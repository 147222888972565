import OnlyAddFile from '../components/OnlyAddFile';
import EventHeader from './../components/EventHeader'
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
import FileOrFolder from "../components/FileOrFolder";
import axios from "axios";
import {translate} from "react-i18next";

const addr = 'https://api.helphelptrip.com/api/1.0';

class DirectorySecond extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			files: '',
			name: '',
			loaded: false,
			backFiles: [],
			backName: '',
			duplicate: false,
			directorySecond: true,
			storageList: false,
			props: props.props
		};
		let handleToUpdate = this.handleToUpdate.bind(this);
	}

	handleToUpdate = (newStorage) => {
		this.setState({
			storage: {files: [], directories: []},
			newStorage: true
		});
		this.setState({
			storage: newStorage,
			newStorage: true,
			storageList: true

		});
	};

	componentDidMount() {
		this.setState({
			loaded: true
		});

		axios.get(addr +'/dirs/' + this.props.location.state.id + '/items', {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			}
		})

			.then((response) => {
				this.setState({
					storage: response.data,
					storageList: true
				});

			})
			.catch((error) => {

			});

	}

	renderLink() {
		if (!this.state.duplicate && this.state.storageList) {
			return <Link className="storage-go-back grey-color " to={{
				pathname: '/storage/' + this.props.match.params.directory,
				state: {
					name: this.props.location.state.storage.name,
					files: this.state.backFiles,
					nameFrom: this.props.location.state.storage.name,
					storage: this.state.storage,
					id: this.props.location.state.storage.id
				}
			}}><i className="fas fa-chevron-left pr-2 grey-color"/>
				wróć</Link>
		}

	}

	render() {
		const {t} = this.props;

		if (this.state.loaded && this.state.storageList) {
			return <div>
				<EventHeader header={t('storage')} backTo="/menu"/>
				<div className="page-content storage col-12">
					<div className="page-div storage-container border-radius-all">
						<p className="path-style border-radius-top">{t('storage')} {'/' + this.props.match.params.directory + '/' + this.props.match.params.directory2}</p>
						{this.renderLink()}
						<div className="storage-wrapper">

							{this.state.storage.files.map((file, index) => {
								let fileFullName = '',
									fileNamePart = '',
									fileType = '';

								fileFullName = file.name;
								fileNamePart = fileFullName.split('.');
								fileType = fileNamePart[1];
								return <FileOrFolder key={index}
								                     name={file.name}
								                     fileType={fileType}
								                     isFile={true}
								                     props={this.props}
								                     directory={this.state.directorySecond}
								                     files={this.state.storage.files[index]}
								                     link={file.link}
								                     handleToUpdate={this.handleToUpdate}/>
							})}

							<OnlyAddFile props={this.props} handleToUpdate={this.handleToUpdate}/>
						</div>
					</div>
				</div>
			</div>
		} else {
			return <div className="bg">
				<div className="centering">
					<p className="center text-center">
						<img src={require('../images/logo.png')} alt="" className="logo-in-spinner"/>
						<i className="fas fa-spinner"/> {t("loading")}
					</p>
				</div>
			</div>
		}

	}
}
export default translate("translations")(DirectorySecond);
