import React, {Component} from 'react';
import {translate, Trans} from "react-i18next";


class DownloadApk extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
	}

	render() {
		const {t} = this.props;

		if (!this.isCordova) {
			return <Trans>
				<div className="download-app">
					<p className="small default-cursor">{t("downloadApk")}:</p>
					<a href="https://helphelptrip.com/android.apk">
						<img className="pointer-cursor" src={require('../images/android-store.png')} alt="android-app"/>
					</a>

				</div>
			</Trans>
		} else {
			return null
		}

	}
}

export default translate("translations")(DownloadApk);
