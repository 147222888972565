import axios from 'axios/index';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import DownloadApk from "../components/DownloadApk"
import {translate, Trans} from "react-i18next";
import Pdf from '../images/Regulamin.pdf';

const agreements = [
	{
		contents: 'Wyrażam zgodę na otrzymanie na podany adres e-mail informacji dotyczących aplikacji "Help!" niezbędnych do jej prawidłowego działania.',
		name: 'first',
		decision: true
	}

];

const addr = 'https://api.helphelptrip.com/api/1.0';

class Login extends Component {

	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.state = {
			loginEmail: '',
			loginPassword: '',
			isLoaded: true,
			token: localStorage.getItem('token'),
			loggedIn: false,
			remindPasswordView: false,
			registerView: false,
			registerSuccess: false,
			errorLoginMessage: '',
			password_confirm: '',
			registerPassword: '',
			registerEmail: '',
			remindEmail: '',
			agreement: agreements,
			checked: agreements.decision,
		};
		localStorage.setItem('messageSend', 'false');
	}

	componentDidMount() {

		// if (this.isCordova) {
		//
		// 	document.addEventListener("deviceready", () => {
		// 		this.setState({
		// 			isLoaded: true
		// 		});
		// 	}, false);
		// 	this.setState({
		// 		isLoaded: true
		// 	});
		//
		// }
		//
		// if (!this.isCordova) {
		// 	this.setState({
		// 		isLoaded: true
		// 	});
		// }

		if (this.props.location.state !== undefined) {
			this.setState({
				registerView: true
			})
		}


		this.setState({
			token: null
		});

	}

	login = () => {

		localStorage.removeItem('token');
		axios.post(addr + '/login', {
			email: this.state.loginEmail,
			password: this.state.loginPassword
		}, {
			headers: {
				'Content-type': 'application/json'
			}
		})
			.then((response) => {

				let token = response.data.token,
					user = response.data.user.email,
					rootDir = response.data.rootDir;
				localStorage.setItem('token', token);
				localStorage.setItem('rootDir', rootDir);
				localStorage.setItem('user', user);
				localStorage.setItem('guest', 'false');

				this.setState({
					loggedIn: true
				});
			})
			.catch((error) => {
				const {t} = this.props;

				if (error.response.status === 404) {
					this.setState({
						errorLoginMessage: t("errorLoginMessage404")
					})
				} else if (error.response.status === 401) {
					this.setState({
						errorLoginMessage: t("errorLoginMessage401")
					})
				} else if (error.response.status === 500) {
					this.setState({
						errorLoginMessage: t("appError")
					})
				} else if (error.response.status === 400) {
					this.setState({
						errorLoginMessage: error.response.data.errors.email
					})
				}
			});
	};

	remindMe = e => {
		this.setState({
			remindPasswordView: false,
			registerView: false,
			passwordRegisterError: '',
			emailRegisterError: '',
			loginPassword: '',
			errorLoginMessage: ''
		});
		this.setState({
			remindPasswordView: true,
			registerView: false
		});
	};

	registerMe = e => {
		this.setState({
			remindPasswordView: false,
			registerView: true
		});
	};

	remindMeAction = e => {

		axios.post(addr + '/password_reset', {
			email: this.state.remindEmail
		}, {
			headers: {
				'Content-type': 'application/json'
			}
		})

			.then((response) => {
				this.setState({
					// remindPasswordView: false,
					// registerView: false,
					newPasswordPopup: true
				});
			})
			.catch((error) => {

				const {t} = this.props;

				if (error.response !== undefined && error.response.status === 404) {
					this.setState({
						remindError: t("errorEmailInvalid")
					})
				}
				if (error.response !== undefined && error.response.status === 400) {
					this.setState({
						remindError: error.response.data.errors.email
					})
				}
			});
	};

	passwordPopup = () => {
		const {t} = this.props;

		if (this.state.newPasswordPopup) {
			return <div>
				<div className="overlay-success">
				</div>
				<div id="passwordPopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title first-letter-big" id="errorModal">Twoje nowe hasło zostało wysłane
							na podany przez Ciebie adres e-mail</h6>
						<button className="btn default-button full-width-button margin-top-10" onClick={this.returnToLogin}> {t('ok')}
						</button>
					</div>
				</div>
			</div>
		}
	};

	returnToLogin = () => {
		this.setState({
			remindPasswordView: false,
			registerView: false,
		})
	};

	registerMeAction = () => {

		this.setState({
			registerEmailError: '',
			registerPasswordError: '',
			passwordConfirmedError: ''
		});

		if (this.state.first !== undefined && this.state.first === true) {
			axios.post(addr + '/register', {
				email: this.state.registerEmail,
				password: this.state.registerPassword,
				password_confirmed: this.state.password_confirm,
				agreements: this.state.agreement
			}, {
				headers: {
					'Content-type': 'application/json'
				}
			})
				.then((response) => {
					this.setState({
						registerSuccess: true,
						registerView: false
					});
				})
				.catch((error) => {
					const {t} = this.props;

					if (error.response.data.errors.email === "Pole 'adres email' nie może być puste") {
						this.setState({
							registerEmailError: t('errorEmailBlank'),
						});
					} else if (error.response.data.errors.email === "Adres email jest nieprawidłowy") {
						this.setState({
							registerEmailError: t('errorEmailInvalid'),
						});
					} else if (error.response.data.errors.email === "Podany adres email jest za długi") {
						this.setState({
							registerEmailError: t('errorEmailTooLong'),
						});
					} else if (error.response.data.errors.email !== undefined && error.response.data.errors.email.includes('Istnieje już konto zarejestrowane na podany adres email')) {
						this.setState({
							registerEmailError: t('errorEmailNotExist'),
						});
					}
					//Password
					if (error.response.data.errors.password === "Hasło nie może być puste") {
						this.setState({
							registerPasswordError: t('errorPasswordBlank'),
						});
					} else if (error.response.data.errors.password === "Nieprawidłowe hasło") {
						this.setState({
							registerPasswordError: t('errorPasswordIncorrect'),
						});
					} else if (error.response.data.errors.password === "Hasło nie może być dłuższe niż 60 znaków") {
						this.setState({
							registerPasswordError: t('errorPasswordTooLong'),
						});
					} else if (error.response.data.errors.password === "Hasło musi mieć minimum 6 znaków") {
						this.setState({
							registerPasswordError: t('errorPasswordTooShort'),
						});
					} else if (error.response.data.errors.password === "Podane hasła różnią się") {
						this.setState({
							registerPasswordError: t('errorPasswordMismatch'),
						});
					}
					//Password confirmed
					if (error.response.data.errors.password_confirmed === "Hasło nie może być puste") {
						this.setState({
							passwordConfirmedError: t('errorPasswordBlank'),
						});
					} else if (error.response.data.errors.password_confirmed === "Nieprawidłowe hasło") {
						this.setState({
							passwordConfirmedError: t('errorPasswordIncorrect'),
						});
					} else if (error.response.data.errors.password_confirmed === "Hasło nie może być dłuższe niż 60 znaków") {
						this.setState({
							passwordConfirmedError: t('errorPasswordTooLong'),
						});
					} else if (error.response.data.errors.password_confirmed === "Hasło musi mieć minimum 6 znaków") {
						this.setState({
							passwordConfirmedError: t('errorPasswordTooShort'),
						});
					}
				});
		}
	};

	backToLoginView = e => {
		this.setState({
			remindPasswordView: false,
			registerView: false,
			registerPassword: '',
			registerPasswordError: '',
			passwordConfirmedError: '',
			registerEmailError: '',
			loginPassword: '',
			remindError: ''
		});
	};

	confirmRegister = () => {
		this.setState({
			registerSuccess: false,
			remindPasswordView: false,
			registerView: false,
		});
	};

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	checkChange = (event) => {
		const target = event.target;
		const name = target.name;
		const isChecked = target.checked;
		this.setState({
			[name]: isChecked
		});
	};

	guestMode = () => {
		localStorage.setItem('token', 'null');

		function getRandomInt(max) {
			return Math.floor(Math.random() * Math.floor(max));
		}

		localStorage.setItem('token', getRandomInt(1000000000000));
		localStorage.setItem('guest', 'true');
		this.setState({
			loggedIn: true,
			isLoaded: true
		});
	};

	renderRegisterButton = () => {
		const {t} = this.props;
		if (this.state.first === true) {
			return <button className="btn default-button full-width-button"
			               onClick={this.registerMeAction}>{t("register")}</button>
		} else {
			return <button className="btn default-button button-disabled full-width-button">{t("register")}</button>
		}
	};

	getDocument = () => {
		this.setState({
			openPdf: true
		})
	};
	languageButtons = () => {
		const {i18n} = this.props;
		const changeLanguage = lng => {
			i18n.changeLanguage(lng);
		};
		return <div className='language-buttons'>
			<img alt="" src={require('../images/pl.svg')} onClick={() => changeLanguage("pl")}/>
			<img alt="" src={require('../images/en.svg')} onClick={() => changeLanguage("en")}/>
			<img alt="" src={require('../images/fr.svg')} onClick={() => changeLanguage("fr")}/>
			<img alt="" src={require('../images/ru.svg')} onClick={() => changeLanguage("ru")}/>
		</div>
	};

	render() {
		const {t} = this.props;
		if (this.state.isLoaded) {
			if (!this.state.loggedIn && localStorage.getItem('guest') === 'true') {
				return <Redirect to="/menu"/>
			}
			if (!this.state.loggedIn && localStorage.getItem('guest') !== 'true') {
				if (!this.state.remindPasswordView && !this.state.registerView && !this.state.registerSuccess) {
					return <Trans>
						<div className='login-background '></div>
						<div className="bg login-bg">
							<div className="em-img">
								{this.languageButtons()}
								<img src={require('../images/logo.png')} alt=""/>
								<h1>{t("Login")}</h1>

								<span>{t("loginInfo")}</span>
								<input id="loginEmail" type="email" onChange={this.valueChange}
								       value={this.state.loginEmail}
								       name="loginEmail" placeholder={t("writeEmail")}/>
								<input id="loginPassword" type="password" onChange={this.valueChange}
								       name="loginPassword" placeholder={t("writePassword")}/>
								<p className="error-line">{this.state.errorLoginMessage}</p>
								<button className="btn default-button full-width-button margin-top-10"
								        onClick={this.login}>{t("loginButton")}</button>
								<p className="small" onClick={this.remindMe}>{t("forgetPassword")}</p>
								<p className="small" onClick={this.registerMe}>{t("registerLink")}</p>
								<button className="btn default-button full-width-button"
								        onClick={this.guestMode}>{t("skipLogin")}</button>
							</div>
							<DownloadApk/>
						</div>
					</Trans>
				} else if (this.state.remindPasswordView && !this.state.registerView) {
					return <div>
						{this.passwordPopup()}
						<div className="bg login-bg">
							<div className="em-img">
								{this.languageButtons()}

								<img src={require('../images/logo.png')} alt=""/>
								<h1>{t("passwordReset")}</h1>

								<p className="normal-text">{t("passwordResetInfo")}</p>
								<div>
									<input type="email" name="remindEmail" onChange={this.valueChange}
									       value={this.state.remindEmail} placeholder={t("writeEmail")}/>
								</div>
								<p className="error-line">{this.state.remindError}</p>
								<button className="btn default-button full-width-button margin-top-10"
								        onClick={this.remindMeAction}>{t('passwordResetButton')}</button>
								<p className="small" onClick={this.backToLoginView}>{t('returnToLogin')}</p>
							</div>
							<DownloadApk/>
						</div>
					</div>
				} else if (this.state.registerView) {
					return <div className="bg login-bg">
						<div className="em-img">
							{this.languageButtons()}

							<img src={require('../images/logo.png')} alt=""/>
							<h1>{t("registration")}</h1>
							<input id="registerEmail" type="email" name="registerEmail" onChange={this.valueChange}
							       value={this.state.registerEmail} placeholder={t("email")}/>
							<p className="error-line">{this.state.registerEmailError}</p>

							<input id="registerPassword" type="password" name="registerPassword"
							       onChange={this.valueChange} value={this.state.registerPassword}
							       placeholder={t("password")}/>
							<p className="error-line">{this.state.registerPasswordError}</p>

							<input id="password_confirm" type="password" name="password_confirm"
							       onChange={this.valueChange} value={this.state.password_confirm}
							       placeholder={t("confirmPassword")}/>
							<p className="error-line">{this.state.passwordConfirmedError}</p>
							{this.state.agreement.map((item) => {
								return <div className="single-agreement">
									<input type="checkbox" className="width-5" name={item.name} key={item.name}
									       onChange={this.checkChange}/>
									<span className="required-text width-5">*</span>
									<p className="width-90">{item.contents}
										<a className="regulations" rel="noopener noreferrer" href={Pdf}
										   target="_new">{t('regulationsLink')}</a>
									</p>
								</div>
							})}
							{this.renderRegisterButton()}
							<p className="small" onClick={this.backToLoginView}>{t('returnToLogin')}</p>
						</div>
						<DownloadApk/>
					</div>
				} else if (this.state.registerSuccess && !this.state.remindPasswordView && !this.state.registerView) {
					return <div id="registerConfirmPopup">
						<div className="popup-title"><p>{t('registerCorrect')}</p></div>
						<div className="popup">
							<h6 className="modal-title" id="errorModal"> {t('accountCreated')}
								<br/> {t('accountCreated2')}</h6>
							<button className="btn default-button full-width-button margin-top-10"
							        onClick={this.confirmRegister}>{t('ok')}</button>
						</div>
					</div>
				}
			} else if (this.state.loggedIn) {
				return <Redirect to="/menu"/>
			}
		} else {
			return <div className="bg">
				<div className="centering">
					<p className="center text-center">
						<img src={require('../images/logo.png')} alt="" className="logo-in-spinner"/>
						<i className="fas fa-spinner"/> {t('loading')}
					</p>
				</div>
			</div>
		}
	}
}

export default translate("translations")(Login);
