import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import React from "react";

i18n.use(LanguageDetector).init({
	// we init with resources

	resources: {

		pl: {
			translations: {
				"langName": "Polski",
				"loading": "Ładowanie...",

				"Login": "Logowanie",
				"loginInfo": "Zalogowani użytkownicy mają dostęp do dodatkowych funkcjonalności.",
				"writeEmail": "Wpisz e-mail",
				"writePassword": "Podaj hasło",
				"loginButton": "Zaloguj",
				"forgetPassword": "Zapomniałeś hasła?",
				"registerLink": "Rejestracja",
				"skipLogin": "Pomiń logowanie",
				"downloadApk": "Pobierz aplikację",

				"passwordReset": "Resetowanie hasła",
				"passwordResetInfo": "Wpisz e-mail, którego użyłeś do logowania. Zostanie na niego wysłane hasło tymczasowe.",
				"passwordResetButton": "Resetuj hasło",
				"returnToLogin": "Wróć do logowania",

				"registration": "Rejestracja",
				"email": "E-mail",
				"password": "Hasło",
				"confirmPassword": "Potwierdź hasło",
				"register": "Zarejestruj",

				"registerCorrect": "Zarejestrowano poprawnie",
				"accountCreated": "Konto zostało utworzone.",
				"accountCreated2": "Na podany adres e-mail wysłano wiadomość z linkiem aktywacyjnym.",
				"ok": "Ok",

				"chooseEvent": "Wybierz zdarzenie,",
				"chooseEvent2": "w którym uczestniczyłeś(-aś).",

				"fallDetected": "Wykryto upadek!",
				"fallDetectedInfo": "Po upływie 30 sekund zostanie wysłany SMS.",
				"fallDetectedInfo2": "Aby przerwać odliczanie i anulować wysłanie SMS-a, kliknij przycisk poniżej.",
				"cancel": "Anuluj",
				"smsSent": "SMS wysłany!",
				"waitForHelp": "Oczekuj na pojawienie się pomocy.",

				"drive": "Dojazd",
				"call": "Zadzwoń",
				"otherFacilities": "Pozostałe placówki",
				"myHealth": "Moje zdrowie",

				"back": "wróć",
				"close": "Zamknij",

				"geolocationError": "Błąd. Twoje położenie nie mogło zostać przesłane. Upewnij się, że aplikacja nie ma zablokowanego dostępu do geolokalizacji.",
				"recoverDocuments": "Odzyskaj dokumenty:",
				"recoverDocumentsInfo": "Odzyskaj dokumenty:",
				"downloadCopy": "Pobierz kopię dokumentów",

				"forms": "Formularze",
				"downloadedForms": "Pobrane formularze",
				"downloadForms": "Pobierz formularze",
				"archivedForms": "Archiwalne formularze",
				"fillForms": "Uzupełnij i wyślij niezbędne formularze.",

				"user": "Użytkownik",
				"personalData": "Dane osobowe:",
				"health": "Stan zdrowia:",
				"diseases": "Choroby:",
				"medicines": "Przyjmowane leki:",
				"translate": "Przetłumacz",

				"procedure": "Procedura",
				"procedureSteps": "Procedura postępowania",

				"save": "Zapisz",

				"overloadDetection": "Wykrywanie upadku",
				"overloadDetectionInfo": "Gdy opcja będzie wyłączona nie zostaniesz powiadomiona/y o wykryciu nienaturalnego przeciążenia co może mieć wpływ na Twoje zdrowie.",
				"overloadDetectionPhone": "Do działania tej funkcjonalności niezbędne jest podanie co najmniej jednego poprawnego numeru telefonu. Numer telefonu podasz wchodząc w",
				"overloadDetectionApk": "Funkcjonalność dostępna tylko w aplikacji mobilnej",

				"off": "Wyłączone",
				"on": "Włączone",

				"success": "Sukces",
				"successPasswordChange": "Hasło zostało zmienione!",

				"actualPassword": "Dotychczasowe hasło",
				"newPassword": "Nowe hasło",
				"confirmNewPassword": "Potwierdź nowe hasło",

				"alarmPhones": "Telefony alarmowe",
				"alarmPhonesInfo": "Wpisz numery telefonów osób, które mamy poinformować za pośrednictwem SMS-a o nagłej sytuacji oraz wpisz, jak mamy Cię przedstawić w wiadomości.",

				"passwordChange": "Zmień hasło",
				"passwordChangeInfo": "Aby zmienić hasło podaj aktualne hasło oraz dwa razy nowe hasło i kliknij zapisz.",

				"writeData": "Wpisz w poniższe pola:",
				"basicPersonalData": "- podstawowe dane osobowe",
				"healthElement": "- stan zdrowia",
				"diseasesElement": "- choroby, które przechodzisz",
				"medicinesElement": "- leki, które przyjmujesz",
				"writeDataInfo": "Zebranie tych informacji w jednym miejscu będzie dla osoby udzielającej Ci pomocy najważniejszym źródłem wiedzy.",
				"writeDataLanguage": "Uzupełniając dane pamiętaj, aby informacje podawać w uproszczonym języku. Informacje o chorobach i lekach w miarę możliwości podawaj w nazwach łacińskich.",
				"writeDataPlace": "Dostęp do tych informacji będzie możliwy poprzez naciśnięcie przycisku 'Moje zdrowie' w dole aplikacji.",
				"myData": "Moje dane:",

				"error": "Błąd",
				"add": "Dodaj",

				"addDir": "Dodaj folder",
				"dirName": "Nazwa folderu",
				"dirSuccess": "Folder został dodany poprawnie",
				"change": "Zmień",
				"chooseFile": "Wybierz plik...",
				"fileChosen": "Wybrano plik:",

				"addFile": "Dodaj plik",
				"addFileSuccess": "Plik został dodany poprawnie",
				"addFolder": "dodaj katalog",
				"addFile2": "dodaj plik",

				"closest": "najbliżej Ciebie:",

				"calculate": "Przelicz",
				"dateRate": "Kurs z dnia ",
				"todayRate": "Dzisiejszy kurs",
				"currencyCalculator": "Kalkulator walut",

				"offlineApk": "Aktualnie aplikacja działa w trybie offline - aby skorzystać z tej funkcjonalności połącz się z siecią.",

				"call112": "Zadzwoń pod 112",

				"seeOther": "Zobacz pozostałe placówki",

				"confirmDelete": "Czy na pewno chcesz usunąć",
				"confirmDeleteFile": "Czy na pewno chcesz usunąć ten plik?",
				"confirmDeleteDir": "Czy na pewno chcesz usunąć ten folder?",

				"yes": "Tak",
				"no": "Nie",

				"deleteDir": "Usuń folder",
				"dirNotEmpty": "Folder, który chcesz usunąć nie jest pusty.",
				"dirNotEmptyDelete": "Czy na pewno chcesz go usunąć?",
				"deleteAll": "Usuń folder wraz z zawartością",
				"dirDeleteSuccess": "Folder został usunięty poprawnie",
				"fileDeleteSuccess": "Plik został usunięty poprawnie",

				"dir": "folder",
				"file": "plik",
				"moveDir": "Przenieś folder",
				"chooseLocation": "Wybierz miejsce docelowe folderu",
				"moveSuccess": "został przeniesiony",
				"moveSuccessFile": "Plik został przeniesiony",
				"moveSuccessDir": "Folder został przeniesiony",

				"changeName": "Zmień nazwę",
				"changeNameSuccess": "Nazwa została zmieniona",

				"mainDir": "Katalog główny",
				"move": "Przenieś",
				"delete": "Usuń",

				"yourChoice": "Wybrałeś/wybrałaś:",
				"yourChoiceInfo": "Nie wiesz co zrobić? Zobacz procedurę postępowania w tym przypadku klikając w przycisk poniżej.",
				"seeProcedure": "Zobacz procedurę",

				"translation": "Kierunek tłumaczenia:",
				"translationButton": "Użyj przycisku i zacznij mówić. Aby przetłumaczyć, kliknij ponownie.",
				"wrongLanguage": "Niepoprawny język syntezatora? Kliknij tutaj, aby doinstalować język.",
				"microphoneNeeded": "Aplikacja potrzebuje dostępu do mikrofonu, aby tłumaczyć mowę.",
				"allow": "Zezwól",

				"chrome": "Aby użyć translatora mowy, przełącz się na przeglądarkę Chrome.",

				"speechRecognition": "Tłumaczenie mowy",

				"onlineTranslator": "W razie problemów możesz skorzystać również z tłumacza online:",
				"onlineTranslatorOn": "Włącz - tłumacz online",

				"event1": "Nagły wypadek zdrowotny",
				"event2": "Wypadek samochodowy",
				"event3": "Awaria samochodu",
				"event4": "Kradzież",
				"event5": "Granica",

				"smsContentFall": "zglosil w aplikacji Help! zdarzenie 'Upadek'. Jego lokalizacja to:",

				"errorLoginMessage401": "Nieprawidłowy e-mail lub hasło",
				"errorLoginMessage404": "Wystąpił nieoczekiwany błąd. Spróbuj ponownie później",
				"errorPhone": "Nieprawidłowy numer telefonu",

				"errorPasswordBlank": "Hasło nie może być puste",
				"errorPasswordIncorrect": "Nieprawidłowe hasło",
				"errorPasswordTooLong": "Hasło nie może być dłuższe niż 60 znaków",
				"errorPasswordTooShort": "Hasło musi mieć minimum 6 znaków",
				"errorPasswordMismatch": "Podane hasła różnią się",

				"errorEmailNotExist": "Adres email już jest zarejestrowany - zaloguj się lub utwórz nowe konto",
				"errorEmailExist": "Niepoprawny adres e-mail lub hasło albo konto nie zostało aktywowane",
				"errorEmailNotActive": "Nieaktywowane konto użytkownika",
				"errorEmailNotFound": "Niezarejestrowany adres email",
				"errorEmailBlank": "Pole 'adres email' nie może być puste",
				"errorEmailInvalid": "Adres email jest nieprawidłowy",
				"errorEmailTooLong": "Podany adres email jest za długi",

				"errorToken": "Niepoprawny token",

				"errorAgreements": "Brak wymaganych zgód",

				"errorDevice": "Nieprawidłowa platforma urządzenia",

				"errorDirDuplication": "Katalog o tej samej nazwie już istnieje w tej lokalizacji",
				"errorDirIncorrectLenght": "Nazwa musi mieć od 3 do 128 znaków",
				"errorDirInvalid": "Nieprawidłowy katalog",

				"errorFileDuplication": "Plik o tej samej nazwie już istnieje w tej lokalizacji",
				"errorFileIncorrectLenght": "Nazwa musi mieć od 3 do 128 znaków",
				"errorFileInvalid": "Nieprawidłowy plik",

				"errorUuidBlank": "Nazwa musi mieć od 3 do 128 znaków",
				"errorUuidIncorect": "Nieprawidłowy plik",

				"errorCredential": "Niepoprawny adres e-mail lub hasło albo konto nie zostało aktywowane",

				"errorAccessDenied": "Wymagana jest zmiana tymczasowego hasła, aby móc bezpiecznie korzystać z aplikacji",

				"accountActivation": "Aktywacja konta",
				"activationSuccess": "Konto zarejestrowane na adres zostało aktywowane. Może teraz w pełni korzystać z naszej aplikacji",
				"adminRequired": "Tylko administrator ma dostęp do tego zasobu",

				"emailNewPassword": "Witaj!",
				"emailNewPassword2": "Nowe hasło do Twojego konta to:",
				"emailNewPassword3": "Pamiętaj, aby je zmienić wchodząc po zalogowaniu się w edycję profilu!",
				"emailNewPassword4": "Twoje hasło zostało pomyślnie zmienione.",

				"settings": "Ustawienia",
				"storage": "Moje dokumenty",
				"myAccount": "Moje konto",

				"downloadApkInfo": "Możesz też pobrać tę aplikację na swój telefon, wystarczy nacisnąć ten przycisk:",
				"download": "Pobierz",

				//Jeden komunikat podzielony na części za względu na formatowanie tekstu
				"internetModal": "Niniejsza aplikacja wymaga do prawidłowego działania dostępu do",
				"internetModal2": "Internetu",
				"internetModal3": " i",
				"internetModal4": "lokalizacji",
				"internetModal5": "Upewnij się, że te uprawnienia nie są zablokowane.",

				"fallDetectorModal": "W trosce o Twoje bezpieczeństwo niniejsza aplikacja sprawdza przeciążenia oddziałujące na Twoje urządzenie.",
				"fallDetectorModal2": "Gdy zostanie wykryte nienaturalne przeciążenie zostaniesz o tym poinformowany/a. ",
				"fallDetectorModal3": "Możesz zrezygnować z tej funkcji wybierając odpowiednią opcję w ustawieniach aplikacji.",
				"apkBackground": "Aplikacja działa w tle",
				"watchOverloads": "Bada przeciążenia w trosce o Twoje zdrowie.",

				"agreementContent": "Wyrażam zgodę na otrzymanie na podany adres e-mail informacji dotyczących aplikacji 'Help!' niezbędnych do jej prawidłowego działania.",
				"regulationsLink": "Regulamin i polityka prywatności.",

				"tutorialAccount": "Aplikacja daje możliwość założenia konta. Zalogowani użytkownicy posiadają dostęp do funkcjonalności pozwalającej na przechowywanie plików i ich odzyskanie (logując się na innym urządzeniu) w przypadku utraty lub zniszczenia telefonu.",
				"tutorialPass": "Możesz korzystać z aplikacji jako użytkownik niezalogowany - wystarczy nacisnąć Pomiń logowanie. Aplikacja zapamięta Twój wybór - jeżeli zmienisz zdanie odpowiednie opcje znajdziesz w menu.",
				"tutorialDownload": "Możesz też pobrać tę aplikację na swój telefon, wystarczy nacisnąć ikonę powyżej.",
				"tutorialPhones": "Dodatkowo jeśli w zakładce 'Moje konto' dodasz numery telefonu osób do kontaktu zostaną one powiadomione za pomocą SMS-a o Twojej sytuacji wraz z lokalizacją miejsca, w którym się znajdujesz.",
				"tutorialTranslator": "Po zgłoszeniu zdarzenia będziesz mieć dostęp do tłumacza głosowego tłumaczącego w obie strony, który pomoże Ci porozumieć się z osobami udzielającymi Ci pomocy.",
				"tutorialCurrency": "W zdarzeniu 'Granica' znajduje się kalkulator walut pozwalający na szybkie przeliczenie wielu walut według aktualnego kursu NBP.",
				"tutorialHealth": "W zakładce 'Moje zdrowie' możesz dodać informacje o stanie swojego zdrowia oraz przyjmowanych lekach.",
				"tutorialHealth2": "Informacje te mogą być użyteczne dla osób udzielających Ci pomocy.",
				"tutorialEvent": "Jeżeli znajdziesz się w trudnej sytuacji wybierz zdarzenie, w którym uczestniczyłeś(-aś), aby uzyskać przydatne informacje: procedura postępowania, adresy i numery telefonów placówek znajdujących się najbliżej Ciebie.",

				"guestMode": "Nie jesteś zalogowany do aplikacji, zaloguj się, aby skorzystać z pełnej wersji aplikacji. Zarejestruj się jeśli jeszcze nie posiadasz konta.",
				"guestMode2": "Korzystasz z aplikacji jako gość. Jeżeli chcesz się zalogować kliknij odpowiednią opcję w menu.",

				"loginSidebar": "Zaloguj się",
				"register2": "Zarejestruj się",
				"logOut": "Wyloguj się",

				"langChange": "Zmiana języka",
				"langChange2": "Zmień język",
				"chooseSensitivity": "Wybierz czułość",
				"sensitivityAdjusment": "Dostosowanie czułości",
				"sensitivityAdjusment2": "Jeżeli zbyt często wykrywany jest upadek lub uprawiasz sport ekstremalny możesz wyregulować czułość.",
				"restoreDefaults": "Przywróć domyślne",

				"sendSmsCanceled": "Wysyłanie SMS-a zostało anulowane.",
				"changeSensitivityPopup": "Jeśli chcesz możesz zmienić czułość wykrywania upadku.",
				"canceled": "Anulowano",
				"mute": "Wycisz",
				"yourEmail": "Twój adres e-mail",
				"exit": "Zakończ",
				"next": "Dalej",
				"info": "Info",
				"singleSound": "Pojedynczy",
				"singleSound2": "dźwięk",
				"manySound": "Dźwięk",
				"manySound2": "powtarzany",
				"fallPopupSound": "Dźwięk przy wykryciu upadku",
				"fallPopupSoundInfo": "Jeżeli zostanie wykryty upadek zostaniesz o tym poinformowany",
				"fallPopupSoundInfo2": "w formie komunikatu. Towarzyszyć temu może: ",
				"fallPopupSoundInfo3": "pojedynczy dźwięk",
				"fallPopupSoundInfo4": "- tylko przy wywołaniu komunikatu lub ",
				"fallPopupSoundInfo5": "dźwięk powtarzany ",
				"fallPopupSoundInfo6": " przez cały czas wyświetlania komunikatu.",

				"dataSaveSuccess": "Dane zostały uzupełnione poprawnie!",
				"langSaveSuccess": "Język został zmieniony poprawnie",

				"appError": "Wystąpił błąd w działaniu aplikacji. Spróbuj ponownie później.",
				"myHealthEdit": "Moje zdrowie - edycja",
				"edit": "Edytuj",

				"overloadDetectionInfo2": "Gdy opcja jest wyłączona nie zostaniesz powiadomiona/y o wykryciu nienaturalnego przeciążenia co może mieć wpływ na Twoje zdrowie.",
				"advanced": "Zaawansowane",

				// Skrótowe oznaczenie walut (ISO) do przetłumaczenia tylko na rosyjski
				"eur": "EUR",
				"pln": "PLN",
				"usd": "USD",
				"chf": "CHF",
				"gbp": "GBP",
				"huf": "HUF",
				"uah": "UAH",
				"czk": "CZK",
				"dkk": "DKK",
				"isk": "ISK",
				"nok": "NOK",
				"sek": "SEK",
				"hrk": "HRK",
				"ron": "RON",
				"bgn": "BGN",
				"try": "TRY",
				"rub": "RUB",
				"jpy": "JPY",
				"cny": "CNY",
				"egp": "EGP",
				"tnd": "TND",
				"amd": "AMD",
				"gel": "GEL",
				"mdl": "MDL",
				"all": "ALL",
				"rsd": "RSD",
				"mkd": "MKD",
				"azn": "AZN",
				"byn": "BYN",
				"bam": "BAM",
			}
		},
		en: {
			translations: {
				"langName": "English",

				"loading": "Loading...",

				"Login": "Login",
				"loginInfo": "Logged in users have access to additional functions.",
				"writeEmail": "Enter email",
				"writePassword": "Enter password",
				"loginButton": "Log in",
				"forgetPassword": "Forgot password?",
				"registerLink": "Registration",
				"skipLogin": "Skip login",
				"downloadApk": "Download application:",

				"passwordReset": "Password reset in progress",
				"passwordResetInfo": "Enter email you used to log in. A temporary password will be sent to this address.",
				"passwordResetButton": "Reset password",
				"returnToLogin": "Back to log in",

				"registration": "Registration",
				"email": "Email",
				"password": "Password",
				"confirmPassword": "Confirm password",
				"register": "Register",

				"registerCorrect": "Registered correctly",
				"accountCreated": "The account has been created.",
				"accountCreated2": "An email with an activation link has been sent to the email address provided.",
				"ok": "Ok",

				"chooseEvent": "Choose incident",
				"chooseEvent2": "that happened to you.",

				"fallDetected": "Fall down detected!",
				"fallDetectedInfo": "After 30 seconds SMS will be sent.",
				"fallDetectedInfo2": "To stop the countdown and cancel sending the SMS, click the button below.",
				"cancel": "Cancel",
				"smsSent": "SMS was sent!",
				"waitForHelp": "Wait for help.",

				"drive": "Access",
				"call": "Call",
				"otherFacilities": "Other facilities",
				"myHealth": "My health",

				"back": "back",
				"close": "Close",

				"geolocationError": "Error. Your location could not be sent. Make sure that the application has access to geolocation.",
				"recoverDocuments": "Retrieve documents:",
				"recoverDocumentsInfo": "Retrieve documents:",
				"downloadCopy": "Download copy of documents",

				"forms": "Forms",
				"downloadedForms": "Downloaded forms",
				"downloadForms": "Download forms",
				"archivedForms": "Archival forms",
				"fillForms": "Fill up and send necessary forms.",

				"user": "User",
				"personalData": "Personal data:",
				"health": "Health state:",
				"diseases": "Conditions:",
				"medicines": "Medications taken:",
				"translate": "Translate",

				"procedure": "Procedure",
				"procedureSteps": "What to do",

				"save": "Save",

				"overloadDetection": "Overload detection",
				"overloadDetectionInfo": "When the option is turned off you will not be notified about the detection of unnatural overload which may affect your health.",
				"overloadDetectionPhone": "It is necessary to provide at least one valid phone number to operate this functionality.",
				"overloadDetectionApk": "It is necessary to provide at least one valid phone number to operate this functionality.",

				"off": "Disabled",
				"on": "Enabled",

				"success": "Success",
				"successPasswordChange": "Password has been changed!",

				"actualPassword": "Current password",
				"newPassword": "New password",
				"confirmNewPassword": "Confirm new password",

				"alarmPhones": "Emergency phone numbers",
				"alarmPhonesInfo": "Enter phone numbers of persons you want us to inform about emergency via SMS and type in your signature.",

				"passwordChange": "Change password",

				"writeData": "Fill in following fields:",
				"basicPersonalData": "- basic personal data",
				"healthElement": "- health state",
				"diseasesElement": "- current conditions",
				"medicinesElement": "- administered medicines",
				"writeDataInfo": "Collecting this information in one place will be the most important source of knowledge for the person helping you.",
				"writeDataLanguage": "When entering the details, remember to provide information in a simplified language. If possible, provide Latin names of diseases and medicines.",
				"writeDataPlace": "To access this information press 'My health' button on the bottom of the app.",
				"myData": "My data:",

				"error": "Error",
				"add": "Add",

				"addDir": "Add folder",
				"dirName": "Folder name",
				"dirSuccess": "Folder has been correctly added",
				"change": "Change",
				"chooseFile": "Choose file...",
				"fileChosen": "Chosen file:",

				"addFile": "Add file",
				"addFileSuccess": "File has been added correctly",
				"addFolder": "add catalogue",
				"addFile2": "add file",

				"closest": "nearest you:",

				"calculate": "Calculate",
				"dateRate": "Exchange rate on ",
				"todayRate": "Today's exchange rate",
				"currencyCalculator": "Currency calculator",

				"offlineApk": "App is in offline mode now - to use this functionality connect to the network.",

				"call112": "Call 112",

				"seeOther": "See other facilities",

				"confirmDelete": "Are you sure you want to delete?",
				"confirmDeleteFile": "Are you sure you want to delete this file?",
				"confirmDeleteDir": "Are you sure you want to delete this folder?",

				"yes": "Yes",
				"no": "No",

				"deleteDir": "Delete folder",
				"dirNotEmpty": "Folder you want to delete is not empty.",
				"dirNotEmptyDelete": "Are you sure you want to delete it?",
				"deleteAll": "Delete the folder along with the content",
				"dirDeleteSuccess": "Folder has been successfully deleted",
				"fileDeleteSuccess": "File has been successfully deleted",

				"dir": "folder",
				"file": "file",
				"moveDir": "Move the folder",
				"chooseLocation": "Select the destination of the folder",
				"moveSuccess": "has been moved",
				"moveSuccessFile": "File has been moved",
				"moveSuccessDir": "Folder has been moved",

				"changeName": "Change name",
				"changeNameSuccess": "Name has been changed",

				"mainDir": "Main catalogue",
				"move": "Move",
				"delete": "Delete",

				"yourChoice": "You have chosen:",
				"yourChoiceInfo": "You do not know what to do? See what to do in this case by clicking the button below.",
				"seeProcedure": "See procedure",

				"translation": "Direction of translation:",
				"translationButton": "Press the button and start talking. To translate, click again.",
				"wrongLanguage": "Incorrect language? Click here to install additional language.",
				"microphoneNeeded": "App needs microphone access to translate voice.",
				"allow": "Allow",

				"chrome": "Switch to Chrome to use voice translator.",

				"speechRecognition": "Voice translation",

				"onlineTranslator": "If there are any problems, you can also use an online translator:",
				"onlineTranslatorOn": "Enable - online translator",

				"event1": "Emergency accident regarding health",
				"event2": "Car accident",
				"event3": "Car breakdown",
				"event4": "Theft ",
				"event5": "State boarder",

				"smsContentFall": "reported 'Fall down' in the Help! app. His/her localisation:",

				"errorLoginMessage401": "Incorrect email or password",
				"errorLoginMessage404": "An unexpected error occurred. Please try again later",
				"errorPhone": "Incorrect phone number",

				"errorPasswordBlank": "Password cannot be empty",
				"errorPasswordIncorrect": "Incorrect password",
				"errorPasswordTooLong": "Password cannot be longer than 60 characters",
				"errorPasswordTooShort": "Password cannot be shorter than 6 characters",
				"errorPasswordMismatch": "Password entered do not match",

				"errorEmailNotExist": "Email address is already registered - log in or create new account",
				"errorEmailExist": "Incorrect email address, password or account has not been activated",
				"errorEmailNotActive": "User account not activated",
				"errorEmailNotFound": "Email address not registered",
				"errorEmailBlank": "File 'email address' cannot be empty",
				"errorEmailInvalid": "Incorrect email address",
				"errorEmailTooLong": "Email address provided is too long",

				"errorToken": "Incorrect token",

				"errorAgreements": "Lack of the required permissions",

				"errorDevice": "Incorrect device platform",

				"errorDirDuplication": "There is a catalogue with the same name in this location",
				"errorDirIncorrectLenght": "Name has to be 3-128 characters long",
				"errorDirInvalid": "Incorrect catalogue",

				"errorFileDuplication": "There is a file with the same name in this location",
				"errorFileIncorrectLenght": "Name has to be 3-128 characters long",
				"errorFileInvalid": "Incorrect file",

				"errorUuidBlank": "Name has to be 3-128 characters long",
				"errorUuidIncorect": "Incorrect file",

				"errorCredential": "Incorrect email address, password or account has not been activated",

				"errorAccessDenied": "Change of temporary password is required to safely use the application",

				"accountActivation": "Account activation",
				"activationSuccess": "The account registered to the address has been activated. You can now fully use our application",
				"adminRequired": "Only admin has access to this resource",

				"emailNewPassword": "Hello!",
				"emailNewPassword2": "Your new password:",
				"emailNewPassword3": "Remember to change it in the profile edit after logging in!",
				"emailNewPassword4": "Your password has been successfully changed.",

				"settings": "Settings",
				"storage": "Storage",

				"downloadApkInfo": "You can download this app on your phone, just press this button:",
				"download": "Download",

				//Jeden komunikat podzielony na części za względu na formatowanie tekstu
				"internetModal": "To operate correctly, following application requires access to",
				"internetModal2": "Internet",
				"internetModal3": " and",
				"internetModal4": "localization",
				"internetModal5": "Make sure these permissions are not blocked.",

				"fallDetectorModal": "For your safety, this application checks the overloads affecting your device.",
				"fallDetectorModal2": "You will be informed about the detection of an unnatural overload. ",
				"fallDetectorModal3": "You can opt out of this feature by selecting the appropriate option in the application settings.",
				"apkBackground": "App operates in the background",
				"watchOverloads": "Examines overloads for the sake of your health.",

				"agreementContent": "I agree to receive information about 'Help!' application which are necessary for its proper operation to the email address provided.",
				"regulationsLink": "Terms and Conditions and Privacy Policy.",

				"tutorialAccount": "Aplikacja daje możliwość założenia konta. Zalogowani użytkownicy posiadają dostęp do funkcjonalności pozwalającej na przechowywanie plików i ich odzyskanie (logując się na innym urządzeniu) w przypadku utraty lub zniszczenia telefonu.",
				"tutorialPass": "Możesz korzystać z aplikacji jako użytkownik niezalogowany - wystarczy nacisnąć Pomiń logowanie. Aplikacja zapamięta Twój wybór - jeżeli zmienisz zdanie odpowiednie opcje znajdziesz w menu.",
				"tutorialDownload": "Możesz też pobrać tę aplikację na swój telefon, wystarczy nacisnąć ikonę powyżej.",
				"tutorialPhones": "Dodatkowo jeśli w zakładce 'Moje konto' dodasz numery telefonu osób do kontaktu zostaną one powiadomione za pomocą SMS-a o Twojej sytuacji wraz z lokalizacją miejsca, w którym się znajdujesz.",
				"tutorialTranslator": "Po zgłoszeniu zdarzenia będziesz mieć dostęp do tłumacza głosowego tłumaczącego w obie strony, który pomoże Ci porozumieć się z osobami udzielającymi Ci pomocy.",
				"tutorialCurrency": "W zdarzeniu 'Granica' znajduje się kalkulator walut pozwalający na szybkie przeliczenie wielu walut według aktualnego kursu NBP.",
				"tutorialHealth": "W zakładce 'Moje zdrowie' możesz dodać informacje o stanie swojego zdrowia oraz przyjmowanych lekach.",
				"tutorialHealth2": "Informacje te mogą być użyteczne dla osób udzielających Ci pomocy.",
				"tutorialEvent": "Jeżeli znajdziesz się w trudnej sytuacji wybierz zdarzenie, w którym uczestniczyłeś(-aś), aby uzyskać przydatne informacje: procedura postępowania, adresy i numery telefonów placówek znajdujących się najbliżej Ciebie.",

				"guestMode": "Nie jesteś zalogowany do aplikacji, zaloguj się, aby skorzystać z pełnej wersji aplikacji. Zarejestruj się jeśli jeszcze nie posiadasz konta.",
				"guestMode2": "Korzystasz z aplikacji jako gość. Jeżeli chcesz się zalogować kliknij odpowiednią opcję w menu.",

				"loginSidebar": "Zaloguj się",
				"register2": "Zarejestruj się",
				"logOut": "Wyloguj się",

				"langChange": "Zmiana języka",
				"langChange2": "Zmień język",
				"chooseSensitivity": "Wybierz czułość",
				"sensitivityAdjusment": "Dostosowanie czułości",
				"sensitivityAdjusment2": "Jeżeli zbyt często wykrywany jest upadek lub uprawiasz sport ekstremalny możesz wyregulować czułość.",
				"restoreDefaults": "Przywróć domyślne",

				"sendSmsCanceled": "Wysyłanie SMS-a zostało anulowane.",
				"changeSensitivityPopup": "Jeśli chcesz możesz zmienić czułość wykrywania upadku.",
				"canceled": "Anulowano",
				"mute": "Wycisz",
				"yourEmail": "Twój adres e-mail",
				"exit": "Zakończ",
				"next": "Dalej",
				"info": "Info",
				"singleSound": "Pojedynczy",
				"singleSound2": "dźwięk",
				"manySound": "Dźwięk",
				"manySound2": "powtarzany",
				"fallPopupSound": "Dźwięk przy wykryciu upadku",
				"fallPopupSoundInfo": "Jeżeli zostanie wykryty upadek zostaniesz o tym poinformowany",
				"fallPopupSoundInfo2": "w formie komunikatu. Towarzyszyć temu może: ",
				"fallPopupSoundInfo3": "pojedynczy dźwięk",
				"fallPopupSoundInfo4": "- tylko przy wywołaniu komunikatu lub ",
				"fallPopupSoundInfo5": "dźwięk powtarzany ",
				"fallPopupSoundInfo6": " przez cały czas wyświetlania komunikatu.",

				"dataSaveSuccess": "Dane zostały uzupełnione poprawnie!",
				"langSaveSuccess": "Język został zmieniony poprawnie",

				"appError": "Wystąpił błąd w działaniu aplikacji. Spróbuj ponownie później.",
				"myHealthEdit": "Moje zdrowie - edycja",
				"edit": "Edytuj",

				"overloadDetectionInfo2": "Gdy opcja jest wyłączona nie zostaniesz powiadomiona/y o wykryciu nienaturalnego przeciążenia co może mieć wpływ na Twoje zdrowie.",
				"advanced": "Zaawansowane",

				// Skrótowe oznaczenie walut (ISO) do przetłumaczenia tylko na rosyjski
				"eur": "EUR",
				"pln": "PLN",
				"usd": "USD",
				"chf": "CHF",
				"gbp": "GBP",
				"huf": "HUF",
				"uah": "UAH",
				"czk": "CZK",
				"dkk": "DKK",
				"isk": "ISK",
				"nok": "NOK",
				"sek": "SEK",
				"hrk": "HRK",
				"ron": "RON",
				"bgn": "BGN",
				"try": "TRY",
				"rub": "RUB",
				"jpy": "JPY",
				"cny": "CNY",
				"egp": "EGP",
				"tnd": "TND",
				"amd": "AMD",
				"gel": "GEL",
				"mdl": "MDL",
				"all": "ALL",
				"rsd": "RSD",
				"mkd": "MKD",
				"azn": "AZN",
				"byn": "BYN",
				"bam": "BAM",
			}
		},
		fr: {
			translations: {
				"langName": "Françoise",

				"loading": "chargement...",

				"Login": "login",
				"loginInfo": "Zalogowani użytkownicy mają dostęp do dodatkowych funkcjonalności.",
				"writeEmail": "Les utilisateurs connectés ont accès à des fonctionnalités supplémentaires.",
				"writePassword": "Entrez le mot de passe",
				"loginButton": "Se connecter",
				"forgetPassword": "Avez-vous oublié votre mot de passe?",
				"registerLink": "inscription",
				"skipLogin": "Passer la connexion",
				"downloadApk": "Téléchargez l'application:",

				"passwordReset": "Réinitialisation du mot de passe",
				"passwordResetInfo": "Wpisz e-mail, którego użyłeś do logowania. Zostanie na niego wysłane hasło tymczasowe.",
				"passwordResetButton": "Resetuj hasło",
				"returnToLogin": "Wróć do logowania",

				"registration": "inscription",
				"email": "E-mail",
				"password": "Hasło",
				"confirmPassword": "Potwierdź hasło",
				"register": "Zarejestruj",

				"registerCorrect": "Zarejestrowano poprawnie",
				"accountCreated": "Konto zostało utworzone.",
				"accountCreated2": "Na podany adres email wysłano wiadomość z linkiem aktywacyjnym.",
				"ok": "Ok",

				"chooseEvent": "Choisissez un événement, ",
				"chooseEvent2": "w którym się znalazłeś/znalazłaś.",

				"fallDetected": "Wykryto upadek!",
				"fallDetectedInfo": "Po upływie 30 sekund zostanie wysłany SMS.",
				"fallDetectedInfo2": "Aby przerwać odliczanie i anulować wysłanie SMSa kliknij przycisk poniżej.",
				"cancel": "Anuluj",
				"smsSent": "SMS wysłany!",
				"waitForHelp": "Oczekuj na pojawienie się pomocy.",

				"drive": "Dojazd",
				"call": "Zadzwoń",
				"otherFacilities": "Pozostałe placówki",
				"myHealth": "Moje zdrowie",

				"back": "wróć",
				"close": "Zamknij",

				"geolocationError": "Bład. Twoje położenie nie mogło zostać przesłane. Upewnij się, że aplikacja nie ma zablokowanego dostępu do geolokalizacji.",
				"recoverDocuments": "Odzyskaj dokumenty:",
				"recoverDocumentsInfo": "Odzyskaj dokumenty:",
				"downloadCopy": "Pobierz kopię dokumentów",

				"forms": "Formularze",
				"downloadedForms": "Pobrane formularze",
				"downloadForms": "Pobierz formularze",
				"archivedForms": "Archiwalne formularze",
				"fillForms": "Uzupełnij i wyślij niezbędne formularze.",

				"user": "Użytkownik",
				"personalData": "Dane osobowe:",
				"health": "Stan zdrowia:",
				"diseases": "Choroby:",
				"medicines": "Przyjmowane leki:",
				"translate": "Przetłumacz",

				"procedure": "Procedura",
				"procedureSteps": "Procedura postępowania",

				"save": "Zapisz",

				"overloadDetection": "Wykrywanie upadku",
				"overloadDetectionInfo": "Gdy opcja będzie wyłączona nie zostaniesz powiadomiona/y o wykryciu nienaturalnego przeciążenia co może mieć wpływ na Twoje zdrowie.",
				"overloadDetectionPhone": "Do działania tej funkcjonalności niezbędne jest podanie conajmniej jednego poprawnego numeru telefonu. Numer telefonu podasz wchodząc w",
				"overloadDetectionApkoverloadDetectionApk": "Funkcjonalność dostępna tylko w aplikacji mobilnej",

				"off": "Wyłączone",
				"on": "Włączone",

				"success": "Sukces",
				"successPasswordChange": "Hasło zostało zmienione!",

				"actualPassword": "Dotychczasowe hasło",
				"newPassword": "Nowe hasło",
				"confirmNewPassword": "Potwierdź nowe hasło",

				"alarmPhones": "appels d'urgence",
				"alarmPhonesInfo": "Wpisz numery telefonów osób, które mamy poinformować za pośrednictwem SMS o nagłej sytuacji oraz wpisz jak mamy Cię przedstawić w wiadomości.",

				"passwordChange": "Zmień hasło",
				"passwordChangeInfo": "Aby zmienić hasło podaj aktualne hasło oraz dwa razy nowe hasło i kliknij zapisz.",


				"writeData": "Wpisz w poniższe pola:",
				"basicPersonalData": "- podstawowe dane osobowe",
				"healthElement": "- stan zdrowia",
				"diseasesElement": "- choroby, które przechodzisz",
				"medicinesElement": "- leki, które przyjmujesz",
				"writeDataInfo": "Zebranie tych informacji w jednym miejscu będzie dla osoby udzielającej Ci pomocy najważniejszym źródłem wiedzy.",
				"writeDataLanguage": "Uzupełniając dane pamiętaj aby informacje podawać w uproszczonym języku. Informacje o chorobach i lekach w miarę możliwości podawaj w nazwach łacińskich.",
				"writeDataPlace": "Dostęp do tych informacji będzie możliwy poprzez naciśnięcie przycisku 'Moje zdrowie' w dole aplikacji.",
				"myData": "Moje dane:",

				"error": "Błąd",
				"add": "Dodaj",

				"addDir": "Dodaj folder",
				"dirName": "Nazwa folderu",
				"dirSuccess": "Folder został dodany poprawnie",
				"change": "Zmień",
				"chooseFile": "Wybierz plik...",
				"fileChosen": "Wybrano plik:",

				"addFile": "Dodaj plik",
				"addFileSuccess": "Plik został dodany poprawnie",
				"addFolder": "dodaj katalog",
				"addFile2": "dodaj plik",

				"closest": "najbliżej Ciebie:",

				"calculate": "Przelicz",
				"dateRate": "Kurs z dnia ",
				"todayRate": "Dzisiejszy kurs",
				"currencyCalculator": "Kalkulator walut",

				"offlineApk": "Aktualnie aplikacja działa w trybie offline - aby skorzystać z tej funkcjonalności połącz się z siecią.",

				"call112": "Zadzwoń pod 112",

				"seeOther": "Zobacz pozostałe placówki",

				"confirmDelete": "Czy na pewno chcesz usunąć",
				"confirmDeleteFile": "Czy na pewno chcesz usunąć ten plik?",
				"confirmDeleteDir": "Czy na pewno chcesz usunąć ten folder?",

				"yes": "Tak",
				"no": "Nie",

				"deleteDir": "Usuń folder",
				"dirNotEmpty": "Folder, który chcesz usunąć nie jest pusty.",
				"dirNotEmptyDelete": "Czy na pewno chcesz go usunąć?",
				"deleteAll": "Usuń folder wraz z zawartością",
				"dirDeleteSuccess": "Folder został usunięty poprawnie",
				"fileDeleteSuccess": "Plik został usunięty poprawnie",

				"dir": "folder",
				"file": "plik",
				"moveDir": "Przenieś folder",
				"chooseLocation": "Wybierz miejsce docelowe folderu",
				"moveSuccess": "został przeniesiony",
				"moveSuccessFile": "Plik został przeniesiony",
				"moveSuccessDir": "Folder został przeniesiony",

				"changeName": "Zmień nazwę",
				"changeNameSuccess": "Nazwa została zmieniona",

				"mainDir": "Katalog główny",
				"move": "Przenieś",
				"delete": "Usuń",

				"yourChoice": "Wybrałeś/wybrałaś:",
				"yourChoiceInfo": "Nie wiesz co zrobić? Zobacz procedurę postępowania w tym przypadku klikając w przycisk poniżej.",
				"seeProcedure": "Zobacz procedurę",

				"translation": "Kierunek tłumaczenia:",
				"translationButton": "Użyj przycisku i zacznij mówić. Aby przetłumaczyć, kliknij ponownie.",
				"wrongLanguage": "Niepoprawny język syntezatora? Kliknij tutaj, aby doinstalować język.",
				"microphoneNeeded": "Aplikacja potrzebuje dostępu do mikrofonu, aby tłumaczyć mowę.",
				"allow": "Zezwól",

				"chrome": "Aby użyć translatora mowy, przełącz się na przeglądarkę Chrome.",

				"speechRecognition": "Tłumaczenie mowy",

				"onlineTranslator": "W razie problemów możesz skorzystać również z tłumacza online:",
				"onlineTranslatorOn": "Włącz - tłumacz online",

				"event1": "Accident sanitaire d'urgence",
				"event2": "Accident de voiture",
				"event3": "Panne de voiture",
				"event4": "vol",
				"event5": "frontière",

				"smsContentFall": "zglosil w aplikacji Help! zdarzenie 'Upadek'. Jego lokalizacja to:",

				"errorLoginMessage401": "Nieprawidłowy e-mail lub hasło",
				"errorLoginMessage404": "Wystąpił nieoczekiwany błąd. Spróbuj ponownie później",
				"errorPhone": "Nieprawidłowy numer telefonu",

				"errorPasswordBlank": "mot de passe nie może być puste",
				"errorPasswordIncorrect": "Nieprawidłowe mot de passe",
				"errorPasswordTooLong": "mot de passe nie może być dłuższe niż 60 znaków",
				"errorPasswordTooShort": "mot de passe musi mieć minimum 6 znaków",
				"errorPasswordMismatch": "Podane mot de passe różnią się",

				"errorEmailNotExist": "Adres email już jest zarejestrowany - zaloguj się lub utwórz nowe konto",
				"errorEmailExist": "Niepoprawny adres e-mail lub hasło albo konto nie zostało aktywowane",
				"errorEmailNotActive": "Nie aktywowane konto użytkownika",
				"errorEmailNotFound": "Nie zarejestrowany adres email",
				"errorEmailBlank": "Le champ 'email address' ne peut pas être vide",
				"errorEmailInvalid": "Adres email jest nieprawidłowy",
				"errorEmailTooLong": "Podany adres email jest za długi",

				"errorToken": "Niepoprawny token",

				"errorAgreements": "Brak wymaganych zgód",

				"errorDevice": "Nieprawidłowa platforma urządzenia",

				"errorDirDuplication": "Katalog o tej samej nazwie już istnieje w tej lokalizacji",
				"errorDirIncorrectLenght": "Nazwa musi mieć od 3 do 128 znaków",
				"errorDirInvalid": "Nieprawidłowy katalog",

				"errorFileDuplication": "Plik o tej samej nazwie już istnieje w tej lokalizacji",
				"errorFileIncorrectLenght": "Nazwa musi mieć od 3 do 128 znaków",
				"errorFileInvalid": "Nieprawidłowy plik",

				"errorUuidBlank": "Nazwa musi mieć od 3 do 128 znaków",
				"errorUuidIncorect": "Nieprawidłowy plik",

				"errorCredential": "Niepoprawny adres e-mail lub hasło albo konto nie zostało aktywowane",

				"errorAccessDenied": "Wymagana jest zmiana tymczasowego hasła, aby móc bezpiecznie korzystać z aplikacji",

				"accountActivation": "Aktywacja konta",
				"activationSuccess": "Konto zarejestrowane na adres zostało aktywowane. Może teraz w pełni korzystać z naszej aplikacji",
				"adminRequired": "Tylko administrator ma dostęp do tego zasobu",

				"emailNewPassword": "Witaj!",
				"emailNewPassword2": "Nowe hasło do Twojego konta to:",
				"emailNewPassword3": "Pamiętaj, aby je zmienić wchodząc po zalogowaniu się w edycję profilu!",
				"emailNewPassword4": "Twoje hasło zostało pomyślnie zmienione.",

				"settings": "Ustawienia",
				"storage": "Moje dokumenty",
				"myAccount": "Moje konto",

				"downloadApkInfo": "Możesz też pobrać tę aplikację na swój telefon, wystarczy nacisnąć ten przycisk:",
				"download": "Pobierz",

				//Jeden komunikat podzielony na części za względu na formatowanie tekstu
				"internetModal": "Cette application wymaga do prawidłowego działania dostępu do",
				"internetModal2": "Internetu",
				"internetModal3": " i",
				"internetModal4": "emplacement",
				"internetModal5": "Upewnij się, że te uprawnienia nie są zablokowane.",

				"fallDetectorModal": "Pour votre sécurité niniejsza aplikacja sprawdza przeciążenia oddziałujące na Twoje urządzenie.",
				"fallDetectorModal2": "Gdy zostanie wykryte nienaturalne przeciążenie zostaniesz o tym poinformowany/a. ",
				"fallDetectorModal3": "Vous pouvez désactiver cette fonctionnalité wybierając odpowiednią opcję w ustawieniach aplikacji.",
				"apkBackground": "Aplikacja działa w tle",
				"watchOverloads": "Bada przeciążenia w trosce o Twoje zdrowie.",

				"agreementContent": "Wyrażam zgodę na otrzymanie na podany adres e-mail informacji dotyczących aplikacji 'Help!' niezbędnych do jej prawidłowego działania.",
				"regulationsLink": "Regulamin i polityka prywatności.",

				"tutorialAccount": "Aplikacja daje możliwość założenia konta. Zalogowani użytkownicy posiadają dostęp do funkcjonalności pozwalającej na przechowywanie plików i ich odzyskanie (logując się na innym urządzeniu) w przypadku utraty lub zniszczenia telefonu.",
				"tutorialPass": "Możesz korzystać z aplikacji jako użytkownik niezalogowany - wystarczy nacisnąć Pomiń logowanie. Aplikacja zapamięta Twój wybór - jeżeli zmienisz zdanie odpowiednie opcje znajdziesz w menu.",
				"tutorialDownload": "Możesz też pobrać tę aplikację na swój telefon, wystarczy nacisnąć ikonę powyżej.",
				"tutorialPhones": "Dodatkowo jeśli w zakładce 'Moje konto' dodasz numery telefonu osób do kontaktu zostaną one powiadomione za pomocą SMS-a o Twojej sytuacji wraz z lokalizacją miejsca, w którym się znajdujesz.",
				"tutorialTranslator": "Po zgłoszeniu zdarzenia będziesz mieć dostęp do tłumacza głosowego tłumaczącego w obie strony, który pomoże Ci porozumieć się z osobami udzielającymi Ci pomocy.",
				"tutorialCurrency": "W zdarzeniu 'Granica' znajduje się kalkulator walut pozwalający na szybkie przeliczenie wielu walut według aktualnego kursu NBP.",
				"tutorialHealth": "W zakładce 'Moje zdrowie' możesz dodać informacje o stanie swojego zdrowia oraz przyjmowanych lekach.",
				"tutorialHealth2": "Informacje te mogą być użyteczne dla osób udzielających Ci pomocy.",
				"tutorialEvent": "Jeżeli znajdziesz się w trudnej sytuacji wybierz zdarzenie, w którym uczestniczyłeś(-aś), aby uzyskać przydatne informacje: procedura postępowania, adresy i numery telefonów placówek znajdujących się najbliżej Ciebie.",

				"guestMode": "Nie jesteś zalogowany do aplikacji, zaloguj się, aby skorzystać z pełnej wersji aplikacji. Zarejestruj się jeśli jeszcze nie posiadasz konta.",
				"guestMode2": "Korzystasz z aplikacji jako gość. Jeżeli chcesz się zalogować kliknij odpowiednią opcję w menu.",

				"loginSidebar": "Zaloguj się",
				"register2": "Zarejestruj się",
				"logOut": "Wyloguj się",

				"langChange": "Zmiana języka",
				"langChange2": "Zmień język",
				"chooseSensitivity": "Wybierz czułość",
				"sensitivityAdjusment": "Dostosowanie czułości",
				"sensitivityAdjusment2": "Jeżeli zbyt często wykrywany jest upadek lub uprawiasz sport ekstremalny możesz wyregulować czułość.",
				"restoreDefaults": "Przywróć domyślne",

				"sendSmsCanceled": "Wysyłanie SMS-a zostało anulowane.",
				"changeSensitivityPopup": "Jeśli chcesz możesz zmienić czułość wykrywania upadku.",
				"canceled": "Anulowano",
				"mute": "Wycisz",
				"yourEmail": "Twój adres e-mail",
				"exit": "Zakończ",
				"next": "Dalej",
				"info": "Info",
				"singleSound": "Pojedynczy",
				"singleSound2": "dźwięk",
				"manySound": "Dźwięk",
				"manySound2": "powtarzany",
				"fallPopupSound": "Dźwięk przy wykryciu upadku",
				"fallPopupSoundInfo": "Jeżeli zostanie wykryty upadek zostaniesz o tym poinformowany",
				"fallPopupSoundInfo2": "w formie komunikatu. Towarzyszyć temu może: ",
				"fallPopupSoundInfo3": "pojedynczy dźwięk",
				"fallPopupSoundInfo4": "- tylko przy wywołaniu komunikatu lub ",
				"fallPopupSoundInfo5": "dźwięk powtarzany ",
				"fallPopupSoundInfo6": " przez cały czas wyświetlania komunikatu.",

				"dataSaveSuccess": "Dane zostały uzupełnione poprawnie!",
				"langSaveSuccess": "Język został zmieniony poprawnie",

				"appError": "Wystąpił błąd w działaniu aplikacji. Spróbuj ponownie później.",
				"myHealthEdit": "Moje zdrowie - edycja",
				"edit": "Edytuj",

				"overloadDetectionInfo2": "Gdy opcja jest wyłączona nie zostaniesz powiadomiona/y o wykryciu nienaturalnego przeciążenia co może mieć wpływ na Twoje zdrowie.",
				"advanced": "Zaawansowane",

				// Skrótowe oznaczenie walut (ISO) do przetłumaczenia tylko na rosyjski
				"eur": "EUR",
				"pln": "PLN",
				"usd": "USD",
				"chf": "CHF",
				"gbp": "GBP",
				"huf": "HUF",
				"uah": "UAH",
				"czk": "CZK",
				"dkk": "DKK",
				"isk": "ISK",
				"nok": "NOK",
				"sek": "SEK",
				"hrk": "HRK",
				"ron": "RON",
				"bgn": "BGN",
				"try": "TRY",
				"rub": "RUB",
				"jpy": "JPY",
				"cny": "CNY",
				"egp": "EGP",
				"tnd": "TND",
				"amd": "AMD",
				"gel": "GEL",
				"mdl": "MDL",
				"all": "ALL",
				"rsd": "RSD",
				"mkd": "MKD",
				"azn": "AZN",
				"byn": "BYN",
				"bam": "BAM",
			}
		},
		ru: {
			translations: {
				"langName": "русский",

				"loading": "Ładowanie...",

				"Login": "Войти",
				"loginInfo": "Zalogowani użytkownicy mają dostęp do dodatkowych funkcjonalności.",
				"writeEmail": "Wpisz e-mail",
				"writePassword": "Podaj hasło",
				"loginButton": "Zaloguj",
				"forgetPassword": "Zapomniałeś hasła?",
				"registerLink": "Rejestracja",
				"skipLogin": "Pomiń logowanie",
				"downloadApk": "Pobierz aplikację:",

				"passwordReset": "Resetowanie hasła",
				"passwordResetInfo": "Wpisz e-mail, którego użyłeś do logowania. Zostanie na niego wysłane hasło tymczasowe.",
				"passwordResetButton": "Resetuj hasło",
				"returnToLogin": "Wróć do logowania",

				"registration": "регистрация",
				"email": "E-mail",
				"password": "Hasło",
				"confirmPassword": "Potwierdź hasło",
				"register": "Zarejestruj",

				"registerCorrect": "Zarejestrowano poprawnie",
				"accountCreated": "Konto zostało utworzone.",
				"accountCreated2": "Na podany adres email wysłano wiadomość z linkiem aktywacyjnym.",
				"ok": "Ok",

				"chooseEvent": "Выберите событие, ",
				"chooseEvent2": "w którym się znalazłeś/znalazłaś.",

				"fallDetected": "Wykryto upadek!",
				"fallDetectedInfo": "Po upływie 30 sekund zostanie wysłany SMS.",
				"fallDetectedInfo2": "Aby przerwać odliczanie i anulować wysłanie SMSa kliknij przycisk poniżej.",
				"cancel": "Anuluj",
				"smsSent": "SMS wysłany!",
				"waitForHelp": "Oczekuj na pojawienie się pomocy.",

				"drive": "Dojazd",
				"call": "Zadzwoń",
				"otherFacilities": "Pozostałe placówki",
				"myHealth": "Moje zdrowie",

				"back": "wróć",
				"close": "Zamknij",

				"geolocationError": "Bład. Twoje położenie nie mogło zostać przesłane. Upewnij się, że aplikacja nie ma zablokowanego dostępu do geolokalizacji.",
				"recoverDocuments": "Odzyskaj dokumenty:",
				"recoverDocumentsInfo": "Odzyskaj dokumenty:",
				"downloadCopy": "Pobierz kopię dokumentów",

				"forms": "Formularze",
				"downloadedForms": "Pobrane formularze",
				"downloadForms": "Pobierz formularze",
				"archivedForms": "Archiwalne formularze",
				"fillForms": "Uzupełnij i wyślij niezbędne formularze.",

				"user": "Użytkownik",
				"personalData": "Dane osobowe:",
				"health": "Stan zdrowia:",
				"diseases": "Choroby:",
				"medicines": "Przyjmowane leki:",
				"translate": "Przetłumacz",

				"procedure": "Procedura",
				"procedureSteps": "Procedura postępowania",

				"save": "Zapisz",

				"overloadDetection": "Wykrywanie upadku",
				"overloadDetectionInfo": "Gdy opcja będzie wyłączona nie zostaniesz powiadomiona/y o wykryciu nienaturalnego przeciążenia co może mieć wpływ na Twoje zdrowie.",
				"overloadDetectionPhone": "Do działania tej funkcjonalności niezbędne jest podanie conajmniej jednego poprawnego numeru telefonu. Numer telefonu podasz wchodząc w",
				"overloadDetectionApk": "Funkcjonalność dostępna tylko w aplikacji mobilnej",

				"off": "Wyłączone",
				"on": "Włączone",

				"success": "Sukces",
				"successPasswordChange": "Hasło zostało zmienione!",

				"actualPassword": "Dotychczasowe hasło",
				"newPassword": "Nowe hasło",
				"confirmNewPassword": "Potwierdź nowe hasło",

				"alarmPhones": "экстренные вызовы",
				"alarmPhonesInfo": "Wpisz numery telefonów osób, które mamy poinformować za pośrednictwem SMS o nagłej sytuacji oraz wpisz jak mamy Cię przedstawić w wiadomości.",

				"passwordChange": "Zmień hasło",
				"passwordChangeInfo": "Aby zmienić hasło podaj aktualne hasło oraz dwa razy nowe hasło i kliknij zapisz.",

				"writeData": "Wpisz w poniższe pola:",
				"basicPersonalData": "- podstawowe dane osobowe",
				"healthElement": "- stan zdrowia",
				"diseasesElement": "- choroby, które przechodzisz",
				"medicinesElement": "- leki, które przyjmujesz",
				"writeDataInfo": "Zebranie tych informacji w jednym miejscu będzie dla osoby udzielającej Ci pomocy najważniejszym źródłem wiedzy.",
				"writeDataLanguage": "Uzupełniając dane pamiętaj aby informacje podawać w uproszczonym języku. Informacje o chorobach i lekach w miarę możliwości podawaj w nazwach łacińskich.",
				"writeDataPlace": "Dostęp do tych informacji będzie możliwy poprzez naciśnięcie przycisku 'Moje zdrowie' w dole aplikacji.",
				"myData": "Moje dane:",

				"error": "Błąd",
				"add": "Dodaj",

				"addDir": "Dodaj folder",
				"dirName": "Nazwa folderu",
				"dirSuccess": "Folder został dodany poprawnie",
				"change": "Zmień",
				"chooseFile": "Wybierz plik...",
				"fileChosen": "Wybrano plik:",

				"addFile": "Dodaj plik",
				"addFileSuccess": "Plik został dodany poprawnie",
				"addFolder": "dodaj katalog",
				"addFile2": "dodaj plik",

				"closest": "najbliżej Ciebie:",

				"calculate": "Przelicz",
				"dateRate": "Kurs z dnia ",
				"todayRate": "Dzisiejszy kurs",
				"currencyCalculator": "Kalkulator walut",

				"offlineApk": "Aktualnie aplikacja działa w trybie offline - aby skorzystać z tej funkcjonalności połącz się z siecią.",

				"call112": "Zadzwoń pod 112",

				"seeOther": "Zobacz pozostałe placówki",

				"confirmDelete": "Czy na pewno chcesz usunąć",
				"confirmDeleteFile": "Czy na pewno chcesz usunąć ten plik?",
				"confirmDeleteDir": "Czy na pewno chcesz usunąć ten folder?",

				"yes": "Tak",
				"no": "Nie",

				"deleteDir": "Usuń folder",
				"dirNotEmpty": "Folder, który chcesz usunąć nie jest pusty.",
				"dirNotEmptyDelete": "Czy na pewno chcesz go usunąć?",
				"deleteAll": "Usuń folder wraz z zawartością",
				"dirDeleteSuccess": "Folder został usunięty poprawnie",
				"fileDeleteSuccess": "Plik został usunięty poprawnie",

				"dir": "folder",
				"file": "plik",
				"moveDir": "Przenieś folder",
				"chooseLocation": "Wybierz miejsce docelowe folderu",
				"moveSuccess": "został przeniesiony",
				"moveSuccessFile": "Plik został przeniesiony",
				"moveSuccessDir": "Folder został przeniesiony",

				"changeName": "Zmień nazwę",
				"changeNameSuccess": "Nazwa została zmieniona",

				"mainDir": "Katalog główny",
				"move": "Przenieś",
				"delete": "Usuń",

				"yourChoice": "Wybrałeś/wybrałaś:",
				"yourChoiceInfo": "Nie wiesz co zrobić? Zobacz procedurę postępowania w tym przypadku klikając w przycisk poniżej.",
				"seeProcedure": "Zobacz procedurę",

				"translation": "Kierunek tłumaczenia:",
				"translationButton": "Użyj przycisku i zacznij mówić. Aby przetłumaczyć, kliknij ponownie.",
				"wrongLanguage": "Niepoprawny język syntezatora? Kliknij tutaj, aby doinstalować język.",
				"microphoneNeeded": "Aplikacja potrzebuje dostępu do mikrofonu, aby tłumaczyć mowę.",
				"allow": "Zezwól",

				"chrome": "Aby użyć translatora mowy, przełącz się na przeglądarkę Chrome.",

				"speechRecognition": "Tłumaczenie mowy",

				"onlineTranslator": "W razie problemów możesz skorzystać również z tłumacza online:",
				"onlineTranslatorOn": "Włącz - tłumacz online",

				"event1": "Экстренная медицинская авария",
				"event2": "Автомобильная авария",
				"event3": "Поломка машины",
				"event4": "кража",
				"event5": "граница",

				"smsContentFall": "zglosil w aplikacji Help! zdarzenie 'Upadek'. Jego lokalizacja to:",

				"errorLoginMessage401": "Nieprawidłowy e-mail lub hasło",
				"errorLoginMessage404": "Wystąpił nieoczekiwany błąd. Spróbuj ponownie później",
				"errorPhone": "Nieprawidłowy numer telefonu",

				"errorPasswordBlank": "пароль nie może być puste",
				"errorPasswordIncorrect": "Nieprawidłowe пароль",
				"errorPasswordTooLong": "пароль nie może być dłuższe niż 60 znaków",
				"errorPasswordTooShort": "пароль musi mieć minimum 6 znaków",
				"errorPasswordMismatch": "Podane пароль różnią się",

				"errorEmailNotExist": "Adres email już jest zarejestrowany - zaloguj się lub utwórz nowe konto",
				"errorEmailExist": "Niepoprawny adres e-mail lub hasło albo konto nie zostało aktywowane",
				"errorEmailNotActive": "Nie aktywowane konto użytkownika",
				"errorEmailNotFound": "Nie zarejestrowany adres email",
				"errorEmailBlank": "Поле «адрес электронной почты» не может быть пустым",
				"errorEmailInvalid": "Adres email jest nieprawidłowy",
				"errorEmailTooLong": "Podany adres email jest za długi",

				"errorToken": "Niepoprawny token",

				"errorAgreements": "Brak wymaganych zgód",

				"errorDevice": "Nieprawidłowa platforma urządzenia",

				"errorDirDuplication": "Katalog o tej samej nazwie już istnieje w tej lokalizacji",
				"errorDirIncorrectLenght": "Nazwa musi mieć od 3 do 128 znaków",
				"errorDirInvalid": "Nieprawidłowy katalog",

				"errorFileDuplication": "Plik o tej samej nazwie już istnieje w tej lokalizacji",
				"errorFileIncorrectLenght": "Nazwa musi mieć od 3 do 128 znaków",
				"errorFileInvalid": "Nieprawidłowy plik",

				"errorUuidBlank": "Nazwa musi mieć od 3 do 128 znaków",
				"errorUuidIncorect": "Nieprawidłowy plik",

				"errorCredential": "Niepoprawny adres e-mail lub hasło albo konto nie zostało aktywowane",

				"errorAccessDenied": "Wymagana jest zmiana tymczasowego hasła, aby móc bezpiecznie korzystać z aplikacji",

				"accountActivation": "Aktywacja konta",
				"activationSuccess": "Konto zarejestrowane na adres zostało aktywowane. Może teraz w pełni korzystać z naszej aplikacji",
				"adminRequired": "Tylko administrator ma dostęp do tego zasobu",

				"emailNewPassword": "Witaj!",
				"emailNewPassword2": "Nowe hasło do Twojego konta to:",
				"emailNewPassword3": "Pamiętaj, aby je zmienić wchodząc po zalogowaniu się w edycję profilu!",
				"emailNewPassword4": "Twoje hasło zostało pomyślnie zmienione.",

				"settings": "Ustawienia",
				"storage": "Storage",
				"myAccount": "Moje konto",

				"downloadApkInfo": "Możesz też pobrać tę aplikację na swój telefon, wystarczy nacisnąć ten przycisk:",
				"download": "Pobierz",

				//Jeden komunikat podzielony na części za względu na formatowanie tekstu
				"internetModal": "Это приложение wymaga do prawidłowego działania dostępu do",
				"internetModal2": "Internetu",
				"internetModal3": " i",
				"internetModal4": "lokalizacji",
				"internetModal5": "Upewnij się, że te uprawnienia nie są zablokowane.",

				"fallDetectorModal": "W trosce o Twoje bezpieczeństwo niniejsza aplikacja sprawdza przeciążenia oddziałujące na Twoje urządzenie.",
				"fallDetectorModal2": "Gdy zostanie wykryte nienaturalne przeciążenie zostaniesz o tym poinformowany/a. ",
				"fallDetectorModal3": "Вы можете отказаться от этой функции wybierając odpowiednią opcję w ustawieniach aplikacji.",
				"apkBackground": "Aplikacja działa w tle",
				"watchOverloads": "Bada przeciążenia w trosce o Twoje zdrowie.",

				"agreementContent": "Wyrażam zgodę na otrzymanie na podany adres e-mail informacji dotyczących aplikacji 'Help!' niezbędnych do jej prawidłowego działania.",
				"regulationsLink": "Regulamin i polityka prywatności.",

				"tutorialAccount": "Aplikacja daje możliwość założenia konta. Zalogowani użytkownicy posiadają dostęp do funkcjonalności pozwalającej na przechowywanie plików i ich odzyskanie (logując się na innym urządzeniu) w przypadku utraty lub zniszczenia telefonu.",
				"tutorialPass": "Możesz korzystać z aplikacji jako użytkownik niezalogowany - wystarczy nacisnąć Pomiń logowanie. Aplikacja zapamięta Twój wybór - jeżeli zmienisz zdanie odpowiednie opcje znajdziesz w menu.",
				"tutorialDownload": "Możesz też pobrać tę aplikację na swój telefon, wystarczy nacisnąć ikonę powyżej.",
				"tutorialPhones": "Dodatkowo jeśli w zakładce 'Moje konto' dodasz numery telefonu osób do kontaktu zostaną one powiadomione za pomocą SMS-a o Twojej sytuacji wraz z lokalizacją miejsca, w którym się znajdujesz.",
				"tutorialTranslator": "Po zgłoszeniu zdarzenia będziesz mieć dostęp do tłumacza głosowego tłumaczącego w obie strony, który pomoże Ci porozumieć się z osobami udzielającymi Ci pomocy.",
				"tutorialCurrency": "W zdarzeniu 'Granica' znajduje się kalkulator walut pozwalający na szybkie przeliczenie wielu walut według aktualnego kursu NBP.",
				"tutorialHealth": "W zakładce 'Moje zdrowie' możesz dodać informacje o stanie swojego zdrowia oraz przyjmowanych lekach.",
				"tutorialHealth2": "Informacje te mogą być użyteczne dla osób udzielających Ci pomocy.",
				"tutorialEvent": "Jeżeli znajdziesz się w trudnej sytuacji wybierz zdarzenie, w którym uczestniczyłeś(-aś), aby uzyskać przydatne informacje: procedura postępowania, adresy i numery telefonów placówek znajdujących się najbliżej Ciebie.",

				"guestMode": "Nie jesteś zalogowany do aplikacji, zaloguj się, aby skorzystać z pełnej wersji aplikacji. Zarejestruj się jeśli jeszcze nie posiadasz konta.",
				"guestMode2": "Korzystasz z aplikacji jako gość. Jeżeli chcesz się zalogować kliknij odpowiednią opcję w menu.",

				"loginSidebar": "Zaloguj się",
				"register2": "Zarejestruj się",
				"logOut": "Wyloguj się",

				"langChange": "Zmiana języka",
				"langChange2": "Zmień język",
				"chooseSensitivity": "Wybierz czułość",
				"sensitivityAdjusment": "Dostosowanie czułości",
				"sensitivityAdjusment2": "Jeżeli zbyt często wykrywany jest upadek lub uprawiasz sport ekstremalny możesz wyregulować czułość.",
				"restoreDefaults": "Przywróć domyślne",

				"sendSmsCanceled": "Wysyłanie SMS-a zostało anulowane.",
				"changeSensitivityPopup": "Jeśli chcesz możesz zmienić czułość wykrywania upadku.",
				"canceled": "Anulowano",
				"mute": "Wycisz",
				"yourEmail": "Twój adres e-mail",
				"exit": "Zakończ",
				"next": "Dalej",
				"info": "Info",
				"singleSound": "Pojedynczy",
				"singleSound2": "dźwięk",
				"manySound": "Dźwięk",
				"manySound2": "powtarzany",
				"fallPopupSound": "Dźwięk przy wykryciu upadku",
				"fallPopupSoundInfo": "Jeżeli zostanie wykryty upadek zostaniesz o tym poinformowany",
				"fallPopupSoundInfo2": "w formie komunikatu. Towarzyszyć temu może: ",
				"fallPopupSoundInfo3": "pojedynczy dźwięk",
				"fallPopupSoundInfo4": "- tylko przy wywołaniu komunikatu lub ",
				"fallPopupSoundInfo5": "dźwięk powtarzany ",
				"fallPopupSoundInfo6": " przez cały czas wyświetlania komunikatu.",

				"dataSaveSuccess": "Dane zostały uzupełnione poprawnie!",
				"langSaveSuccess": "Język został zmieniony poprawnie",

				"appError": "Wystąpił błąd w działaniu aplikacji. Spróbuj ponownie później.",
				"myHealthEdit": "Moje zdrowie - edycja",
				"edit": "Edytuj",

				"overloadDetectionInfo2": "Gdy opcja jest wyłączona nie zostaniesz powiadomiona/y o wykryciu nienaturalnego przeciążenia co może mieć wpływ na Twoje zdrowie.",
				"advanced": "Zaawansowane",
				// Skrótowe oznaczenie walut (ISO) do przetłumaczenia tylko na rosyjski
				"eur": "EUR",
				"pln": "PLN",
				"usd": "USD",
				"chf": "CHF",
				"gbp": "GBP",
				"huf": "HUF",
				"uah": "UAH",
				"czk": "CZK",
				"dkk": "DKK",
				"isk": "ISK",
				"nok": "NOK",
				"sek": "SEK",
				"hrk": "HRK",
				"ron": "RON",
				"bgn": "BGN",
				"try": "TRY",
				"rub": "RUB",
				"jpy": "JPY",
				"cny": "CNY",
				"egp": "EGP",
				"tnd": "TND",
				"amd": "AMD",
				"gel": "GEL",
				"mdl": "MDL",
				"all": "ALL",
				"rsd": "RSD",
				"mkd": "MKD",
				"azn": "AZN",
				"byn": "BYN",
				"bam": "BAM",
			}
		},

	},
	fallbackLng: {
		'en-US': ['en'],
		'pl-Pl': ['pl'],
		default: ['en'],
	},
	debug: false,

	// have a common namespace used around the full app
	ns: ["translations"],
	defaultNS: "translations",

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ","
	},

	react: {
		wait: true
	}
});

export default i18n;
