import axios from 'axios/index'
import React, {Component} from 'react'
import isoLang from '../data/isoLangs'
import {translate} from "react-i18next";

class SpeechRecognitionWeb extends Component {
	constructor(props) {
		super(props);

		this.state = {
			permissionGranted: true,
			speechToText: '',
			listening: false,
			translateTo: 'de-DE',
			translateFrom: localStorage.getItem('i18nextLng'),
			speaking: false,
			fromLangName: '',
			toLangName: '',
			reverseLangs: false

		};
	}
	initTranslate = () => {
		if (navigator.userAgent.indexOf("Chrome") !== -1) {
			this.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
			this.recognition = new this.SpeechRecognition();

			this.recognition.continous = false;
			this.recognition.interimResults = true;
			this.recognition.lang = this.state.translateFrom;

		}
		this.translateToShortened = this.state.translateTo.split('-')[0];
		this.translateFromShortened = this.state.translateFrom.split('-')[0];
		if (!this.state.reverseLangs) {
			for (let i in isoLang) {
				if (isoLang.hasOwnProperty(i)) {
					if (this.translateToShortened === i) {
						this.setState({
							toLangName: isoLang[i].nativeName
						});
					}
					if (this.translateFromShortened === i) {
						this.setState({
							fromLangName: isoLang[i].nativeName
						});
					}
				}
			}
		} else {
			let from = this.state.fromLangName;
			let to = this.state.toLangName;
			this.setState({
				fromLangName: to,
				toLangName: from
			})
		}
	};
	componentDidMount() {
		this.initTranslate();
	}
	toggleListen = () => {
		this.setState({
			listening: !this.state.listening
		}, this.handleListen);
	};

	handleListen = () => {
		if (this.state.listening) {
			this.recognition.start();
			this.recognition.onend = () => {
				this.recognition.start()
			}

		} else {
			this.recognition.stop();
			this.recognition.onend = () => {
				axios.get('https://translate.yandex.net/api/v1.5/tr.json/translate?key=trnsl.1.1.20190107T102846Z.ec41886338390a8a.2447a4d1853ae300a7e14b6417831085e05ea4ef' +
					'&text=' + this.state.speechToText + '&lang=' + this.translateFromShortened + '-' + this.translateToShortened)
					.then(response => {
						document.getElementById('final').innerHTML = response.data.text;
						let utterance = new SpeechSynthesisUtterance(response.data.text);
						utterance.lang = this.state.translateTo;
						utterance.volume = 5;
						speechSynthesis.speak(utterance);
						this.setState({
							speaking: true
						});
					})
					.catch(() => {
					})
			}
		}
		let finalTranscript = '';
		this.recognition.onresult = event => {
			let interimTranscript = '';

			for (let i = event.resultIndex; i < event.results.length; i++) {
				const transcript = event.results[i][0].transcript;
				if (event.results[i].isFinal) finalTranscript += transcript + ' ';
				else interimTranscript += transcript;
			}
			document.getElementById('interim').innerHTML = interimTranscript;
			document.getElementById('final').innerHTML = finalTranscript;
			this.setState({
				speechToText: finalTranscript
			});
		};
	};
	checkIsTextIsTranslated() {
		if (this.state.speaking) {
			return <button className="btn default-button speech-button orange-btn" onClick={this.sayAgain}>
				<i className="fas fa-redo"/>
			</button>
		}
	}
	sayAgain = () => {
		let utterance = new SpeechSynthesisUtterance(document.getElementById('final').innerHTML);
		utterance.lang = this.state.translateTo;
		utterance.volume = 5;
		speechSynthesis.speak(utterance);
	};
	reverseLang = () => {
		let from = this.state.translateFrom;
		let to = this.state.translateTo;
		this.setState({
			translateFrom: to,
			translateTo: from,
			reverseLangs: !this.state.reverseLangs
		}, this.initTranslate);
	};
	render() {
		const {t} = this.props;

		return <div>
			<div className="bg-content" style={{backgroundImage: 'url(' + require('../images/tlumacz.svg') + ')'}}/>
			<p>{t('translation')}</p>
			<button className="btn default-button width-auto" onClick={this.reverseLang}><p className="upper">{this.state.fromLangName} <i className="fas fa-long-arrow-alt-right "/>{this.state.toLangName}</p></button>
			<p>{t('translationButton')}</p>
			<button className={"btn default-button speech-button" + (this.state.listening ? " listening" : " ")}
			        onClick={this.toggleListen}>
				<i className="fas fa-microphone"/>
			</button>
			{this.checkIsTextIsTranslated()}
			<div id='final'/>
			<div id='interim'/>
		</div>
	}
}
export default translate("translations")(SpeechRecognitionWeb);
