import {Component} from 'react';
import ReactDOM from "react-dom";
import {withRouter} from 'react-router-dom';

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if ((this.props.location !== prevProps.location) && ReactDOM.findDOMNode(this) !== null) {
			ReactDOM.findDOMNode(this).scrollIntoView({block: "start", inline: "start"});
		}
	}

	render() {

		return this.props.children
	}
}

export default withRouter(ScrollToTop)
