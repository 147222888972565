import React, {Component} from 'react';
import {translate} from "react-i18next";

class Form extends Component {
	render() {
		const {t} = this.props;

		if (this.props.form) {
			return <div className="page-div border-radius-all">
				<div className="bg-content" style={{backgroundImage: 'url(' + require('../images/formularze.svg') + ')'}}/>
				<p className="margin-bottom-20"><strong>{t("forms")}:</strong></p>
				<p>{t("fillForms")}</p>
				<a className="btn default-button margin-top-10" rel="noopener noreferrer" href={this.props.form}
				   target="_blank">{t("downloadForms")}</a>
			</div>
		}
		else {
			return false;
		}
	}
}
export default translate("translations")(Form);
