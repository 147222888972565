import {Route, Switch} from 'react-router-dom';

import Account from './views/Account';
import Category from './views/Category';
import Content from './views/Content';
import Directory from './views/Directory';
import DirectorySecond from './views/DirectorySecond';
import Event from './views/Event';
import Forms from './views/Forms';
import Home from './views/Home';
import Login from './views/Login';
import Language from './views/Language';
import Medicines from './views/Medicines';
import Procedure from './views/Procedure';
import Settings from './views/Settings';
import Storage from './views/Storage';

import {translate} from "react-i18next";


import './styles/bootstrap.css';
import './styles/font-awesome.css';
import './styles/global.css';

import React, {Component} from "react";
import TutorialBefore from "./components/TutorialBefore";

class App extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.state = {
			// seenApplicationMessage: localStorage.getItem('seenApplicationMessage') === 'true',
			// seenRequirementsMessage: localStorage.getItem('seenRequirementsMessage') === 'true',
			// seenFallDetectorMessage: localStorage.getItem('seenFallDetectorMessage') === 'true',
			seenTutorial:  localStorage.getItem('seenTutorial')
		};
	}

	seeApplicationMessage() {
		this.setState({
			seenApplicationMessage: 'true'
		});

		localStorage.setItem('seenApplicationMessage', 'true');
	}

	seeRequirementsMessage() {
		this.setState({
			seenRequirementsMessage: 'true'
		});

		localStorage.setItem('seenRequirementsMessage', 'true');
	}

	seeFallDetectorMessage() {
		this.setState({
			seenFallDetectorMessage: 'true'
		});

		localStorage.setItem('seenFallDetectorMessage', 'true');
	}

	renderApplicationPopup() {
		const {t} = this.props;
			return (
				<div className="popup" id="errorPopup">
					<h6 className="modal-title" id="errorModal">{t("downloadApkInfo")}</h6>
					<a className="btn default-button margin-top-10" href="https://helphelptrip.com/android.apk"
					   onClick={this.seeApplicationMessage.bind(this)}>{t("download")}</a>
					<button className="btn default-button full-width-button margin-top-10"
					        onClick={this.seeApplicationMessage.bind(this)}>{t("close")}</button>
				</div>
			);

	}

	renderRequirementsPopup() {
		const {t} = this.props;

		return (
			<div className="popup" id="errorPopup">
				<h6 className="modal-title"
				    id="errorModal">{t("internetModal")}<b> {t("internetModal2")}</b> {t("internetModal3")}<b> {t("internetModal4")}.</b> {t("internetModal5")}
				</h6>

				{/*"fallDetectorModal": "W trosce o Twoje bezpieczeństwo niniejsza aplikacja sprawdza przeciążenia oddziałujące na Twoje urządzenie. <br/> Gdy zostanie wykryte nienaturalne przeciążenie zostaniesz o tym poinformowany/a. <br/>Możesz zrezygnować z tej funkcji wybierając odpowiednią opcję w ustawieniach aplikacji.",*/}

				<button className="btn default-button full-width-button margin-top-10"
				        onClick={this.seeRequirementsMessage.bind(this)}>{t("close")}</button>
			</div>
		);
	}

	renderFallDetectorPopup() {
		const {t} = this.props;

		return (
			<div className="popup" id="errorPopup">
				<h6 className="modal-title" id="errorModal">{t("fallDetectorModal")} <br/> {t("fallDetectorModal2")}
					<br/> {t("fallDetectorModal3")} </h6>
				<button className="btn default-button full-width-button margin-top-10"
				        onClick={this.seeFallDetectorMessage.bind(this)}>{t("close")}</button>
			</div>
		);
	}
	callbackOnEndTutorialBefore = () => {
		this.setState({
			seenTutorial: true
		});
		localStorage.setItem('seenTutorial', 'true');
	};
	render() {

		if (!this.state.seenTutorial) {
			return <TutorialBefore callback={this.callbackOnEndTutorialBefore} />
		}

		return <Switch>
			<Route component={Login} exact path="/"/>
			<Route component={Home} exact path="/menu"/>
			<Route component={Account} exact path="/konto"/>
			<Route component={Event} exact path="/event/:eventId"/>
			<Route component={Procedure} exact path="/event/:eventId/procedure"/>
			<Route component={Content} exact path="/event/:eventId/content"/>
			<Route component={Category} exact path="/event/:eventId/category/:categoryId"/>
			<Route component={Medicines} exact path="/zdrowie"/>
			<Route component={Language} exact path="/lang"/>
			<Route component={Settings} exact path="/ustawienia"/>
			<Route component={Storage} exact path="/storage"/>
			<Route component={Directory} exact path="/storage/:directory"/>
			<Route component={DirectorySecond} exact path="/storage/:directory/:directory2"/>
			<Route component={Forms} exact path="/event/:eventId/forms"/>
		</Switch>
	}
}

export default translate("translations")(App);
