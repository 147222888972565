import axios from 'axios/index';
import React, {Component} from 'react';
import {HashLink as Link} from 'react-router-hash-link';
import Header from '../components/Header';
import {translate} from "react-i18next";

import TutorialMain from "../components/TutorialMain";


class Home extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.state = {
			isLoaded: false,
			events: [],
			error: false,
			loggedIn: false,
			offlineData: '',
			offlineDataObject: '',
			writeFile: false,
			writeObjectFile: false,
			internetConnection: false,
			notMobile: '',
			goHome: false,
			data: {},
			name: localStorage.getItem('name') || 'Użytkownik',
			phone: localStorage.getItem('phone'),
			phone2: localStorage.getItem('phone2'),
			geolocationSuccess: false,
			latitude: '',
			longitude: '',
			accidentSave: false,
			objectSave: false,
			fallDetector: localStorage.getItem('fallDetector'),
			wantToLogOut: false,
			sidebarOpen: false,
			seenTutorialMain: localStorage.getItem('seenTutorialMain'),
			locationData: []
		};
		localStorage.setItem('messageSend', 'false');
		this.iterator = 0
	}

	componentDidMount = () => {

		if (localStorage.getItem('guest') === 'true') {
			this.setState({
				isLoaded: true,
				loggedIn: true
			})
		}

		if (this.isCordova) {

			document.addEventListener("online", this.showCurrency, false);
			document.addEventListener("offline", this.noInternetConnention, false);
			if (navigator.connection.type !== 'none') {
				this.setState({
					internetConnection: true
				})
			} else {
				this.setState({
					internetConnection: false
				})
			}
		}

		if (localStorage.getItem('token') !== null) {

			this.setState({
				isLoaded: true,
				loggedIn: true
			});

			axios.get('https://panel.wurren.optize.pl/wurren/accident')
				.then(res => res.data)
				.then((result) => {
						const event = [];
						const events = [];
						const {t} = this.props;

						for (let i in result.data) {
							if (result.data.hasOwnProperty(i)) {
								events.push({
									id: result.data[i].id,
									name: result.data[i].attributes.title,
									icon: result.data[i].attributes.icon.url
								});
							}
						}
						this.setState({
							isLoaded: true,
							events
						});
					},
					(error) => {
						this.setState({
							internetConnection: false,
							isLoaded: true,
							events: [
								{
									id: 1,
									name: 'Nagły wypadek zdrowotny',
									icon: 'nagly-wypadek-zdrowotny.svg'
								},
								{
									id: 2,
									name: 'Wypadek samochodowy',
									icon: 'wypadek-samochodowy.svg'
								},
								{
									id: 3,
									name: 'Awaria samochodu',
									icon: 'awaria-samochodu.svg'
								},
								{
									id: 4,
									name: 'Kradzież',
									icon: 'kradziez.svg'
								},
								{
									id: 5,
									name: 'Granica',
									icon: 'granica.svg'
								}
							],
							error
						});
					}
				);
		} else {
			this.setState({
				isLoaded: true,
				loggedIn: false
			});
		}
	};

	callbackOnEndTutorial = () => {
		this.setState({
			seenTutorialMain: true
		});
		localStorage.setItem('seenTutorialMain', 'true');
	};

	render() {
		const {t} = this.props;
		if (this.state.isLoaded && this.state.loggedIn) {
			if (!this.state.seenTutorialMain) {
				return <TutorialMain callback={this.callbackOnEndTutorial}/>

			}
			return <div className={"bg " + (this.props.location.pathname === '/menu' ? 'bg-menu' : ' ')}>
				<Header/>
				<div className="wrapper">
					<div className="container-fluid col-12 flex-con">
						<div className="row no-gutters bg-alpha">
							<div className="col-12 flex-con-wrapper">
								<h6 className="title">
									<div>
										{/*{this.state.locationData}*/}
									</div>
									<strong>{t("chooseEvent")}</strong> {t("chooseEvent2")}</h6>
								<div className="button-wrapper">
									{this.state.events.map((event, index) => {
										if (this.state.error) {
											return <Link key={index} to={{
												pathname: '/event/' + event.id,
												state: {internetConnection: false}
											}}
											             className="emergency-main-buttons">
												<div className="em-img">
													<img src={require('../images/' + event.icon)} alt=""/>
												</div>
												<div className="em-title">
													<h6>{event.name}</h6>
												</div>
											</Link>
										} else {
											return <Link key={index} to={{
												pathname: '/event/' + event.id,
												state: {internetConnection: true}
											}} alt=""
											             className="emergency-main-buttons">
												<div className="em-img">
													<img src={event.icon} alt=""/></div>
												<div className="em-title">
													<h6>{event.name}</h6>
												</div>
											</Link>
										}
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		} else {

			return <div className="bg">
				<div className="centering">
					<p className="center text-center">
						<img src={require('../images/logo.png')} alt="" className="logo-in-spinner"/>
						<i className="fas fa-spinner"/>
						{t("loading")}

					</p>
				</div>
			</div>
		}
	}
}

export default translate("translations")(Home);
