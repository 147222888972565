import React, {Component} from 'react';
import {translate} from "react-i18next";
import Sound from 'react-sound';
import ReactCountdownClock from 'react-countdown-clock';
import axios from 'axios/index';
import ReactSpeedometer from 'react-d3-speedometer'

class FallDetector extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.state = {
			latitude: '',
			longitude: '',
			fallDetector: localStorage.getItem('fallDetector'),
			watchID: localStorage.getItem('watchID'),
			watchIDSaved: false,
			sound: localStorage.getItem('sound'),
			stopped: false,
			phone2: localStorage.getItem('phone2'),
			phone: localStorage.getItem('phone'),
			name: localStorage.getItem('name') || 'Uzytkownik',
			smv: localStorage.getItem('fallDetectorSmv'),

		};
	}

	componentDidMount() {
		const {t} = this.props;

		if (this.props.title !== t('settings') && this.isCordova) {
			// if (this.state.watchID !== undefined && this.state.watchID !== null ) {
			// 	navigator.accelerometer.clearWatch(localStorage.getItem('watchID'));
			// 	localStorage.setItem('watchID', null)
			// }
			if (this.state.watchID === undefined) {
				localStorage.setItem('watchID', null);
			}
			if (this.isCordova && this.state.fallDetector === "1") {
				document.addEventListener("deviceready", () => {
					window.cordova.plugins.backgroundMode.enable();
					window.cordova.plugins.backgroundMode.setDefaults({
						title: 'Aplikacja działa w tle',
						text: 'Bada przeciążenia',
						hidden: false
					});
					this.fallWatch();
				}, false);

			}

			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					this.setState({
						geolocationSuccess: true,
						latitude: position.coords.latitude,
						longitude: position.coords.longitude
					});

				}, () => {
					this.setState({
						geolocationSuccess: false
					});
				});
			} else {
				this.setState({
					geolocationSuccess: false
				});
			}
		}
		if (this.state.smv === undefined) {
			this.setState({
				smv: 21
			})
		}
	}

	fallWatch = () => {
		if (this.state.watchID !== undefined) {
			navigator.accelerometer.clearWatch(this.state.watchID);
		}
		if (this.state.watchID === undefined) {
			localStorage.setItem('watchID', null);
		}

		let watchID = navigator.accelerometer.watchAcceleration((acceleration) => {

			this.setState({
				watchID: watchID
			}, () => {
				this.setState({
					watchID: watchID
				});
				if (this.state.watchID !== null && !this.state.watchIDSaved) {
					localStorage.setItem('watchID', this.state.watchID);
					this.setState({
						watchIDSaved: true
					});
				}
			});

			let sMV = this.state.smv,
				accX = null,
				accY = null,
				accZ = null,
				signalMagnitudeVector = null;
			accX = Math.pow(Math.abs(acceleration.x), 2);
			accY = Math.pow(Math.abs(acceleration.y), 2);
			accZ = Math.pow(Math.abs(acceleration.z), 2);
			signalMagnitudeVector = Math.sqrt(accX + accY + accZ);
			if (signalMagnitudeVector >= sMV && this.state.fallDetector === "1" && localStorage.getItem('watchID') !== undefined) {
				localStorage.setItem('fallDetected', 'true');
				navigator.accelerometer.clearWatch(this.state.watchID);
				this.setState({
					fallPopup: true,
				}, () => {

					window.cordova.plugins.notification.local.setDefaults({
						vibrate: true
					});
					window.cordova.plugins.notification.local.schedule({
						title: 'Wykryto upadek!',
						text: 'Po upływie 30 sekund zostanie wysłany SMS.',
						foreground: true
					});
				});
			}
		}, () => {
			alert('onError!');
		}, {frequency: 500});
	};

	onComplete = () => {
		// this.setState({
		// 	smsSent: true
		// });
		if (this.state.phone && this.state.phone2) {

			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone + '&message=' + this.state.name + ' zglosil w aplikacji Help! zdarzenie "Upadek". Jego lokalizacja to: https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({});
			}, () => {
			});

			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone2 + '&message=' + this.state.name + ' zglosil w aplikacji Help! zdarzenie "Upadek". Jego lokalizacja to: https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({
					smsSent: true
				});
			}, () => {
			});

		} else if (this.state.phone && !this.state.phone2) {

			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone + '&message=' + this.state.name + ' zglosil w aplikacji Help! zdarzenie "Upadek". Jego lokalizacja to: https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({
					smsSent: true
				});
			}, () => {
			});

		} else if (!this.state.phone && this.state.phone2) {
			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone2 + '&message=' + this.state.name + ' zglosil w aplikacji Help! zdarzenie "Upadek". Jego lokalizacja to: https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({
					smsSent: true
				});
			}, () => {
			});
		}
	};

	clearCounting = () => {
		localStorage.setItem('fallDetected', 'false');
		this.setState({
			fallPopup: false,
			changeSmv: true
		});
		navigator.vibrate(0)
	};

	reloadWatcher = () => {
		localStorage.setItem('fallDetected', 'false');
		this.setState({
			fallPopup: false,
			smsSent: false,
			changeSmv: false
		}, () => {
			this.fallWatch();
		});
	};

	playSound = () => {
		if (this.state.stopped === false) {
			if (this.state.sound === 'many') {
				return <Sound
					url="../www/static/media/beep-01a.238acd50.mp3"
					loop={true}
					playStatus={Sound.status.PLAYING}
				/>
			} else {
				return <Sound
					url="../www/static/media/beep-01a.238acd50.mp3"
					playStatus={Sound.status.PLAYING}
				/>
			}
		} else if (this.state.stopped === true) {
			return <Sound
				url="../www/static/media/beep-01a.238acd50.mp3"
				playStatus={Sound.status.STOPPED}
			/>
		}
	};

	clearSound = () => {
		this.setState({
			stopped: true
		});
		navigator.vibrate(0)
	};

	buttonMute = () => {
		const {t} = this.props;

		if (this.state.sound === 'many') {
			return <button className="btn default-button full-width-button margin-top-10"
			               onClick={this.clearSound}>{t('mute')}
			</button>
		}
	};

	fallPopupClock = () => {
		const {t} = this.props;

		if (this.state.fallPopup && !this.state.smsSent && !this.state.changeSmv) {
			navigator.vibrate([500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500]);
			return <div>
				<div className="overlay-falldetector">
					<div id="fallPopup">
						<div className="popup-title"><p>{t("fallDetected")}</p></div>
						<div className="popup">
							<h6 className="modal-title"
							    id="errorModal">{t("fallDetectedInfo")}<br/> {t("fallDetectedInfo2")}
							</h6>
							<div className="countdown-clock">
								<ReactCountdownClock seconds={30}
								                     color="#ffd600"
								                     alpha={0.9}
								                     size={150}
								                     showMilliseconds={false}
								                     onComplete={this.onComplete}
								/>
								{this.playSound()}
							</div>
							<button className="btn default-button full-width-button margin-top-10"
							        onClick={this.clearCounting}>{t("cancel")}</button>
							{this.buttonMute()}
						</div>
					</div>
				</div>
			</div>
		}
	};

	smsSentSuccess = () => {
		const {t} = this.props;

		if (this.state.fallPopup && this.state.smsSent && !this.state.changeSmv) {
			return <div id="fallPopup">
				<div className="popup-title"><p>{t("smsSent")}</p></div>
				<div className="popup">
					<h6 className="modal-title" id="errorModal">{t("waitForHelp")} </h6>
					<button className="btn default-button full-width-button"
					        onClick={this.reloadWatcher}>{t("ok")}</button>
				</div>
			</div>
		}
	};

	changeSmvSensitivity = () => {
		const {t} = this.props;

		if (!this.state.fallPopup && !this.state.smsSent && this.state.changeSmv) {
			return <div className="overlay-falldetector">
				<div id="fallPopup">
					<div className="popup-title"><p>{t("canceled")}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal"> {t("sendSmsCanceled")}
							<br/> {t("changeSensitivityPopup")}</h6>
						{this.reactSpeedometer()}
						<button className="btn default-button full-width-button"
						        onClick={this.reloadWatcher}>{t("ok")}</button>
					</div>
				</div>
			</div>
		}
	};

	set25 = () => {
		this.setState({
			smv: 24.6
		});
		localStorage.setItem('fallDetectorSmv', '24.6')

	};
	set23 = () => {
		this.setState({
			smv: 23.3
		});
		localStorage.setItem('fallDetectorSmv', '23.3')

	};
	set22 = () => {
		this.setState({
			smv: 22
		});
		localStorage.setItem('fallDetectorSmv', '22')

	};
	set21 = () => {
		this.setState({
			smv: 21.0
		});
		localStorage.setItem('fallDetectorSmv', '21')

	};
	set20 = () => {
		this.setState({
			smv: 19.7
		});
		localStorage.setItem('fallDetectorSmv', '19.7')

	};
	set19 = () => {
		this.setState({
			smv: 18.6
		});
		localStorage.setItem('fallDetectorSmv', '18.6')
	};
	set17 = () => {
		this.setState({
			smv: 17.5
		});
		localStorage.setItem('fallDetectorSmv', '17.5')

	};
	getFallDetectorSmv = () => {
		localStorage.setItem('fallDetectorSmv', this.state.smv);

	};

	setDefaultButton = () => {
		const {t} = this.props;

		if (localStorage.getItem('fallDetectorSmv') !== '21') {
			return <button className="btn default-button margin-bottom-10 full-width-button"
			               onClick={this.getDefaultSmv}> {t('restoreDefaults')}
			</button>
		}
	};

	reactSpeedometer = () => {
		return <div className='d-flex justify-content-center '>

			<div className='main-speedometer'>
				<ReactSpeedometer

					minValue={17}
					maxValue={25}
					value={this.state.smv}
					needleColor="steelblue"
					textColor="white"
					width={260}
					height={160}
					startColor='transparent'
					endColor='transparent'
					segments={7}
				/>
			</div>
			<div className='dots-speedometer'
			>
				<img src={require('../images/dots.svg')} alt=''/>
			</div>
			<div className='value-setting'>

				<svg className="speedometer" width="260" height="160">
					<g className="arc-transparent" transform="translate(130,130)">
						<path className="speedo-segment" fill="transparent" onClick={this.set17}
						      d="M-110,-1.3471114790620885e-14A110,110,0,0,1,-99.10657546926609,-47.72721130293141L-45.048443395120955,-21.69418695587791A50,50,0,0,0,-50,-6.123233995736766e-15Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set19}
						      d="M-99.10657546926609,-47.72721130293141A110,110,0,0,1,-68.58387820446069,-86.00146307148329L-31.174490092936676,-39.09157412340149A50,50,0,0,0,-45.048443395120955,-21.69418695587791Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set20}
						      d="M-68.58387820446069,-86.00146307148329A110,110,0,0,1,-24.477302735194577,-107.2420703400006L-11.126046697815717,-48.74639560909118A50,50,0,0,0,-31.174490092936676,-39.09157412340149Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set21}
						      d="M-24.477302735194577,-107.2420703400006A110,110,0,0,1,24.477302735194566,-107.2420703400006L11.126046697815712,-48.74639560909118A50,50,0,0,0,-11.126046697815717,-48.74639560909118Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set22}
						      d="M24.477302735194566,-107.2420703400006A110,110,0,0,1,68.58387820446067,-86.00146307148329L31.17449009293667,-39.09157412340149A50,50,0,0,0,11.126046697815712,-48.74639560909118Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set23}
						      d="M68.58387820446067,-86.00146307148329A110,110,0,0,1,99.10657546926609,-47.72721130293141L45.048443395120955,-21.694186955877914A50,50,0,0,0,31.17449009293667,-39.09157412340149Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set25}
						      d="M99.10657546926609,-47.72721130293141A110,110,0,0,1,110,0L50,0A50,50,0,0,0,45.048443395120955,-21.694186955877914Z">
						</path>
					</g>
				</svg>

			</div>
		</div>
	};

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	setSmv = () => {
		this.setState({
			smvSetting: true
		})
	};

	getDefaultSmv = () => {
		localStorage.setItem('fallDetectorSmv', '21');
		this.setState({
			smv: 21.0
		});
	};

	smvOption = () => {
		const {t} = this.props;

		if (this.state.smvSetting) {
			return <div className="page-div border-radius-all sensitivity">

				<div className="fall-detector">
					<p>{t('chooseSensitivity')}</p>
					<select className="margin-top-10" value={this.state.smv}
					        onChange={this.valueChange}
					        name="smv">
						<option value="17.5" onClick={this.getFallDetectorSmv}>1.8g</option>
						<option value="18.6" onClick={this.getFallDetectorSmv}>1.9g</option>
						<option value="19.7" onClick={this.getFallDetectorSmv}>2.0g</option>
						<option value="21" onClick={this.getFallDetectorSmv}>2.1g</option>
						<option value="22" onClick={this.getFallDetectorSmv}>2.2g</option>
						<option value="23.3" onClick={this.getFallDetectorSmv}>2.3g</option>
						<option value="24.6" onClick={this.getFallDetectorSmv}>2.4g</option>
					</select>
				</div>
				<div className="clearfix margin-bottom-10"/>
			</div>
		}
	};

	render() {
		return <div>
			{this.fallPopupClock()}
			{this.smsSentSuccess()}
			{this.changeSmvSensitivity()}
		</div>
	}
}

export default translate("translations")(FallDetector);
