import React, {Component} from 'react';
import {translate} from "react-i18next";

class TutorialBefore extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			step: 1,
			seenTutorial: false
		};
	}

	languageButtons = () => {
		const {i18n} = this.props;
		const changeLanguage = lng => {
			i18n.changeLanguage(lng);
		};
		return <div className='language-buttons-tutorial'>
			<img alt="" src={require('../images/pl.svg')} onClick={() => changeLanguage("pl")}/>
			<img alt="" src={require('../images/en.svg')} onClick={() => changeLanguage("en")}/>
			<img alt="" src={require('../images/fr.svg')} onClick={() => changeLanguage("fr")}/>
			<img alt="" src={require('../images/ru.svg')} onClick={() => changeLanguage("ru")}/>
		</div>
	};

	nextStep = () => {
		let currentStep = this.state.step,
			nextStep = currentStep + 1;

		this.setState({
			step: nextStep
		});
	};

	closeTutorial = () => {
		this.props.callback();
	};

	setStep1 = () => {
		this.setState({
			step: 1
		})
	};
	setStep2 = () => {
		this.setState({
			step: 2
		})
	};
	setStep3 = () => {
		this.setState({
			step: 3
		})
	};
	setStep4 = () => {
		this.setState({
			step: 4
		})
	};
	setStep5 = () => {
		this.setState({
			step: 5
		})
	};

	nextStepButton = () => {
		const {t} = this.props;

		return <button className="btn default-button full-width-button" onClick={this.nextStep}>
			{t('next')}
		</button>
	};

	dots = () => {
		if (!this.isCordova) {
			return <div className='dots'>
				<input id='step1' type='radio' className='nav-dot'  readOnly checked={this.state.step === 1}/>
				<label htmlFor='step1' onClick={this.setStep1}></label>
				<input type='radio' className='nav-dot' onClick={this.setStep2} readOnly checked={this.state.step === 2}/>
				<label htmlFor='step2' onClick={this.setStep2}></label>
				<input type='radio' className='nav-dot' onClick={this.setStep3} readOnly checked={this.state.step === 3}/>
				<label htmlFor='step3' onClick={this.setStep3}></label>
				<input type='radio' className='nav-dot' onClick={this.setStep4} readOnly checked={this.state.step === 4}/>
				<label htmlFor='step4' onClick={this.setStep4}></label>
			</div>

		} else if (this.isCordova) {
			return <div className='dots'>
				<input type='radio' className='nav-dot' onClick={this.setStep1} readOnly checked={this.state.step === 1}/>
				<label htmlFor='step1' onClick={this.setStep1}></label>
				<input type='radio' className='nav-dot' onClick={this.setStep2} readOnly checked={this.state.step === 2}/>
				<label htmlFor='step2' onClick={this.setStep2}></label>
				<input type='radio' className='nav-dot' onClick={this.setStep3} readOnly checked={this.state.step === 3}/>
				<label htmlFor='step3' onClick={this.setStep3}></label>
				<input type='radio' className='nav-dot' onClick={this.setStep4} readOnly checked={this.state.step === 4}/>
				<label htmlFor='step4' onClick={this.setStep4}></label>
				<input type='radio' className='nav-dot' onClick={this.setStep5} readOnly checked={this.state.step === 5}/>
				<label htmlFor='step5' onClick={this.setStep5}></label>
			</div>
		}
	};

	navigation = () => {
		return <div className='tutorial-button'>
			{this.nextStepButton()}
			{this.dots()}
		</div>
	};

	storage = () => {
		const {t} = this.props;

		return <div className='tutorial-box'>
			<div className='tutorial-icon'>
				{this.languageButtons()}
				<i className='fa fa-folder-open'/>
			</div>
			<div className='tutorial-info'>
				<p>{t('tutorialAccount')} </p>
			</div>
			{this.navigation()}

		</div>
	};

	guestMode = () => {
		const {t} = this.props;

		return <div className='tutorial-box'>
			<div className='tutorial-icon'>
				{this.languageButtons()}
				<i className='fa fa-sign-in-alt'/>
			</div>
			<div className='tutorial-info'>
				<p> {t('tutorialPass')}</p>

			</div>
			<div className='tutorial-button'>
				<button className="btn default-button full-width-button" onClick={this.closeTutorial}>
					{t('exit')}
				</button>
				{this.dots()}
			</div>

		</div>
	};

	render() {
		const {t} = this.props;


		if (!this.isCordova) {
			if (this.state.step === 2) {
				return <div className='tutorial-box'>
					<div className='tutorial-icon'>
						{this.languageButtons()}
						<a href="https://helphelptrip.com/android.apk"><i className='fa fa-download'/></a>
					</div>
					<div className='tutorial-info'>
						<p>{t('tutorialDownload')}</p>
					</div>
					{this.navigation()}
				</div>
			}
			if (this.state.step === 3) {
				return <>{this.storage()}</>
			}
			if (this.state.step === 4) {
				return <>{this.guestMode()}</>
			}
		}
		if (this.isCordova) {
			if (this.state.step === 2) {
				return <div className='tutorial-box'>
					<div className='tutorial-icon'>
						{this.languageButtons()}
						<i className='fa fa-user-shield'/>
					</div>
					<div className='tutorial-info'>
						<p> {t('fallDetectorModal')} {t('fallDetectorModal2')}</p>
					</div>
					{this.navigation()}

				</div>
			}
			if (this.state.step === 3) {
				return <div className='tutorial-box'>
					<div className='tutorial-icon'>
						{this.languageButtons()}
						<i className='fa fa-user-shield'/>
					</div>
					<div className='tutorial-info'>
						<p>{t('overloadDetectionPhone')} </p>
					</div>
					{this.navigation()}
				</div>
			}

			if (this.state.step === 4) {
				return <>{this.storage()}</>
			}
			if (this.state.step === 5) {
				return <>{this.guestMode()}</>
			}
		}

		return <div className='tutorial-box'>
			<div className='tutorial-icon'>
				{this.languageButtons()}
				<i className='fa fa-map-marked-alt'/>
			</div>
			<div className='tutorial-info'>
				<p>{t('internetModal')}{t('internetModal2')}{t('internetModal3')}{t('internetModal4')}{t('internetModal5')}</p>

			</div>
			{this.navigation()}

		</div>
	}
}

export default translate("translations")(TutorialBefore);
