import React, {Component} from 'react';
import SpeechRecognitionMobile from "./SpeechRecognitionMobile";
import SpeechRecognitionWeb from "./SpeechRecognitionWeb";
import SpeechRecognitionWebNotChrome from "./SpeechRecognitionWebNotChrome";
import {translate} from "react-i18next";

class SpeechTranslator extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
	}
	renderProperComponent() {
		if (this.isCordova) {
			return <SpeechRecognitionMobile/>
		} else if (navigator.userAgent.indexOf("Chrome") !== -1) {
			return <SpeechRecognitionWeb/>
		} else if (navigator.userAgent.indexOf("Chrome") === -1) {
			return <SpeechRecognitionWebNotChrome/>
		}
	};
	render() {
		const {t} = this.props;

		return <div className="no-pointer-events speech-translator">
			<div className="content-title border-radius-top"><p>{t('speechRecognition')}</p></div>
			<div className="page-div border-radius-bottom">
				{this.renderProperComponent()}
			</div>
		</div>
	}
}
export default translate("translations")(SpeechTranslator);
