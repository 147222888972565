import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {translate} from "react-i18next";

class Intro extends Component {
	render() {
		const {t} = this.props;

		return <div className="page-div border-radius-all"><p><strong>{t('yourChoice')}</strong></p>
			<p>{this.props.header}</p>
			<hr/>
			<p>{t('yourChoiceInfo')}</p>
			<Link className="btn default-button margin-top-10" to={{
				pathname: '/event/' + this.props.eventId + '/procedure',
				state: {procedure: this.props.procedure}
			}}>{t('seeProcedure')}</Link>
		</div>
	}
}
export default translate("translations")(Intro);
