import axios from 'axios/index';
import React, {Component} from 'react';
import moment from 'moment';
import {translate} from "react-i18next";

class CurrencyCalculator extends Component {

    constructor(props) {
        super(props);
        this.isCordova = navigator.userAgent.match(/cordova/i);
        this.state = {
            midEuro: null,
            mid: null,
            secondMidEuro: null,
            firstCurrencyName: 'eur',
            secondCurrencyName: 'pln',
            firstCurrencyValue: 1,
            newCurrency: '',
            result: null,
            firstMid: null,
            secondMid: 1,
            date: '',
            today: moment(new Date()).format('YYYY-MM-DD'),
            todayRate: '',
            internetConnection: false

        };
        this.showCurrency = this.showCurrency.bind(this);
        this.valueChange = this.valueChange.bind(this);
        this.setFirstTable = this.setFirstTable.bind(this);
        this.setSecondTable = this.setSecondTable.bind(this);
        this.setFirstPlnValue = this.setFirstPlnValue.bind(this);
        this.setSecondPlnValue = this.setSecondPlnValue.bind(this);
        this.getRate = this.getRate.bind(this);
        this.swapCurrencies = this.swapCurrencies.bind(this);
    }

    showCurrency() {

        axios.get('https://api.nbp.pl/api/exchangerates/rates/a/eur')
            .then(res => res.data)
            .then((result) => {

                    this.setState({
                        internetConnection: true,
                        firstDate: result.rates[0].effectiveDate,
                        secondDate: result.rates[0].effectiveDate,
                        firstMid: result.rates[0].mid,
                        secondMidShow: Number((1 / result.rates[0].mid).toFixed(4))
                    }, () => {

                        if (this.state.today === this.state.firstDate) {
                            this.setState({
                                todayRate: true
                            });
                        } else {
                            this.setState({
                                date: moment(this.state.firstDate).format('DD.MM.YYYY'),
                                todayRate: false
                            });
                        }

                        let res = null,
                            res2 = null,
                            refreshCurrency = null;
                        res = this.state.firstMid / this.state.secondMid;
                        res2 = this.state.secondMid / this.state.firstMid;
                        this.setState({
                            result: res,
                            result2: Number(res2.toFixed(4))
                        });
                        refreshCurrency = Number((res * this.state.firstCurrencyValue).toFixed(2));

                        this.setState({
                            newCurrency: refreshCurrency
                        });
                    });
                }
            );
    }

    componentDidMount() {
        document.addEventListener("online", this.refreshAfterConnention, false);
        document.addEventListener("offline", this.noInternetConnention, false);
        if (this.isCordova) {
            if (navigator.connection.type !== 'none') {
                this.setState({
                    internetConnection: true
                })
            } else {
                this.setState({
                    internetConnection: false
                })
            }
        }
        this.showCurrency();
    }

    valueChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        }, () => {
            this.setState({
                [name]: value
            });
        });
    }

    noInternetConnention = () => {
        this.setState({
            internetConnection: false
        })
    };

    refreshAfterConnention = () => {
        this.setState({
            internetConnection: true
        })
    };

    getRate() {
        this.setFirstTable();
        this.setSecondTable();
        this.todayRate()
    }

    setFirstTable() {
        if (this.state.firstCurrencyName === 'egp' || this.state.firstCurrencyName === 'tnd' || this.state.firstCurrencyName === 'amd' || this.state.firstCurrencyName === 'gel' || this.state.firstCurrencyName === 'mdl' || this.state.firstCurrencyName === 'all' || this.state.firstCurrencyName === 'rsd' || this.state.firstCurrencyName === 'mkd' || this.state.firstCurrencyName === 'azn' || this.state.firstCurrencyName === 'byn' || this.state.firstCurrencyName === 'bam') {
            this.getFirstMidB();
        } else if (this.state.firstCurrencyName === 'pln') {
            this.setFirstPlnValue();
        } else {
            this.getFirstMidA();
        }
    }

    getFirstMidA() {
        axios.get('https://api.nbp.pl/api/exchangerates/rates/a/' + this.state.firstCurrencyName)
            .then(res => res.data)
            .then((result) => {
                this.setState({
                    firstMid: result.rates[0].mid,
                    firstDate: result.rates[0].effectiveDate
                }, () => {
                    this.getNewValue();
                });
            });
    }

    setFirstPlnValue() {
        this.setState({
            firstMid: 1
        }, () => {
            this.getNewValue();
        });
    }

    getFirstMidB() {
        axios.get('https://api.nbp.pl/api/exchangerates/rates/b/' + this.state.firstCurrencyName)
            .then(res => res.data)
            .then((result) => {
                this.setState({
                    firstMid: result.rates[0].mid,
                    firstDate: result.rates[0].effectiveDate
                }, () => {
                    this.getNewValue();
                });
            });
    }

    getNewValue() {
        let res = null,
            res2 = null;
        res = this.state.firstMid / this.state.secondMid;
        res2 = this.state.secondMid / this.state.firstMid;
        this.setState({
            result: Number(res.toFixed(4)),
            result2: Number(res2.toFixed(4))
        }, () => {
            let refreshCurrency = null;
            refreshCurrency = Number((this.state.result * this.state.firstCurrencyValue).toFixed(2));
            this.setState({
                newCurrency: refreshCurrency
            });
            if (this.state.today === this.state.firstDate) {
                this.setState({
                    todayRate: true
                });
            } else {
                this.setState({
                    date: moment(this.state.firstDate).format('DD.MM.YYYY'),
                    todayRate: false
                });
            }
            if (this.state.today === this.state.secondDate) {
                this.setState({
                    todayRate: true
                });
            } else {
                this.setState({
                    date: moment(this.state.secondDate).format('DD.MM.YYYY'),
                    todayRate: false
                });
            }
        });
    }

    setSecondTable() {
        if (this.state.secondCurrencyName === 'egp' || this.state.secondCurrencyName === 'tnd' || this.state.secondCurrencyName === 'amd' || this.state.secondCurrencyName === 'gel' || this.state.secondCurrencyName === 'mdl' || this.state.secondCurrencyName === 'all' || this.state.secondCurrencyName === 'rsd' || this.state.secondCurrencyName === 'mkd' || this.state.secondCurrencyName === 'azn' || this.state.secondCurrencyName === 'byn' || this.state.secondCurrencyName === 'bam') {
            this.getSecondMidB();
        } else if (this.state.secondCurrencyName === 'pln') {
            this.setSecondPlnValue();
        } else {
            this.getSecondMidA();
        }
    }

    getSecondMidA() {
        axios.get('https://api.nbp.pl/api/exchangerates/rates/a/' + this.state.secondCurrencyName)
            .then(res => res.data)
            .then((result) => {
                this.setState({
                    secondMid: result.rates[0].mid,
                    secondDate: result.rates[0].effectiveDate
                }, () => {
                    this.getNewValue();
                });
            });
    }

    setSecondPlnValue() {
        this.setState({
            secondMid: 1
        }, () => {
            this.getNewValue();
        });
    }

    getSecondMidB() {
        axios.get('https://api.nbp.pl/api/exchangerates/rates/b/' + this.state.secondCurrencyName)
            .then(res => res.data)
            .then((result) => {
                this.setState({
                    secondMid: result.rates[0].mid,
                    secondDate: result.rates[0].effectiveDate
                }, () => {
                    this.getNewValue();
                });
            });
    }

    swapCurrencies() {
        let res = null,
            res2 = null,
            refreshCurrency = null;
        res = this.state.secondMid / this.state.firstMid;
        res2 = this.state.firstMid / this.state.secondMid;
        refreshCurrency = Number((res2 * this.state.firstCurrencyValue).toFixed(2));
        this.setState({
            firstMid: this.state.secondMid,
            secondMid: this.state.firstMid,
            firstCurrencyName: this.state.secondCurrencyName,
            secondCurrencyName: this.state.firstCurrencyName,
            result: Number(res.toFixed(4)),
            result2: Number(res2.toFixed(4)),
            newCurrency: refreshCurrency
        }, () => {
            res = this.state.secondMid / this.state.firstMid;
            res2 = this.state.firstMid / this.state.secondMid;
            refreshCurrency = Number((res2 * this.state.firstCurrencyValue).toFixed(2));
            this.setState({
                result: Number(res2.toFixed(4)),
                result2: Number(res.toFixed(4)),
                newCurrency: refreshCurrency
            });
        });
    }

    clearResult = () => {
        this.setState({
            newCurrency: '',
            result: '',
            result2: ''
        });
    };

    displayButton = () => {
	    const {t} = this.props;

	    if (this.state.firstMid !== null) {
            return <div className="currency-buttons">
                <button className="btn default-button currency-rate-button" type="button"
                        onClick={this.getRate}>
                    {t("calculate")}
                </button>
                <button className="btn default-button currency-switch-button icon-rotate" type="button"
                        onClick={this.swapCurrencies}>
                    <i className="fa fa-exchange-alt ">

                    </i>
                </button>
            </div>
        } else {
            return <div className="currency-buttons">
                <button className="btn default-button currency-rate-button button-disabled" type="button">
                    {t("calculate")}
                </button>
                <button className="btn default-button currency-switch-button button-disabled icon-rotate" type="button">
                    <i className="fa fa-exchange-alt ">

                    </i>
                </button>
            </div>
        }
    };

    todayRate() {
	    const {t} = this.props;

	    if (this.state.result !== '' && this.state.firstMid !== null) {
            let rateDateFirst = null,
                rateDateSecond = null;
            rateDateFirst = moment(this.state.firstDate).format('DD.MM.YYYY');
            rateDateSecond = moment(this.state.secondDate).format('DD.MM.YYYY');
            if (this.state.today === this.state.firstDate && this.state.today !== this.state.secondDate) {
                return <div><p> {t("dateRate")} {rateDateSecond} r.</p>
                    {this.currencyRates()}
                </div>
            } else if (this.state.today !== this.state.firstDate && this.state.today === this.state.secondDate) {
                return <div><p>{t("dateRate")} {rateDateFirst} r.</p>
                    {this.currencyRates()}
                </div>
            } else {
                return <div><p>{t("todayRate")}</p>
                    {this.currencyRates()}
                </div>
            }
        } else {
            return <div className="height-75">
                <p className="height-20">
                </p>
                <hr/>
            </div>
        }
    }

    currencyRates() {
        return <div>
            <hr/>
            <div className="currency-rates">
                <div className="col-12">
                    <p>
                        <strong>1 {this.state.firstCurrencyName} = {this.state.result} {this.state.secondCurrencyName}</strong>
                    </p>
                </div>
                <div className="col-12">
                    <p>
                        <strong>1 {this.state.secondCurrencyName} = {this.state.result2} {this.state.firstCurrencyName}</strong>
                    </p>
                </div>

            </div>
        </div>
    }

    render() {
	    const {t} = this.props;

	    if (this.state.internetConnection) {
            return (
                <div className="no-pointer-events currency-calculator">
                    <div className="content-title border-radius-top"><p>{t("currencyCalculator")}</p></div>
                    <div className="page-div border-radius-bottom">
                        <div className="currency-row">
                            <div className="currency-input-div">
                                <input name="firstCurrencyValue" value={this.state.firstCurrencyValue}
                                       onChange={this.valueChange} className="currency-input" type="text"
                                       placeholder="10"/>
                            </div>
                            <div className="currency-select">
                                <select name='firstCurrencyName' className="currency-names" onChange={this.valueChange}
                                        onClick={this.clearResult}
                                        value={this.state.firstCurrencyName}>
                                    {/*tabela A*/}
                                    <option value="eur">EUR</option>
                                    <option value="pln">PLN</option>
                                    <option value="usd">USD</option>
                                    <option value="chf">CHF</option>
                                    <option value="gbp">GBP</option>
                                    {/*forint węgierski*/}
                                    <option value="huf">HUF</option>
                                    {/*hrywna ukraińska*/}
                                    <option value="uah">UAH</option>
                                    {/*korony*/}
                                    <option value="czk">CZK</option>
                                    <option value="dkk">DKK</option>
                                    <option value="isk">ISK</option>
                                    <option value="nok">NOK</option>
                                    <option value="sek">SEK</option>
                                    {/*kuna chorwacka*/}
                                    <option value="hrk">HRK</option>
                                    {/*lej rumuński*/}
                                    <option value="ron">RON</option>
                                    {/*lew bułgarski*/}
                                    <option value="bgn">BGN</option>
                                    {/*lira turecka*/}
                                    <option value="try">TRY</option>
                                    {/*rubel rosyjski*/}
                                    <option value="rub">RUB</option>
                                    {/*jen*/}
                                    <option value="jpy">JPY</option>
                                    {/*yuan*/}
                                    <option value="cny">CNY</option>
                                    {/*tabela B*/}
                                    {/*funt egipski*/}
                                    <option value="egp">EGP</option>
                                    {/*dinar tunezyjski*/}
                                    <option value="tnd">TND</option>
                                    {/*dram armeński*/}
                                    <option value="amd">AMD</option>
                                    {/*lari gruziński*/}
                                    <option value="gel">GEL</option>
                                    {/*lej mołdawski*/}
                                    <option value="mdl">MDL</option>
                                    {/*lek albański*/}
                                    <option value="all">ALL</option>
                                    {/*dinar serbski*/}
                                    <option value="rsd">RSD</option>
                                    {/*denar macedoński*/}
                                    <option value="mkd">MKD</option>
                                    {/*manat azerbejdżański*/}
                                    <option value="azn">AZN</option>
                                    {/*rubel białoruski*/}
                                    <option value="byn">BYN</option>
                                    {/*wymienialna marka (Bośnia i Hercegowina)*/}
                                    <option value="bam">BAM</option>
                                </select>
                            </div>
                        </div>
                        {this.displayButton()}

                        <div className="currency-row">
                            <div className="currency-input-div readonly-input">
                                <input className="currency-input" type="text"
                                       readOnly value={this.state.newCurrency}/>
                            </div>
                            <div className="currency-select">
                                <select name='secondCurrencyName' className="currency-names" onChange={this.valueChange}
                                        onClick={this.clearResult}
                                        value={this.state.secondCurrencyName}>
                                    {/*tabela A*/}
                                    <option value="pln">PLN</option>
                                    <option value="eur">EUR</option>
                                    <option value="usd">USD</option>
                                    <option value="chf">CHF</option>
                                    <option value="gbp">GBP</option>
                                    {/*forint węgierski*/}
                                    <option value="huf">HUF</option>
                                    {/*hrywna ukraińska*/}
                                    <option value="uah">UAH</option>
                                    {/*korony*/}
                                    <option value="czk">CZK</option>
                                    <option value="dkk">DKK</option>
                                    <option value="isk">ISK</option>
                                    <option value="nok">NOK</option>
                                    <option value="sek">SEK</option>
                                    {/*kuna chorwacka*/}
                                    <option value="hrk">HRK</option>
                                    {/*lej rumuński*/}
                                    <option value="ron">RON</option>
                                    {/*lew bułgarski*/}
                                    <option value="bgn">BGN</option>
                                    {/*lira turecka*/}
                                    <option value="try">TRY</option>
                                    {/*rubel rosyjski*/}
                                    <option value="rub">RUB</option>
                                    {/*jen*/}
                                    <option value="jpy">JPY</option>
                                    {/*yuan*/}
                                    <option value="cny">CNY</option>
                                    {/*tabela B*/}
                                    {/*funt egipski*/}
                                    <option value="egp">EGP</option>
                                    {/*dinar tunezyjski*/}
                                    <option value="tnd">TND</option>
                                    {/*dram armeński*/}
                                    <option value="amd">AMD</option>
                                    {/*lari gruziński*/}
                                    <option value="gel">GEL</option>
                                    {/*lej mołdawski*/}
                                    <option value="mdl">MDL</option>
                                    {/*lek albański*/}
                                    <option value="all">ALL</option>
                                    {/*dinar serbski*/}
                                    <option value="rsd">RSD</option>
                                    {/*denar macedoński*/}
                                    <option value="mkd">MKD</option>
                                    {/*manat azerbejdżański*/}
                                    <option value="azn">AZN</option>
                                    {/*rubel białoruski*/}
                                    <option value="byn">BYN</option>
                                    {/*wymienialna marka (Bośnia i Hercegowina)*/}
                                    <option value="bam">BAM</option>
                                </select>
                            </div>
                        </div>
                        {this.todayRate()}
                    </div>
                </div>
            );
        } else {
            return <div className="no-pointer-events currency-calculator">
                <div className="content-title border-radius-top"><p>{t("currencyCalculator")}</p></div>
                <div className="page-div border-radius-bottom">
                    <div>
                        <div className="bg-content"/>
                        <p>{t("offlineApk")}</p>
                    </div>
                </div>
            </div>
        }
    }
}
export default translate("translations")(CurrencyCalculator);
