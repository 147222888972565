import AddFileOrFolder from '../components/AddFileOrFolder';
import FileOrFolder from '../components/FileOrFolder'
import EventHeader from './../components/EventHeader'
import React, {Component} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import {translate} from "react-i18next";

const addr = 'https://api.helphelptrip.com/api/1.0';

class Storage extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			showFile: false,
			rootDir: localStorage.getItem('rootDir'),
			folderName: '',
		};
		let handleToUpdate = this.handleToUpdate.bind(this);
	}

	componentDidMount() {
		this.getStorage()
	}

	getStorage = () => {
		axios.get(addr + '/dirs/' + this.state.rootDir + '/items', {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			}
		})
			.then((response) => {
				this.setState({
					storage: response.data,
					storageList: true,
				});
			})
			.catch((error) => {
				if (error.response.status === 403 && error.response.data.password_change) {
					this.setState({
						forbidden: true,
						forbiddenError: error.response.data.password_change
					});
				}
			});
	};

	handleToUpdate = (newStorage) => {
		this.setState({
			storage: {files: [], directories: []},
			newStorage: true
		});
		this.setState({
			storage: newStorage,
			newStorage: true,
			storageList: true
		});
	};

	render() {
		const {t} = this.props;

		if (this.state.storageList) {
			return <div>
				<EventHeader header={t('storage')} backTo="/menu"/>
				<div className="account-bg">
				</div>
				<div className="page-content col-12">
					{/*<div className="page-content storage col-12">*/}
					<div className="page-div storage-container border-radius-all">
						<p className="path-style border-radius-top">{t('storage')}</p>
						<div className="storage-go-back">
						</div>
						<div className="storage-wrapper">
							{this.state.storage.directories.map((file, index) => {
								//folder*/}
								return <FileOrFolder id={this.state.storage.directories[index].id}
								                     key={index}
								                     isFile={false}
								                     name={file.name}
								                     nameFrom="Storage"
								                     storage={this.state.storage.directories[index]}
								                     handleToUpdate={this.handleToUpdate}/>
							})}

							{this.state.storage.files.map((file, index) => {
								let fileFullName = '',
									fileNamePart = '',
									fileType = '';

								fileFullName = file.name;
								fileNamePart = fileFullName.split('.');
								fileType = fileNamePart[1];
								return <FileOrFolder key={index}
								                     id={this.state.storage.files[index].id}
								                     name={file.name}
								                     isFile={true}
								                     fileType={fileType}
								                     files={this.state.storage.files[index]}
								                     link={file.link}
								                     handleToUpdate={this.handleToUpdate}/>
							})}


						</div>
						<AddFileOrFolder handleToUpdate={this.handleToUpdate} props={this.props}/>
					</div>
				</div>
			</div>
		} else if (this.state.forbidden) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>{t('error')}</p></div>
					<div className="popup">
						<p>{this.state.forbiddenError}</p>
						<Link className="btn default-button" to={'/konto'}>{t('passwordChange')}</Link>
					</div>
				</div>
			</div>
		} else {
			return <div className="bg">
				<div className="centering">
					<p className="center text-center">
						<img src={require('../images/logo.png')} alt="" className="logo-in-spinner"/>
						<i className="fas fa-spinner"/> {t("loading")}
					</p>
				</div>
			</div>
		}
	}
}

export default translate("translations")(Storage);
