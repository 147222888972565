import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {translate} from "react-i18next";

class Content extends Component {
	constructor(props) {
		super(props);
		if (props.location.state !== undefined) {
			this.state = {
				title: props.location.state.title,
				content: props.location.state.content,
				eventId: props.location.state.eventId,
				noData: false
			};
		} else {
			this.state = {
				noData: true
			};
		}
	}

	render() {
		if (this.state.noData) {
			return <Redirect to="/menu"/>
		} else {
			return <div className="bg">
				<div className="header row no-gutters col-12">
					<Link className="back d-flex" to={'/event/' + this.state.eventId}>
						<div className="arrow-header">
							<i className="fas fa-chevron-left"/>
						</div>
						<div className="title-header">
							<h5>{this.state.title}</h5>
						</div>
					</Link>
				</div>
				<div className="page-content col-12 position-absolute">
					<div className="page-div border-radius-all">
						<div dangerouslySetInnerHTML={{__html: this.state.content}}/>
					</div>
				</div>
			</div>
		}
	}
}

export default translate("translations")(Content);
