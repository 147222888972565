import React, {Component} from 'react';
import axios from "axios";
import isoLang from "../data/isoLangs";
import {translate} from "react-i18next";

class SpeechRecognitionMobile extends Component {
    constructor(props) {
        super(props);
        this.isCordova = navigator.userAgent.match(/cordova/i);

        this.state = {
            permissionGranted: false,
            listening: false,
            speechToText: '',
            translateTo: 'de-DE',
            translateFrom: localStorage.getItem('i18nextLng'),
            speaking: false,
            internetConnection: false,
	        fromLangName: '',
	        toLangName: '',
	        reverseLangs: false
        };
        this.askForPermission = this.askForPermission.bind(this);
    }
    initTranslate = () => {
	    this.translateToShortened = this.state.translateTo.split('-')[0];
	    this.translateFromShortened = this.state.translateFrom.split('-')[0];
	    if (!this.state.reverseLangs) {
		    for (let i in isoLang) {
			    if (isoLang.hasOwnProperty(i)) {
				    if (this.translateToShortened === i) {
					    this.setState({
						    toLangName: isoLang[i].nativeName
					    });
				    }
				    if (this.translateFromShortened === i) {
					    this.setState({
						    fromLangName: isoLang[i].nativeName
					    });
				    }
			    }
		    }
	    } else {
		    let from = this.state.fromLangName;
		    let to = this.state.toLangName;
		    this.setState({
			    fromLangName: to,
			    toLangName: from
		    })
	    }
    };

    componentDidMount() {
        if (this.isCordova) {
            document.addEventListener("online", this.refreshAfterConnention, false);
            document.addEventListener("offline", this.noInternetConnention, false);
            if (navigator.connection.type !== 'none') {
                this.setState({
                    internetConnection: true
                })
            } else {
                this.setState({
                    internetConnection: false
                })
            }
        }

        this.askForPermission();
    }

    noInternetConnention = () => {
        this.setState({
            internetConnection: false
        })
    };

    refreshAfterConnention = () => {
        this.setState({
            internetConnection: true
        })
    };

    askForPermission() {
        window.plugins.speechRecognition.hasPermission((isGranted) => {
            if (isGranted) {

                this.setState({
                    permissionGranted: true
                });
            } else {
                window.plugins.speechRecognition.isRecognitionAvailable((available) => {
                    if (available) {
                        window.plugins.speechRecognition.requestPermission((success) => {
                            this.setState({
                                permissionGranted: true
                            });
                        }, function () {
                        });
                    }
                }, () => {
                });
            }
        }, () => {

        });
	    this.initTranslate();
    };

    toggleListen = () => {
        this.setState({
            listening: !this.state.listening
        }, this.handleListen);
    };

    handleListen = () => {
        let settings = {
	        language: this.state.translateFrom,
            showPopup: true
        };
        if (this.state.listening) {
            window.plugins.speechRecognition.startListening((result) => {
                this.setState({
                    speechToText: result[0]
                });
                document.getElementById('final').innerHTML = this.state.speechToText;
            }, () => {
            }, settings);

        } else {
            window.plugins.speechRecognition.stopListening(() => {
                // No more recognition
                document.getElementById('final').innerHTML = this.state.speechToText;
                axios.get('https://translate.yandex.net/api/v1.5/tr.json/translate?key=trnsl.1.1.20190107T102846Z.ec41886338390a8a.2447a4d1853ae300a7e14b6417831085e05ea4ef' +
                    '&text=' + this.state.speechToText + '&lang=' + this.translateFromShortened + '-' + this.translateToShortened)
                    .then(response => {

                        document.getElementById('final').innerHTML = response.data.text[0];
                        window.TTS.speak({
                            text: response.data.text[0],
                            locale: this.state.translateTo,
                        }).then(() => {
                            this.setState({
                                speaking: true
                            });
                        }, () => {

                        });
                    })
                    .catch(() => {

                    })
            }, () => {

            });
        }
    };

    openInstallLanguagesModal() {
        window.TTS.openInstallTts().then(() => {
        }, () => {
        });
    }

    checkIsTextIsTranslated() {
        if (this.state.speaking) {
            return <button className="btn default-button speech-button orange-btn" onClick={this.sayAgain}>
                <i className="fas fa-redo"/>
            </button>
        }
    }

    sayAgain = () => {
        window.TTS.speak({
            text: document.getElementById('final').innerHTML,
            locale: this.state.translateTo,
        }).then(() => {
        }, () => {
        });
    };
	reverseLang = () => {
		let from = this.state.translateFrom;
		let to = this.state.translateTo;
		this.setState({
			translateFrom: to,
			translateTo: from,
			reverseLangs: !this.state.reverseLangs
		}, this.initTranslate);
	};
    render() {
	    const {t} = this.props;

	    if (this.state.internetConnection) {
            if (this.state.permissionGranted) {
                return <div>
                    <div className="bg-content"
                         style={{backgroundImage: 'url(' + require('../images/tlumacz.svg') + ')'}}/>
	                <p>{t('translation')}</p>
	                <button className="btn default-button width-auto" onClick={this.reverseLang}><p className="upper">{this.state.fromLangName} <i className="fas fa-long-arrow-alt-right "/>{this.state.toLangName}</p></button>
                    <p>{t('translationButton')}</p>
                    <button className={"btn default-button speech-button" + (this.state.listening ? " listening" : " ")}
                            onClick={this.toggleListen}>
                        <i className="fas fa-microphone"/>
                    </button>
                    {this.checkIsTextIsTranslated()}
                    <div id='final'/>
                    <br/>
                    <p className="small" onClick={this.openInstallLanguagesModal}><b>{t('wrongLanguage')}</b></p>
                </div>
            } else {
                return <div>
                    <div className="bg-content"
                         style={{backgroundImage: 'url(' + require('../images/tlumacz.svg') + ')'}}/>
                    <p>{t('microphoneNeeded')}</p>
                    <p onClick={this.askForPermission}><b>{t('allow')}</b></p>
                </div>
            }
        } else {
            return <div>
                <div className="bg-content"
                     style={{backgroundImage: 'url(' + require('../images/tlumacz.svg') + ')'}}/>
                <p>{t('offlineApk')}</p>
            </div>
        }

    }
}
export default translate("translations")(SpeechRecognitionMobile);
