import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {translate} from "react-i18next";

class Procedure extends Component {
	constructor(props) {
		super(props);
		if (props.location.state !== undefined) {
			this.state = {
				procedure: props.location.state.procedure,
				eventId: props.match.params.eventId,
				noData: false
			};
		} else {
			this.state = {
				noData: true
			};
		}
	}

	render() {
		const {t} = this.props;

		if (this.state.noData) {
			return <Redirect to="/menu"/>
		} else {
			return <div className="bg">
				<div className="header row no-gutters col-12">
					<Link className="back d-flex" to={'/event/' + this.state.eventId}>
						<div className="arrow-header">
							<i className="fas fa-chevron-left"/>
						</div>
						<div className="title-header">
							<h5>{t('procedure')}</h5>
						</div>
					</Link>
				</div>
				<div className="page-content col-12">
					<div className="page-div border-radius-all">
						<p><strong>{t('procedureSteps')}</strong></p>
						<hr/>
						<div dangerouslySetInnerHTML={{__html: this.state.procedure}}/>
					</div>
				</div>
			</div>
		}
	}
}
export default translate("translations")(Procedure);
