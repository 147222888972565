import React, {Component} from 'react';
import {translate} from "react-i18next";
import EventHeader from "../components/EventHeader";

class Medicines extends Component {
	constructor(props) {
		super(props);

		this.state = {
			edit: false,
			about: (localStorage.getItem('about') || '').replace(/(?:\r\n|\r|\n)/g, '<br>'),
			health: (localStorage.getItem('health') || '').replace(/(?:\r\n|\r|\n)/g, '<br>'),
			medicines: (localStorage.getItem('medicines') || '').replace(/(?:\r\n|\r|\n)/g, '<br>'),
			diseases: (localStorage.getItem('diseases') || '').replace(/(?:\r\n|\r|\n)/g, '<br>'),
		}

	}
	translateUrl() {
		const {t} = this.props;

		let text = 'Moje zdrowie\n\n' +
			t('personalData') +':\n' +
			(localStorage.getItem('about') || '') + '\n' +
			t('health') +':\n' +
			(localStorage.getItem('health') || '') + '\n' +
			t('diseases') +':\n' +
			(localStorage.getItem('diseases') || '') + '\n' +
			t('medicines') +':\n' +
			(localStorage.getItem('medicines') || '');

		return 'https://translate.google.com/#view=home&op=translate&sl=pl&tl=de&text=' + encodeURIComponent(text);
	}

	editOpen = () => {
		this.setState({
			edit: true
		})
	};

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	getValue = () => {
		localStorage.setItem('health', this.state.health);
		localStorage.setItem('about', this.state.about);
		localStorage.setItem('diseases', this.state.diseases);
		localStorage.setItem('medicines', this.state.medicines);

		this.setState({
			edit: false
		})
	};

	render() {
		const {t} = this.props;

		if (this.state.edit) {
			return <div>
				<EventHeader header={t('myHealthEdit')} backTo="/menu"/>
				<div className="medicine-bg">
				</div>
				<div className="page-content col-12 ">
					<div className="page-div border-radius-all">
						<p><strong>{t('myHealth')}</strong></p>
						<hr/>
						<div className="margin-top-20 margin-bottom-10">
							<p>{t('writeData')}</p>
						</div>
						<p>{t('basicPersonalData')};</p>
						<p>{t('healthElement')};</p>
						<p>{t('diseasesElement')};</p>
						<p className="margin-bottom-15">{t('medicinesElement')}.</p>
						<div className="margin-top-10 padding-right-15">
							<p><strong>{t('writeDataInfo')}</strong></p>
						</div>
						<div className="margin-top-10 padding-right-15">
							<p>{t('writeDataLanguage')}</p>
						</div>
						<div className="margin-top-10 margin-bottom-20">
							<p>{t('writeDataPlace')}</p>
						</div>

						<p className="margin-top-20"><strong>{t('myData')}</strong></p>
						<textarea name='about' className="margin-top-10 " onChange={this.valueChange}  value={this.state.about}/>
						<div className="clearfix"/>

						<p className="margin-top-20"><strong>{t('health')}</strong></p>
						<textarea name='health' className="margin-top-10 " onChange={this.valueChange}  value={this.state.health}/>
						<div className="clearfix"/>

						<p className="margin-top-20"><strong>{t('diseases')}</strong></p>
						<textarea name='diseases' className="margin-top-10" onChange={this.valueChange}  value={this.state.diseases}/>
						<div className="clearfix"/>

						<p className="margin-top-20"><strong>{t('medicines')}</strong></p>
						<textarea name='medicines' className="margin-top-10" onChange={this.valueChange}  value={this.state.medicines}/>
						<button className="btn default-button full-width-button margin-top-10" onClick={this.getValue}>{t('save')}</button>
						<div className="clearfix margin-bottom-10"/>

					</div>
				</div>
			</div>
		} else {
			return <div>
				<EventHeader header={t('myHealth')} backTo="/menu" hideBtn={true}/>
				<div className="account-bg">
				</div>
				<div className="page-content page-content-no-margin-bottom col-12 position-absolute">
					<div className="page-div border-radius-all"><p><strong>{t('myHealth')}</strong></p>
						<hr/>
						<p className="margin-top-20 margin-bottom-10"><strong>{t('personalData')}</strong></p>
						<div dangerouslySetInnerHTML={{__html: this.state.about}}/>
						<p className="margin-top-20 margin-bottom-10"><strong>{t('health')}</strong></p>
						<div dangerouslySetInnerHTML={{__html: this.state.health}}/>
						<p className="margin-top-20 margin-bottom-10"><strong>{t('diseases')}</strong></p>
						<div dangerouslySetInnerHTML={{__html: this.state.diseases}}/>
						<p className="margin-top-20 margin-bottom-10"><strong>{t('medicines')}</strong></p>
						<div dangerouslySetInnerHTML={{__html: this.state.medicines}}/>
						<a className="btn default-button margin-top-10" href={this.translateUrl()} target="_blank"
						   rel="noopener noreferrer">{t('translate')}</a>
						<button className="btn default-button edit-button full-width-button"
						        onClick={this.editOpen}>{t('edit')}
						</button>
					</div>
				</div>
			</div>
		}
	}
}

export default translate("translations")(Medicines);
