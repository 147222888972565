import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import axios from "axios";
import {translate} from "react-i18next";
const addr = 'https://api.helphelptrip.com/api/1.0';

class FileOrFolder extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			optionsOpen: false,
			deleteFolder: false,
			deleteComplete: false,
			relocateFolder: false,
			deleteError: false,
			isFile: props.isFile,
			name: props.name,
			files: props.files,
			link: props.link,
			showFile: false,
			redirect: false,
			pathMaker: '',
			renameFolder: false,
			storage: props.storage,
			folderNotEmpty: false,
			props: props.props,
			id: props.id,
			rootDir: localStorage.getItem('rootDir'),
			options: {},
		};
		let handleToUpdate = this.handleToUpdate.bind(this);
	}

	componentDidMount() {

		if (!this.state.isFile) {
			if (this.props.nameFrom === undefined) {
			} else {
				let makeSlugFrom = this.props.nameFrom.replace(' ', '-').toLowerCase();
				let makeSlug = this.props.name.replace(' ', '-').toLowerCase();
				this.setState({
					pathMaker: makeSlugFrom + '/' + makeSlug,
					dataType: "folder",
					storage: this.props.storage
				});
			}
		} else {
			this.setState({
				dataType: "plik"
			})
		}
	}

	handleToUpdate = (newStorage) => {
		this.setState({
			storage: {files: [], directories: []},
			newStorage: true
		});
		this.setState({
			storage: newStorage,
			newStorage: true,
			storageList: true
		});
	};

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	hideOptions = () => {
		this.setState({
			optionsOpen: false
		})
	};

	showOptions = () => {
		this.setState({
			optionsOpen: true,
			folderName: ''
		});
	};

	deleteFolder = () => {
		this.setState({
			deleteFolder: true
		});
	};

	confirmDelete = () => {
		this.setState({
			deleteFolder: true

		});
		if (this.state.dataType === "folder") {
			axios.delete(addr + '/dirs/' + this.state.id + '/false', {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						folderEmpty: response.data.empty,
						deleteFolderOrFolder: false,
						deleteFolder: false
					}, () => {
						if (this.state.folderEmpty) {
							this.setState({
								folderDeleted: true,
								folderNotEmpty: true,
								deleteAll: true,
								storageList: true
							}, () => {
								setTimeout(() =>
									this.setState({
										folderDeleted: false,
										optionsOpen: false,
										folderNotEmpty: false,
										deleteAll: false,
										storageList: false
									}), 1500);
							})
						} else {
							this.setState({
								folderNotEmpty: true,
								folderDeleted: false
							})
						}
					});
				})
				.catch((error) => {
				});

		} else if (this.state.dataType === "plik") {

			axios.delete(addr + '/files/' + this.props.files.id, {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						fileDeleted: true,
						deleteAll: true,
						storageList: true,
						deleteFolder: false
					}, () => {
						setTimeout(() =>
							this.setState({
								fileDeleted: false,
								optionsOpen: false,
								deleteAll: false,
								storageList: false
							}), 1500);
					});
				})
				.catch((error) => {
				});
		}
	};

	deleteAll = () => {
		axios.delete(addr + '/dirs/' + this.state.storage.id + '/true', {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			}
		})
			.then((response) => {
				this.setState({
					deleteAll: true,
					storageList: true
				}, () => {
					setTimeout(() =>
						this.setState({
							folderDeleted: false,
							optionsOpen: false,
							folderNotEmpty: false,
							deleteAll: false,
						}), 1500);
				});
			})
			.catch((error) => {
			});
	};

	returnToStorage = () => {
		this.hideOptions();
		this.setState({
			deleteFolder: false,
			renameFolder: false,
			relocateFolder: false,
			deleteFolderWithContent: false,
			deleteFolderOrFolder: false
		});
	};

	relocateFolder = () => {
		this.setState({
			relocateFolder: true,
		});
		if (this.state.dataType === "folder") {
			if (this.state.relocateFolder === false) {
				axios.get(addr + '/dirs/tree', {
					headers: {
						'Content-type': 'application/json',
						token: localStorage.getItem('token')
					}
				})
					.then((response) => {

						this.setState({
							main: response.data,
							getDirs: true,
						});
					})
					.catch((error) => {
					});
			}
		} else if (this.state.dataType === "plik") {
			if (this.state.relocateFolder === false) {
				axios.get(addr + '/dirs/tree/files', {
					headers: {
						'Content-type': 'application/json',
						token: localStorage.getItem('token')
					}
				})
					.then((response) => {
						this.setState({
							main: response.data,
							getDirs: true,
						});
					})
					.catch((error) => {
					});
			}
		}
	};

	confirmRelocation = () => {
		if (this.state.dataType === "folder") {
			axios.put(addr + '/dirs', {
					parentDir: this.state.chosenId,
					uuid: this.state.id,
					force: false,
					name: ''
				},
				{
					headers: {
						'Content-type': 'application/json',
						token: localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.setState({
						relocateFolderSuccess: true
					}, () => {
						setTimeout(() =>
							this.setState({
								relocateFolder: false,
								optionsOpen: false,
								relocateFolderSuccess: false,
								storageList: false
							}), 1500)
					})
				})
				.catch((error) => {
				});
		} else if (this.state.dataType === "plik") {
			axios.put(addr + '/files', {
					dir: this.state.chosenId,
					uuid: this.props.files.id,
					name: ''
				},
				{
					headers: {
						'Content-type': 'application/json',
						token: localStorage.getItem('token')
					}
				})
				.then((response) => {
					this.setState({
						relocateFolderSuccess: true
					}, () => {
						setTimeout(() =>
							this.setState({
								relocateFolder: false,
								optionsOpen: false,
								relocateFolderSuccess: false,
								storageList: false
							}), 1500)
					})
				})
				.catch((error) => {
				});
		}
	};

	openFolder = () => {
		this.setState({
			redirect: true
		})
	};

	openFile = () => {
		this.setState({
			showFile: true
		})
	};

	closeFile = () => {
		this.setState({
			showFile: false
		})
	};

	renameFolder = () => {
		if (this.props.nameFrom === 'Storage' ) {
			this.setState({
				parentDir: this.state.rootDir
			});
		} else if (this.props.nameFrom !== 'Storage' && this.state.dataType === "folder") {
			this.setState({
				parentDir: this.props.storage.id
			})
		} else if ((this.props.files.dir !== undefined && this.props.files.dir === this.state.rootDir)) {
			this.setState({
				parentDir: this.state.rootDir
			});
		}
		this.setState({
			renameFolder: true,
			optionsOpen: false,
		})
	};

	confirmRename = () => {
		const {t} = this.props;

		if (this.state.dataType === t('dir')) {
			axios.put(addr + '/dirs', {
				parentDir: this.state.parentDir,
				name: this.state.folderName,
				uuid: this.state.id,
				force: true
			}, {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						renameFolderSuccess: true
					}, () => {
						setTimeout(() =>
							this.setState({
								renameFolder: false,
								optionsOpen: false,
								renameFolderSuccess: false
							}), 1500)
					});
				})
				.catch((error) => {
					this.setState({
						dirError: error.response.data.dirs
					});
				});
		} else if (this.state.dataType === t('file')) {
			axios.put(addr + '/files', {
				dir: this.state.parentDir,
				name: this.state.fileName,
				uuid: this.props.files.id,
			}, {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						renameFolderSuccess: true
					}, () => {
						setTimeout(() =>
							this.setState({
								renameFolder: false,
								optionsOpen: false,
								renameFolderSuccess: false
							}), 1500)
					});
				})
				.catch((error) => {
					this.setState({
						fileError: error.response.data.file
					});
				})
		}
	};

	closeOptionPopup = () => {
		return <div className="popup-flex-close">
			<div className="popup-close" onClick={this.returnToStorage}>
				<span className="fas fa-times grey-color"/>
			</div>
		</div>
	};

	fileShowing = () => {
		if (this.state.showFile && this.state.fileUrl === undefined) {
			axios.post(addr + '/files/download', {
				uuid: this.props.files.id,
			}, {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						fileUrl: response.data.fileUrl
					})
				})
				.catch((error) => {

				});

		}


		if (this.state.fileUrl !== undefined && this.state.showFile && !this.isCordova) {
			return <div>
				<div className="greyed-out"/>
				<div className="embed-pop-up">
					<i className="fas fa-times white-color" onClick={this.closeFile}/>
					<embed src={this.state.fileUrl} width="100%" height="560px" />
				</div>
			</div>
		} else if (this.state.fileUrl !== undefined && this.state.showFile && this.isCordova) {
			return <div>
				<div className="greyed-out"/>
				<div className="embed-pop-up">
					<i className="fas fa-times white-color" onClick={this.closeFile}/>
				</div>
			</div>
		}
		// 	console.log('cordova open');
		// 	// window.open = window.cordova.InAppBrowser.open;
		// 	// window.cordova.plugins.fileOpener2.open(
		// 	//     '../images/wzory_10.pdf',
		// 	//     'application/pdf',
		// 	//     {
		// 	//         error: (e) => {
		// 	//             console.log('Error status:' + e.status)
		// 	//         },
		// 	//         success: () => {
		// 	//             console.log('File opened')
		// 	//         }
		// 	//     }
		// 	// );
		// 	// cordova-plugin-pdf-viewer
		// 	// lub
		// 	// cordova-plugin-document-viewer
		// 	return <div/>
		// } else {
		// 	return <div/>
		// }

	};

	generateIcon = () => {
		if (this.props.directory) {
			return <div className="folder-div single-file-or-folder" onClick={this.openFile}>
				{this.fileIcon()}
				<div className="file-name">
					<h6>{this.state.name}</h6>
				</div>
			</div>
		} else {
			if (this.state.isFile) {
				return <div className="folder-div single-file-or-folder" onClick={this.openFile}>
					{this.fileIcon()}
					<div className="file-name">
						<h6>{this.state.name}</h6>
					</div>
				</div>
			} else {
				return <div className="folder-div single-file-or-folder" onClick={this.openFolder}>
					<i className="fas fa-folder grey-color"/>
					<div className="folder-name">
						<h6>{this.state.name}</h6>
					</div>
				</div>
			}
		}
	};

	fileIcon = () => {
		if (this.props.fileType === 'pdf') {
			return <i className="fas fa-file-pdf grey-color"/>
		} else if (this.props.fileType === 'csv') {
			return <i className="fas fa-file-excel grey-color"/>
		} else if (this.props.fileType === 'pptx') {
			return <i className="fas fa-file-powerpoint grey-color"/>
		} else if (this.props.fileType === 'doc' || this.props.fileType === 'odt') {
			return <i className="fas fa-file-word grey-color"/>
		} else {
			return <i className="fas fa-file grey-color"/>
		}
	};

	dirButton = (file) => {
		if (this.state.isFile === undefined || this.state.isFile === false) {
			if (this.state.chosenId === file.id) {
				return <button className="getid-button getid-button-chosen" onClick={() => this.saveId(file.id)}>
					<i className="fas fa-folder"/>
					<p>{file.name}</p>
				</button>
			} else {
				return <button className="getid-button" onClick={() => this.saveId(file.id)}>
					<i className="fas fa-folder"/>
					<p>{file.name}</p>
				</button>
			}
		} else {
			if (this.state.dirChosen === undefined) {
				return <div>
					<button className="getid-button" onClick={() => this.saveId(file.id)}>
						<i className="fas fa-folder"/>
						<p>{file.name}</p>
					</button>
					{file.sub_dir.map((subfile) => {
							return <button className="getid-button-subdir" onClick={() => this.saveId(subfile.id)}>
								<i className="fas fa-folder"/>
								<p>{subfile.name}</p>
							</button>
						}
					)}
				</div>
			} else {
				if (this.state.chosenId === file.id) {
					return <div>
						<button className="getid-button getid-button-chosen" onClick={() => this.saveId(file.id)}>
							<i className="fas fa-folder"/>
							<p>{file.name}</p>
						</button>
						{file.sub_dir.map((subfile) => {
								if (this.state.chosenId === subfile.id) {
									return <button className="getid-button-subdir getid-button-chosen"
									               onClick={() => this.saveId(subfile.id)}>
										<i className="fas fa-folder"/>
										<p>{subfile.name}</p>
									</button>
								} else {
									return <button className="getid-button-subdir" onClick={() => this.saveId(subfile.id)}>
										<i className="fas fa-folder"/>
										<p>{subfile.name}</p>
									</button>
								}
							}
						)}
					</div>
				} else {
					return <div>
						<button className="getid-button" onClick={() => this.saveId(file.id)}>
							<i className="fas fa-folder"/>
							<p>{file.name}</p>
						</button>
						{file.sub_dir.map((subfile) => {
								if (this.state.chosenId === subfile.id) {
									return <button className="getid-button-subdir getid-button-chosen"
									               onClick={() => this.saveId(subfile.id)}>
										<i className="fas fa-folder"/>
										<p>{subfile.name}</p>
									</button>
								} else {
									return <button className="getid-button-subdir" onClick={() => this.saveId(subfile.id)}>
										<i className="fas fa-folder"/>
										<p>{subfile.name}</p>
									</button>
								}
							}
						)}
					</div>
				}
			}
		}
	};

	operationsOnFile = () => {
		const {t} = this.props;

		// usuń pusty folder
		if (this.state.deleteFolder) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t('delete')} {this.state.dataType}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal">{t("confirmDelete")} {this.state.dataType}?</h6>
						<button className="btn default-button full-width-button" onClick={this.confirmDelete}>{t("yes")}</button>
						<button className="btn default-button full-width-button margin-top-10" onClick={this.returnToStorage}>{t("no")}</button>
					</div>
				</div>
			</div>
		}

		//usuń folder z zawartością
		if (this.state.folderNotEmpty && !this.state.folderDeleted && !this.state.deleteAll) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t('deleteDir')} </p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal"> {t("dirNotEmpty")}<br/>{t("dirNotEmptyDelete")}</h6>
						<button className="btn default-button full-width-button" onClick={this.deleteAll}>{t("deleteAll")}
						</button>
						<button className="btn default-button full-width-button margin-top-10" onClick={this.returnToStorage}>{t("cancel")}</button>
					</div>
				</div>
			</div>
		}

		if (this.state.folderNotEmpty && this.state.deleteAll && this.props.nameFrom === 'Storage') {
			this.getNewDataRoot();
			if (this.state.storageList) {
				return <div>
					<div className="overlay-storage">
					</div>
					<div id="deleteConfirmPopup">
						<div className="popup-title"><p>{t("success")}</p></div>
						<div className="popup">
							<h6 className="modal-title first-letter-big" id="errorModal">{t("dirDeleteSuccess")}</h6>
						</div>
					</div>
				</div>
			}
		} else if (this.state.folderNotEmpty && this.state.deleteAll && this.props.nameFrom !== 'Storage') {
			this.getNewDataId();
			if (this.state.storageList) {
				return <div>
					<div className="overlay-storage">
					</div>
					<div id="deleteConfirmPopup">
						<div className="popup-title"><p>{t("success")}</p></div>
						<div className="popup">
							<h6 className="modal-title first-letter-big" id="errorModal">{t("dirDeleteSuccess")}</h6>
						</div>
					</div>
				</div>
			}
		}

		if (this.state.fileDeleted && this.state.deleteAll && this.props.files.dir === this.state.rootDir) {
			this.getNewDataRoot();
			if (this.state.storageList) {
				return <div>
					<div className="overlay-storage">
					</div>
					<div id="deleteConfirmPopup">
						<div className="popup-title"><p>{t("success")}</p></div>
						<div className="popup">
							<h6 className="modal-title first-letter-big" id="errorModal">{t("fileDeleteSuccess")}</h6>
						</div>
					</div>
				</div>
			}

		} else if (this.state.fileDeleted && this.state.deleteAll && this.props.files.dir !== this.state.rootDir) {
			this.getNewDataId();
			if (this.state.storageList) {
				return <div>
					<div className="overlay-storage">
					</div>
					<div id="deleteConfirmPopup">
						<div className="popup-title"><p>{t("success")}</p></div>
						<div className="popup">
							<h6 className="modal-title first-letter-big" id="errorModal">{t("fileDeleteSuccess")}</h6>
						</div>
					</div>
				</div>
			}
		}

		// przenieś
		if (this.state.relocateFolder && !this.state.relocateFolderSuccess && this.state.getDirs) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t("moveDir")}</p></div>
					<div className="popup relocate-folder">
						<h6 className="modal-title" id="errorModal">{t("chooseLocation")}</h6>
						<div className=" option-no-padding dir-icon">
							{this.mainDir()}
						</div>
						{this.state.main.map((file) => {
								return <div className="dirs-flex">
									{this.dirButton(file)}
								</div>
							}
						)}
						<div className="buttons-row">
							<button className="btn default-button margin-top-10" onClick={this.returnToStorage}>{t("cancel")}</button>
							<button className="btn default-button margin-top-10" onClick={this.confirmRelocation}>{t("ok")}</button>
						</div>
					</div>
				</div>
			</div>
		}

		if (this.state.relocateFolder && this.state.relocateFolderSuccess && this.props.nameFrom === 'Storage') {
			this.getNewDataRoot();
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal">{this.state.dataType} {t("moveSuccess")}</h6>
					</div>
				</div>
			</div>
		} else if (this.state.relocateFolder && this.state.relocateFolderSuccess && this.props.nameFrom !== 'Storage') {
			this.getNewDataId();
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title first-letter-big" id="errorModal">{this.state.dataType} {t("moveSuccess")}</h6>
					</div>
				</div>
			</div>
		}

		// zmień nazwę
		if (this.state.renameFolder && !this.state.renameFolderSuccess && this.state.dataType === 'folder') {
			let folderName = this.props.name;
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t("changeName")}</p></div>
					<div className="popup">
						<input type="text" placeholder={folderName} onChange={this.valueChange} name="folderName"
						       value={this.state.folderName}/>
						<p className="error-line">{this.state.dirError}</p>
						{this.displayButton()}
						<button className="btn default-button full-width-button margin-top-10" onClick={this.returnToStorage}>{t("cancel")}</button>
					</div>
				</div>
			</div>
		}

		if (this.state.renameFolder && !this.state.renameFolderSuccess && this.state.dataType === t('file')) {
			let fileName = '',
				fileFullName = '',
				fileNamePart = '';

			fileFullName = this.props.files.name;
			fileNamePart = fileFullName.split('.');
			fileName = fileNamePart[0];

			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t("changeName")}</p></div>
					<div className="popup">
						<input type="text" placeholder={fileName} onChange={this.valueChange} name="fileName"
						       value={this.state.fileName}/>
						<p className="error-line">{this.state.fileError}</p>
						<button className="btn default-button full-width-button" onClick={this.confirmRename}>{t("change")}</button>
						<button className="btn default-button full-width-button margin-top-10" onClick={this.returnToStorage}>{t("cancel")}</button>
					</div>
				</div>
			</div>
		}

		if (this.state.renameFolder && this.state.renameFolderSuccess && this.props.nameFrom === 'Storage') {
			this.getNewDataRoot();
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal">{t("changeNameSuccess")}</h6>
					</div>
				</div>
			</div>
		} else if (this.state.renameFolder && this.state.renameFolderSuccess && this.props.nameFrom !== 'Storage') {
			this.getNewDataId();
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal">{t("changeNameSuccess")}</h6>
					</div>
				</div>
			</div>
		}
	};

	displayButton() {
		const {t} = this.props;

		if ((this.state.folderName !== '' && this.state.folderName.length >= 3)) {
			return <button className="btn default-button full-width-button" onClick={this.confirmRename}>{t("change")}</button>
		} else {
			return <button className="btn default-button button-disabled full-width-button">{t("change")}</button>
		}
	}

	mainDir = () => {
		const {t} = this.props;

		if (this.state.chosenId === this.state.rootDir) {
			return <button className="getid-button getid-button-chosen" onClick={() => this.saveId(this.state.rootDir)}>
				<i className="fas fa-folder"/>
				<p>{t("mainDir")}</p>
			</button>
		} else {
			return <button className="getid-button" onClick={() => this.saveId(this.state.rootDir)}>
				<i className="fas fa-folder"/>
				<p>{t("mainDir")}</p>
			</button>
		}
	};

	saveId = (id) => {
		this.setState({
			chosenId: id,
			dirChosen: true
		});
	};

	getNewDataRoot = () => {
		axios.get(addr + '/dirs/' + this.state.rootDir + '/items', {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			}
		})
			.then((response) => {
				this.setState({
					storage: response.data,
					storageList: true
				});
				let handleToUpdate = this.props.handleToUpdate;
				handleToUpdate(this.state.storage)
			})
			.catch((error) => {
			});
	};

	getNewDataId = () => {
		const {t} = this.props;

		if (this.state.dataType === t('dir')) {
			axios.get(addr + '/dirs/' + this.props.storage.id + '/items', {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {

					this.setState({
						storage: response.data,
						storageList: true
					});
					let handleToUpdate = this.props.handleToUpdate;
					handleToUpdate(this.state.storage)
				})
				.catch((error) => {
				});
		} else if (this.state.dataType === t('file')) {
			axios.get(addr + '/dirs/' + this.props.files.dir + '/items', {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {

					this.setState({
						storage: response.data,
						storageList: true
					});
					let handleToUpdate = this.props.handleToUpdate;
					handleToUpdate(this.state.storage)
				})
				.catch((error) => {
				});
		}
	};

	options = () => {
		const {t} = this.props;

		if (this.state.optionsOpen) {
			return <div >
				<div className="flex-options">
					<div className="file-or-folder-options-close" onClick={this.hideOptions}>
						<span className="fas fa-times grey-color"/>
					</div>
				</div>
				<div className="folder-div file-or-folder-actions ">
					<div className="hr-no-magrin">
						<div className="options-list row">
							<div className="col-3 option-no-padding option-icon">
								<i className="fas fa-arrows-alt"/>
							</div>
							<div className="col-9 option-no-padding option-name" onClick={this.relocateFolder}>
								<span>{t("move")}</span>
							</div>
						</div>
						<hr/>
						<div className="options-list row">
							<div className="col-3 option-no-padding option-icon">
								<i className="fas fa-trash"/>
							</div>
							<div className="col-9 option-no-padding option-name" onClick={this.deleteFolder}>
								<span>{t("delete")}</span>
							</div>
						</div>
						<hr/>
						<div className="options-list row">
							<div className="col-3 option-no-padding option-icon">
								<i className="fas fa-file-signature"/>
							</div>
							<div className="col-9 option-no-padding option-name" onClick={this.renameFolder}>
								<span>{t("changeName")}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		} else {
			return <div className='flex-column d-flex'>
				<div className="flex-options">
					<div className="file-or-folder-options" onClick={this.showOptions}>
						<span className="fas fa-ellipsis-v grey-color"/>
					</div>
				</div>
				{this.generateIcon()}
			</div>
		}
	};

	render() {
		if (this.state.redirect) {
			return <Redirect to={{
				pathname: this.state.pathMaker,
				state: {
					files: this.state.files,
					name: this.state.name,
					slug: this.state.slug,
					nameFrom: this.props.nameFrom,
					headerMaker: this.state.headerMaker,
					storage: this.state.storage,
					id: this.props.id

				}
			}}/>
		} else {
			return <div className="data-box">
				{this.fileShowing()}
				{this.operationsOnFile()}
				{this.options()}
			</div>
		}
	}
}
export default translate("translations")(FileOrFolder);
