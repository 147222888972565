import React, {Component} from 'react';

import Style from '../classes/Style';
import {translate} from "react-i18next";

class Contacts extends Component {
	render() {
		const {t} = this.props;

		return <div className="no-pointer-events">
			{this.props.contacts.map((contact, index) => {
				return (<div key={index} className="page-div border-radius-all">
					<div className="bg-content " style={Style.background(contact.background)}/>
					<p><strong>{contact.title}</strong></p>
					<p>{contact.name}</p>
					<a className="btn default-button green-btn margin-top-10" href={'tel:+' + contact.phone}>
						<i className="fas fa-phone"/> {t("call")}
					</a>
				</div>)
			})}
		</div>
	}
}
export default translate("translations")(Contacts);
