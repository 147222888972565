import React, {Component} from 'react';
import EventHeader from './../components/EventHeader'
import {Redirect} from 'react-router-dom';
import {translate} from "react-i18next";

class Language extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.name = React.createRef();
		this.about = React.createRef();
		this.health = React.createRef();
		this.medicines = React.createRef();
		this.diseases = React.createRef();
		this.fallDetector = React.createRef();

		this.state = {
			goHome: false,
			oldPassword: '',
			newPassword: '',
			confirmNewPassword: '',
			phone: localStorage.getItem('phone'),
			phone2: localStorage.getItem('phone2'),
			name: localStorage.getItem('name'),
			email: localStorage.getItem('user'),
			guest: localStorage.getItem('guest'),
			lang: localStorage.getItem('i18nextLng'),
			languageChangeSuccess: false
		};
	}

	componentDidMount() {
		if (localStorage.getItem('phone') === null) {
			localStorage.setItem('phone', '');
		}
		if (localStorage.getItem('phone2') === null) {
			localStorage.setItem('phone2', '');
		}
		if (localStorage.getItem('name') === null) {
			localStorage.setItem('name', '');
		}
	}

	getFallDetectorValue = (e) => {
		if (this.state.phone !== '' || this.state.phone2 !== '') {
			if (localStorage.getItem('fallDetector') === undefined) {
				localStorage.setItem('fallDetector', '0');
			} else {
				localStorage.setItem('fallDetector', this.state.fallDetector);

			}
		} else {
			localStorage.setItem('fallDetector', '0');
		}
		this.setState({
			goHome: true
		});
	};

	getValue = (e) => {
		const {t} = this.props;

		localStorage.setItem('name', this.state.name);

		if (this.state.phone.length === 9 || this.state.phone === '') {
			localStorage.setItem('phone', this.state.phone);
			this.setState({
				errorPhone: ''
			})
		} else {
			this.setState({
				errorPhone: t('errorPhone')
			})
		}

		if (this.state.phone2.length === 9 || this.state.phone2 === '') {
			localStorage.setItem('phone2', this.state.phone2);
			this.setState({
				errorPhone2: ''
			})
		} else {
			this.setState({
				errorPhone2: t('errorPhone')
			})
		}

		if (this.state.phone === '' && this.state.phone2 === '') {
			localStorage.setItem('fallDetector', '0');
		}
	};

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	closePopup = () => {
		this.setState({
			passwordChangeSuccess: false
		})
	};

	changeLanguage = () => {
		const {i18n} = this.props;

		const changeLanguage = lng => {
			i18n.changeLanguage(lng);
		};
		this.setState({

			languageChangeSuccess: true,

		}, () => {
			setTimeout(() =>
				this.setState({
					languageChangeSuccess: false,
				}), 1500);
		});
		let lang = this.state.lang;
		if (lang === 'pl') {
			changeLanguage('pl');

		} else if (lang === 'en') {
			changeLanguage('en');

		} else if (lang === 'fr') {
			changeLanguage('fr');


		} else if (lang === 'ru') {
			changeLanguage('ru');

		}
	};

	successPopup = () => {
		const {t} = this.props;
		if (this.state.languageChangeSuccess) {
			return <div>
				<div className="overlay-success">
				</div>
				<div id="successPopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title first-letter-big" id="errorModal">{t('langSaveSuccess')}</h6>
					</div>
				</div>
			</div>
		}
	};

	render() {
		const {t} = this.props;

		if (this.state.goHome) {
			return <Redirect to="/menu"/>
		}


		return <div>
			<EventHeader header={t('langChange')} backTo="/menu"/>
			<div className="medicine-bg">
			</div>
			<div className="page-content page-content-no-margin-bottom col-12 position-absolute">
				<div className="page-div border-radius-all">
					<p><strong>{t('langChange')}</strong></p>
					<hr/>
					<div className='inputs-lang d-flex'>

						<div className='radio-with-flag'>

							<label htmlFor="pl" className='pl'>
								<input id='pl' type='radio' value='pl' name='lang' onChange={this.valueChange}
								       checked={this.state.lang === 'pl'}/>
								<img className='flag' src={require('../images/pl.svg')} alt='pl'/>
								<p>Polski</p>
							</label>
						</div>
						<div className='radio-with-flag'>

							<label htmlFor="en">
								<input id='en' type='radio' value='en' name='lang' onChange={this.valueChange}
								       checked={this.state.lang === 'en'}/>
								<img className='flag' src={require('../images/en.svg')} alt='en'/>
								<p>English</p>

							</label>
						</div>
						<div className='radio-with-flag'>

							<label htmlFor="fr">
								<input id='fr' type='radio' value='fr' name='lang' onChange={this.valueChange}
								       checked={this.state.lang === 'fr'}/>
								<img className='flag' src={require('../images/fr.svg')} alt='en'/>
								<p>Françoise</p>

							</label>
						</div>
						<div className='radio-with-flag'>

							<label htmlFor="ru">
								<input id='ru' type='radio' value='ru' name='lang' onChange={this.valueChange}
								       checked={this.state.lang === 'ru'}/>
								<img className='flag' src={require('../images/ru.svg')} alt='en'/>
								<p>русский</p>

							</label>
						</div>
					</div>
					<button className="btn default-button full-width-button" onClick={this.changeLanguage}>
						{t('save')}
					</button>
				</div>
			</div>
			{this.successPopup()}
		</div>
	}
}

export default translate("translations")(Language);
