import React, {Component} from 'react';
import axios from "axios";
import {translate} from "react-i18next";

class OnlyAddFile extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			addFolder: false,
			addFolderSuccess: false,
			isFile: props.isFile,
			name: props.name,
			slug: props.slug,
			files: props.files,
			link: props.link,
			folderName: '',
			rootDir: localStorage.getItem('rootDir'),
			data: props.data,
			props: props.props,
			newFileOrFolder: false,
		};
		this.fileInput = React.createRef();
	}

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	addFile = () => {
		this.setState({
			addFile: true,
			optionsOpen: false,
			isFile: true
		});
	};

	closePopup = () => {
		this.setState({
			addFolderSuccess: false,
			addFolder: false
		})
	};

	getNewDataSecondaryId = () => {
		axios.get('https://api.helphelptrip.com/api/1.0/dirs/' + this.state.props.location.state.id + '/items', {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			}
		})
			.then((response) => {

				this.setState({
					storage: response.data,
				});
				let handleToUpdate = this.props.handleToUpdate;
				handleToUpdate(this.state.storage);

			})
			.catch((error) => {

			});
	};

	closeOptionPopup = () => {

			return <div className="popup-flex-close">
				<div className="popup-close" onClick={this.closeFilePopup}>
					<span className="fas fa-times grey-color"/>
				</div>
			</div>
	};

	handleSubmit = (event) => {
		event.preventDefault();
		let formData = new FormData();
		formData.append("document", this.fileInput.current.files[0]);
		this.setState({
			addChosenFile: formData
		}, () => {
			formData.append("dir", this.state.props.location.state.id);
			axios.post('https://api.helphelptrip.com/api/1.0/files',
				formData
				, {
					headers: {
						'Content-type': 'multipart/form-data',
						token: localStorage.getItem('token')
					}
				})
				.then((response) => {

					this.setState({
						addFileSuccess: true,
						isRootDir: false

					}, () => {

						setTimeout(() =>
							this.setState({
								addFolder: false,
								addFile: false,
								optionsOpen: false,
								addFileSuccess: false,
								storageList: false
							}), 2500)
					});
				})
				.catch((error) => {
				});
		});
	};

	closeFilePopup = () => {

		this.setState({
			addFileSuccess: false,
			addFile: false
		})
	};

	submitButton = () => {
		const {t} = this.props;

		if (this.fileInput.current !== null && this.state.inputChanged) {
			return <label className='change-label' htmlFor="file">{t('change')}</label>

		} else {
			return <label htmlFor="file">{t('chooseFile')}</label>
		}
	};

	addedFile = () => {
		const {t} = this.props;

		if (this.fileInput.current !== null && this.state.inputChanged) {
			return <div>
				<p className='chosen-file-name'>{t('fileChosen')}</p>
				<span className='underline-file-name'> {this.fileInput.current.files[0].name}</span>
				<button className="btn default-button" type="submit">{t('add')}</button>
			</div>
		}
	};

	getFile = () => {
		if (this.fileInput.current.files.length !== 0) {
			this.setState({
				inputChanged: true
			});
		}
	};

	addingFilePopup = () => {
		const {t} = this.props;

		if (this.state.addFile && !this.state.addFileSuccess) {
			return <div>
				<div className="overlay">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t('addFile')}</p></div>
					<div className="popup">
						<form onSubmit={this.handleSubmit} onChange={this.getFile}>
							<input type="file" name="file" className="inputfile" id="file" ref={this.fileInput}/>

							{this.submitButton()}
							{this.addedFile()}
						</form>
						<button className="btn default-button" onClick={this.closeFilePopup}>{t('cancel')}</button>
					</div>
				</div>
			</div>
		}

		if (this.state.addFile && this.state.addFileSuccess && !this.state.storageList) {
			this.getNewDataSecondaryId();

		}

		if (this.state.addFile && this.state.addFileSuccess && this.state.storageList) {
			return <div>
				<div className="overlay">
				</div>
				<div id="deleteConfirmPopup">
					<div className="popup-title"><p>{t('success')}</p></div>
					<div className="popup">
						<h6 className="modal-title first-letter-big" id="errorModal">{t('addFileSuccess')}</h6>
					</div>
				</div>
			</div>
		}
	};

	render() {
		const {t} = this.props;

		return (
			<div className="d-flex justify-content-center flex-wrap mb-4">
				<div className="data-box folder-to-add">
					<div className="folder-div single-file-or-folder" onClick={this.addFile}>
			<span className="fa-stack">
			<i className="fas fa-plus fa-stack-1x light-grey-color small-icon"/>
			<i className="far fa-file fa-stack-2x light-grey-color "/>
			</span>
						<div className="file-name">
							<h6>+ {t('addFile2')} </h6>
						</div>
					</div>
				</div>
				{this.addingFilePopup()}
			</div>
		)
	}
}
export default translate("translations")(OnlyAddFile);
