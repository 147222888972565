import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';

import Style from '../classes/Style'
import {translate, Trans} from "react-i18next";

class Category extends Component {
	constructor(props) {
		super(props);
		if (props.location.state !== undefined) {
			this.state = {
				otherFacilities: props.location.state.otherFacilities,
				eventId: props.match.params.eventId,
				categoryId: props.match.params.categoryId,
				noData: false
			};
		} else {
			this.state = {
				noData: true
			};
		}
	}

	renderCoordinates(coordinates) {
		if (coordinates.latitude && coordinates.longitude) {
			return <p>{coordinates.latitude}, {coordinates.longitude}</p>
		} else {
			return false;
		}
	}


	renderImg(place) {
		if (place.image) {
			return <div className="info-container">
				<div className="img-con">
					<img src={place.image}/>
				</div>
				<div className="par-con">
					<p className="margin-bottom-20">
						<strong>{place.name}</strong>
					</p>
					<p>{place.address}</p>
					{this.renderCoordinates(place.coordinates)}
					<p>
						{place.phones.map((phone, i) => {
							return <a className="d-block" key={i}
							          href={'tel:' + phone}>{phone}</a>
						})}
					</p>
				</div>
			</div>
		} else {
			return <div className="info-container">
				<div className="par-con">
					<p className="margin-bottom-20">
						<strong>{place.name}</strong>
					</p>
					<p>{place.address}</p>
					{this.renderCoordinates(place.coordinates)}
					<p>
						{place.phones.map((phone, i) => {
							return <a className="d-block" key={i}
							          href={'tel:' + phone}>{phone}</a>
						})}
					</p>
				</div>
			</div>
		}
	}

	renderingButtons(place) {
		const {t} = this.props;

		let phonesExist = place.phones.length;
		let coordinatesExist = place.coordinates.longitude ? true : !!(false && this.props.item.objects[0].coordinates.latitude);
		if (phonesExist && coordinatesExist) {
			return <div>
				<a className="btn default-button orange-btn left-btn"
				   href={'https://www.google.com/maps/place/' + place.coordinates.latitude + ',' + place.coordinates.longitude}>
					<i className="fas fa-map-marker-alt"/> {t("drive")}
				</a>
				<a className="btn default-button green-btn right-btn" href={'tel:' + place.phones[0]}>
					<i className="fas fa-phone"/> {t("call")}
				</a>
			</div>
		} else if (!phonesExist && coordinatesExist) {
			return <div>
				<a className="btn default-button orange-btn"
				   href={'https://www.google.com/maps/place/' + place.coordinates.latitude + ',' + place.coordinates.longitude}>
					<i className="fas fa-map-marker-alt"/> {t("drive")}
				</a>
				<a className="btn default-button green-btn hide-element" href={'tel:' + place.phones[0]}>
					<i className="fas fa-phone"/> {t("call")}
				</a>
			</div>
		} else if (phonesExist && !coordinatesExist) {
			return <Trans>
				<div>
					<a className="btn default-button orange-btn hide-element"
					   href={'https://www.google.com/maps/place/' + place.coordinates.latitude + ',' + place.coordinates.longitude}>
						<i className="fas fa-map-marker-alt"/> {t("drive")}
					</a>
					<a className="btn default-button green-btn" href={'tel:' + place.phones[0]}>
						<i className="fas fa-phone"/> {t("call")}
					</a>
				</div>
			</Trans>
		}
	}

	render() {
		const {t} = this.props;

		if (this.state.noData) {
			return <Redirect to="/menu"/>
		} else {
			return <div className="bg">
				<div className="header row no-gutters col-12">
					<div>
					<Link className="back d-flex" to={'/event/' + this.state.eventId}>
					<div className="arrow-header"><i className="fas fa-chevron-left"/></div>
					<div className="title-header"><h5>{t("otherFacilities")}</h5></div>
					</Link>
					</div>
					<Trans>
						<Link className="header-icon d-flex floating-button btn default-button" to={'/leki'}>
							<img src={require('../images/leki.svg')} alt="medicines"/>
							{t("myHealth")}
						</Link>
					</Trans>
				</div>
				<div className="page-content col-12">
					{this.state.otherFacilities[this.state.categoryId].objects.map((place, index) => {
						if (index === 0) {
							return <div className='no-pointer-events' key={index}>
								<div className="content-title border-radius-top">
									<p>{this.state.otherFacilities[this.state.categoryId].title2}</p>
								</div>
								<div key={index} className="no-pointer-events">
									<div className="page-div border-radius-bottom">
										<div className="bg-content" style={Style.background(place.background)}/>
										{this.renderImg(place)}
										{this.renderingButtons(place)}
									</div>
								</div>
							</div>
						} else {
							return <div key={index} className="no-pointer-events">
								<div className="page-div border-radius-all">
									<div className="bg-content" style={Style.background(place.background)}/>
									{this.renderImg(place)}
									{this.renderingButtons(place)}
								</div>
							</div>
						}
					})}
				</div>
			</div>
		}
	}
}

export default translate("translations")(Category);
