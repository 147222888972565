import axios from 'axios/index';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

import ClosestFacility from '../components/ClosestFacility';
import Contacts from '../components/Contacts';
import CustomSection from '../components/CustomSection';
import Emergency from '../components/Emergency';
import Form from '../components/Form';
import EventHeader from '../components/EventHeader';
import Intro from '../components/Intro';
import OtherFacilities from '../components/OtherFacilities';
import Translator from '../components/Translator';
import CurrencyCalculator from "../components/CurrencyCalculator";
import SpeechTranslator from "../components/SpeechTranslator";
import {translate} from "react-i18next";

class Event extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			data: {},
			backTo: '/menu',
			eventId: props.match.params.eventId,
			loaded: false,
			goHome: false,
			name: localStorage.getItem('name') || 'Uzytkownik',
			phone: localStorage.getItem('phone'),
			phone2: localStorage.getItem('phone2'),
			// internetConnection: props.location.state.internetConnection,
			offlineAccident: '',
			regions: localStorage.getItem('regions'),
			regionChosen: '',
			chooseRegion: false,
			fallPopup: ''
		};
		this.showPosition = this.showPosition.bind(this);
		this.redirectToHome = this.redirectToHome.bind(this);
	}

	showPosition(position) {
		this.setState({
			geolocationSuccess: true,
			// latitude: 52.435622525702065,
			// longitude: 13.357761883575904
			latitude: position.coords.latitude,
			longitude: position.coords.longitude
		});
		let categoryArray = [];
		// if (this.state.internetConnection) {
		axios.get(
			'https://panel.wurren.optize.pl/wurren/accident/' + this.state.eventId +
			'?latitude=' + this.state.latitude +
			'&longitude=' + this.state.longitude
		).then(res => res.data).then((accidentResult) => {
			axios.get('https://panel.wurren.optize.pl/wurren-api/objects?latitude=' + this.state.latitude + '&longitude=' + this.state.longitude).then(res => res.data).then((objectsResult) => {
				for (let key in accidentResult.data.attributes.taxonomy.kategorie) {
					if (accidentResult.data.attributes.taxonomy.kategorie.hasOwnProperty(key)) {
						categoryArray.push(key.substr(key.lastIndexOf('/') + 1, key.length).replace('-', '_'));
					}
				}

				const data = {
					name: accidentResult.data.attributes.title,
					procedure: accidentResult.data.attributes.accidentprocedure,
					contacts: [],
					translator: {
						title: accidentResult.data.attributes.translatepersontitle,
						name: accidentResult.data.attributes.translatepersonname,
						phone: accidentResult.data.attributes.translatepersonphone
					},
					customSection: {
						button: accidentResult.data.attributes.customsectionbuttonname,
						content: accidentResult.data.attributes.customsectioncontent,
						title: accidentResult.data.attributes.customsectiontitle,
						teaser: accidentResult.data.attributes.customsectionteaser,
					},
					form: accidentResult.data.attributes.formfile.url,
					categories: [],
					sections: [],
				};
				if (accidentResult.data.attributes.sms === '1') {
					if (localStorage.getItem('messageSend') === 'false') {
						localStorage.setItem('messageSend', 'true');

						if (this.state.phone && this.state.phone.length) {

							axios.get(
								'https://api.wurren.optize.pl/send?phone=' + this.state.phone + '&message=' + this.state.name + ' zglosil w aplikacji Help! zdarzenie "' + data.name + '". Jego lokalizacja to: https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
							).then(res => res.data).then((result) => {

							}, () => {

							});
						}
						if (this.state.phone2 && this.state.phone2.length) {

							axios.get(
								'https://api.wurren.optize.pl/send?phone=' + this.state.phone2 + '&message=' + this.state.name + ' zglosil w aplikacji Help! zdarzenie "' + data.name + '". Jego lokalizacja to: https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
							).then(res => res.data).then((result) => {

							}, () => {

							});
						}
					}
				}

				function objectData(objectResult, j) {
					return {
						name: objectsResult[j].title,
						address: (objectsResult[j].address !== '' && objectsResult[j].address !== ' ' ? (objectsResult[j].address + ', ') : '') +
							(objectsResult[j].postcode !== '' && objectsResult[j].postcode !== ' ' ? (objectsResult[j].postcode + ' ') : '') +
							objectsResult[j].city,
						coordinates: {
							latitude: objectsResult[j].coordinatelatitude,
							longitude: objectsResult[j].coordinatelongitude
						},
						phones: objectsResult[j].phone ? JSON.parse(objectsResult[j].phone) : [],
						image: objectsResult[j].icon ? 'https://panel.wurren.optize.pl/public/files/' + JSON.parse(objectsResult[j].icon).file : null
					};
				}

				function objectHasValue(obj, value) {
					for (let i in obj) {
						if (obj.hasOwnProperty(i)) {
							if (obj[i].toLowerCase() === value.toLowerCase()) {
								return true;
							}
						}
					}

					return false;
				}

				function getTitle(key) {
					return accidentResult.data.attributes.taxonomy.kategorie['/kategorie/' + key.replace('_', '-')];
				}

				for (let i in accidentResult.data.attributes.person) {
					if (accidentResult.data.attributes.person.hasOwnProperty(i)) {
						data.contacts.push({
							title: accidentResult.data.attributes.person[i].title,
							name: accidentResult.data.attributes.person[i].name,
							phone: accidentResult.data.attributes.person[i].phone,
							background: accidentResult.data.attributes.person[i].background.url
						});
					}
				}

				for (let i in accidentResult.data.attributes.sectionsapp) {
					if (accidentResult.data.attributes.sectionsapp.hasOwnProperty(i)) {
						data.sections.push({
							title: accidentResult.data.attributes.sectionsapp[i].sectiontitle,
						});
					}
				}

				for (let j in objectsResult) {
					if (objectsResult.hasOwnProperty(j)) {
						const currentObjectCategories = objectsResult[j].kategorie ? JSON.parse(objectsResult[j].kategorie) : [];

						for (let key in currentObjectCategories) {
							if (
								currentObjectCategories.hasOwnProperty(key) &&
								objectHasValue(categoryArray, currentObjectCategories[key])
							) {
								let categoryExists = false;

								for (let k in data.categories) {
									if (data.categories[k].title === currentObjectCategories[key]) {
										categoryExists = true;

										data.categories[k].objects.push(objectData(objectsResult, j));

										break;
									}
								}

								if (!categoryExists) {
									data.categories.push({
										title: currentObjectCategories[key],
										title2: getTitle(currentObjectCategories[key]),
										objects: [objectData(objectsResult, j)]
									});
								}
							}
						}
					}
				}

				this.setState({
					data,
					loaded: true
				});
			}, () => {

			});
		}, () => {

		});
		// } else {
		//     //no internet connection
		//    this.readFile()
		// }
	}

	newData(offlineAccident, offlineObject) {

		function extractPhones(phones) {
			const result = [];

			for (let i in phones) {
				if (phones.hasOwnProperty(i)) {
					result.push(phones[i].phone);
				}
			}
			return result;
		}

		function objectData(offlineObject, j) {

			return {
				name: offlineObject.data[j].attributes.title,
				address: offlineObject.data[j].attributes.address + ', ' +
					offlineObject.data[j].attributes.postcode + ' ' +
					offlineObject.data[j].attributes.city,
				coordinates: {
					latitude: offlineObject.data[j].attributes.coordinatelatitude,
					longitude: offlineObject.data[j].attributes.coordinatelongitude
				},
				phones: extractPhones(offlineObject.data[j].attributes.phone),
				image: offlineObject.data[j].attributes.icon.url
			};
		}

		function objectHasValue(obj, value) {
			for (let i in obj) {
				if (obj.hasOwnProperty(i)) {
					if (obj[i].toLowerCase() === value.toLowerCase()) {
						return true;
					}
				}
			}

			return false;
		}

		if (Object.keys(offlineAccident).length && Object.keys(offlineObject).length) {
			this.setState({
				loaded: false
			});
			const data = {
				name: offlineAccident.attributes.title,
				procedure: offlineAccident.attributes.accidentprocedure,
				contacts: [],
				translator: {
					title: offlineAccident.attributes.translatepersontitle,
					name: offlineAccident.attributes.translatepersonname,
					phone: offlineAccident.attributes.translatepersonphone
				},
				form: offlineAccident.attributes.formfile.url,
				categories: []
			};
			//extractPhones(phones);

			// objectData(offlineObject, j);

			//objectHasValue(obj, value);

			for (let i in offlineAccident.attributes.person) {
				if (offlineAccident.attributes.person.hasOwnProperty(i)) {
					data.contacts.push({
						title: offlineAccident.attributes.person[i].title,
						name: offlineAccident.attributes.person[i].name,
						phone: offlineAccident.attributes.person[i].phone,
						background: offlineAccident.attributes.person[i].background.url
					});
				}
			}
			for (let j in offlineObject.data) {

				if (offlineObject.data.hasOwnProperty(j)) {

					const currentObjectCategories = offlineObject.data[j].attributes.kategorie;
					for (let key in currentObjectCategories) {
						if (
							currentObjectCategories.hasOwnProperty(key) &&
							objectHasValue(offlineAccident.attributes.kategorie, currentObjectCategories[key])
						) {
							let categoryExists = false;
							for (let k in data.categories) {
								if (data.categories[k].title === currentObjectCategories[key]) {
									categoryExists = true;

									data.categories[k].objects.push(objectData(offlineObject, j));

									break;
								}
							}
							if (!categoryExists) {
								data.categories.push({
									title: currentObjectCategories[key],
									objects: [objectData(offlineObject, j)]
								});
							}
						}
					}
				}
			}
			this.setState({
				data,
				loaded: true
			});
		}
	}

	readFile = () => {

		let type = window.PERSISTENT;
		let size = 5 * 1024 * 1024;
		let offlineAccident = {};
		window.requestFileSystem(type, size, (fs) => {
			fs.root.getFile('accident.txt', {}, (fileEntry) => {
				fileEntry.file((file) => {
					let reader = new FileReader();
					reader.onloadend = (e) => {
						let dataResult = reader.result;
						let offlineData = JSON.parse(dataResult);
						for (let i in offlineData.data) {
							if (offlineData.data[i].id === this.props.match.params.eventId) {
								offlineAccident = offlineData.data[i];
							}
						}
					};
					reader.readAsText(file);

				}, () => {

				});
				window.requestFileSystem(type, size, (fs) => {
					fs.root.getFile('object.txt', {}, (fileEntry) => {
						fileEntry.file((file) => {
							let reader = new FileReader();
							reader.onloadend = () => {
								let dataResult = reader.result;

								let offlineObject = JSON.parse(dataResult);
								this.newData(offlineAccident, offlineObject);
							};
							reader.readAsText(file);
						}, () => {

						});
					}, () => {

					});
				}, () => {

				});
			}, () => {

			});
		}, () => {
			alert("Błąd. Aplikacja nie może działać w trybie offline. Potrzebne dane nie zostały wcześniej zapisane.");
		});

	};

	componentDidMount() {
		if (this.isCordova) {
			document.addEventListener("online", this.refreshAfterConnention, false);
			document.addEventListener("offline", this.noInternetConnention, false);
			if (navigator.connection.type !== 'none') {
				this.setState({
					internetConnection: true
				})
			} else {
				this.setState({
					internetConnection: false
				})
			}
		}

		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.showPosition, () => {
				navigator.geolocation.getCurrentPosition(this.showPosition, () => {
					this.setState({
						geolocationSuccess: false,
						loaded: true
					});
				}, {
					maximumAge: 120000,
					timeout: 60000,
					enableHighAccuracy: true
				});
			}, {
				maximumAge: 30000,
				timeout: 5000,
				enableHighAccuracy: false
			});
		} else {
			this.setState({
				geolocationSuccess: false,
				loaded: true
			});
		}
	}

	redirectToHome() {
		this.setState({
			goHome: true
		});
	}

	renderPopup() {
		const {t} = this.props;

		return (
			<div className="popup" id="errorPopup">
				<h6 className="modal-title" id="errorModal">{t('geolocationError')}</h6>
				<button className="btn default-button full-width-button"
				        onClick={this.redirectToHome}>{t('close')}</button>
			</div>
		);
	}

	clearCounting = () => {
		localStorage.setItem('fallDetected', 'false');
		this.setState({
			fallPopup: false
		}, () => {
			this.fallWatch();
		});
		navigator.vibrate(0)
	};

	reloadWatcher = () => {
		localStorage.setItem('fallDetected', 'false');
		this.setState({
			fallPopup: false,
			smsSent: false
		}, () => {
			this.fallWatch();
		});
	};

	render() {
		const {t} = this.props;

		if (this.state.goHome) {
			return <Redirect to="/menu"/>
		}

		if (this.state.loaded) {
			if (this.state.geolocationSuccess) {

				return <div className="bg">
					<EventHeader header={this.state.data.name} backTo={this.state.backTo}/>
					<div className="page-content col-12">
						{this.state.data.sections.map((title, i) => {
							if (title.title === "PROCEDURA") {
								return <Intro header={this.state.data.name} eventId={this.state.eventId} key={i}
								              procedure={this.state.data.procedure}/>
							}
							if (title.title === "TŁUMACZENIE MOWY") {
								return <SpeechTranslator key={i} />
							}
							if (title.title === "PLACÓWKA NAJBLIŻEJ CIEBIE") {
								return <ClosestFacility categories={this.state.data.categories} key={i}
								                        eventId={this.state.eventId}/>
							}
							if (title.title === "ZADZWOŃ POD 112") {
								return <Emergency key={i}/>
							}
							if (title.title === "PLACÓWKI") {
								return <OtherFacilities categories={this.state.data.categories} key={i}
								                        eventId={this.state.eventId}/>
							}
							if (title.title === "NIESTANDARDOWA SEKCJA") {
								return <CustomSection custom={this.state.data.customSection} key={i}
								                      eventId={this.state.eventId}/>
							}
							if (title.title === "KALKULATOR WALUT") {
								return <CurrencyCalculator  key={i}/>
							}
							if (title.title === "FORMULARZE") {
								return <Form form={this.state.data.form} key={i}/>
							}
							if (title.title === "KONTAKT DO TŁUMACZA") {
								return <Translator translator={this.state.data.translator} key={i}/>
							}
							if (title.title === "KONTAKT DO OSÓB") {
								return <Contacts contacts={this.state.data.contacts} key={i}/>
							}
							if (title.title === "ODZYSKAJ DOKUMENTY") {
								return <div className="page-div"  key={i}>
									<div className="bg-content"
									     style={{backgroundImage: 'url(' + require('../images/formularze.svg') + ')'}}/>
									<p className="margin-bottom-20"><strong>{t('recoverDocuments')}</strong></p>
									<p>{t('recoverDocumentsInfo')}</p>
									<a className="btn default-button margin-top-10" rel="noopener noreferrer"
									   href='https://panel.wurren.optize.pl/files/dokumenty.pdf'
									   target="_blank">{t('downloadCopy')}</a>
								</div>
							}
						})
						}
					</div>
				</div>

			} else {
				return <div className="bg">
					{this.renderPopup()}
				</div>
			}
		} else {
			return <div className="bg">
				<div className="centering">
					<p className="center text-center">
						<img src={require('../images/logo.png')} alt="" className="logo-in-spinner"/>
						<i className="fas fa-spinner"/> {t('loading')}
					</p>
				</div>
			</div>
		}
	}
}

export default translate("translations")(Event);
