import React, {Component} from 'react';

import Facility from '../components/Facility';

export default class OtherFacilities extends Component {
	render() {
		return this.props.categories.map((category, index) => {
			if (0 === index) {
				return false;
			}
			return <Facility key={index} item={this.props.categories[index]} categories={this.props.categories}
							 eventId={this.props.eventId} index={index}/>
		})

	}
}
