import React, {Component} from 'react';
import {translate} from "react-i18next";

class SpeechRecognitionWebNotChrome extends Component {
	render() {
		const {t} = this.props;

		return <div>
			<div className="bg-content" style={{backgroundImage: 'url(' + require('../images/tlumacz.svg') + ')'}}/>
			<p>{t('chrome')}</p>
		</div>
	}
}
export default translate("translations")(SpeechRecognitionWebNotChrome);
