import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import axios from "axios";
import {translate} from "react-i18next";
import Swipe from 'react-easy-swipe';
import FallDetector from '../components/FallDetector';

const addr = 'https://api.helphelptrip.com/api/1.0';

class EventHeader extends Component {

	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.state = {
			guestMode: localStorage.getItem('guest'),
			loggedOut: false
		};
	}

	logOut = () => {
		axios.post(addr + '/logout', null, {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			},

		})
			.then((response) => {
				this.setState({
					loggedIn: false
				}, () => {
					localStorage.removeItem('token');
					localStorage.removeItem('user');
					this.setState({
						loggedOut: true
					})
				});
			})
			.catch((error) => {
				if (error.response.status === 403 && error.response.data.password_change) {
					this.setState({
						forbidden: true,
						forbiddenError: error.response.data.password_change
					});
				}
			});
	};

	hideFloatingButton() {
		const {t} = this.props;

		if (typeof this.props.hideBtn === 'undefined') {
			return <Link className="header-icon d-flex floating-button btn default-button" to={'/zdrowie'}>
				<img src={require('../images/leki.svg')} alt="medicines"/>
				{t("myHealth")}
			</Link>
		}
	}

	// logoutOptions = () => {
	// 	if (this.state.guestMode === 'true') {
	// 		return <Link className="header-icon d-flex pointer-cursor" to={'/'}>
	// 			<i className="bigger-icon fas fa-sign-in-alt"/>
	// 		</Link>
	// 	} else {
	// 		return <button className="header-icon d-flex pointer-cursor" onClick={this.logOut}>
	// 			<i className="bigger-icon fas fa-sign-out-alt"/>
	// 		</button>
	// 	}
	// };

	closePopup = () => {
		this.setState({
			forbidden: false
		})
	};

	openSidebar = () => {
		this.setState({
				sidebarOpen: true
			}
		);
	};

	onSwipeEnd = () => {
		this.openSidebar()
	};

	closeSidebar = () => {

		this.setState({
			sidebarOpen: false
		})

	};
	downloadApp = () => {
		const {t} = this.props;

		if (!this.isCordova) {
			return <div>
				<hr/>
				<ul>
					<li>
						<a className="back d-flex" href="https://helphelptrip.com/android.apk">
							<i className='fas fa-download icon-box'/>
							<p><b> {t("downloadApk")}</b></p>
						</a>
					</li>
				</ul>
			</div>
		}
	};
	moveToRegister = () => {
		navigator.accelerometer.clearWatch(localStorage.getItem('watchID'));

		this.setState({
			register: true
		});

	};

	guestLogOut = () => {
		navigator.accelerometer.clearWatch(localStorage.getItem('watchID'));

		this.setState({
			moveToLogin: true
		});
	};

	onSwipeRight = () => {
		this.setState({
				sidebarOpen: true
			}
		);
	};

	onSwipeLeft = () => {
		this.setState({
			sidebarOpen: false
		})
	};


	sidebarShow() {
		const {t} = this.props;


		if (this.state.sidebarOpen === true) {
			if (this.state.register) {
				return <Redirect to={{
					pathname: '/',
					state: {
						register: this.state.register,
					}
				}}/>
			}
			if (this.state.moveToLogin) {
				return <Redirect to={{
					pathname: '/',

				}}/>
			}
			if (this.state.loggedOut) {
				return <Redirect to={{
				pathname: '/'
				}}/>
			}
			if (this.state.guestMode === 'false') {
				return <div>
					<div className="overlay" onClick={this.closeSidebar}>
					</div>
					<Swipe onSwipeLeft={this.onSwipeLeft}
					>
						<div className='sidebar-box'>
							<div className='sidebar-header'>
								<Link to={'/lang'}>
									<div className='round-flag'>
										<img className='help-logo'
										     src={require('../images/' + localStorage.getItem('i18nextLng') + '.svg')}
										     alt='help-logo'/>
									</div>

								</Link>
								<div className='user-info'>
									<p><b>Twój adres e-mail</b></p>
									<p>{this.state.user}</p>
								</div>
							</div>
							<div className='sidebar-options'>
								<ul>
									<li>
										<Link className="header-icon d-flex" to={'/konto'}>

											<i className='fas fa-user-edit icon-box'/>
											<p><b>{t("myAccount")}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/zdrowie'}>

											<div className='icon-box'>
												<img src={require('../images/leki.svg')} alt="settings"/>
											</div>
											<p><b>{t("myHealth")}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/storage'}>

											<i className='fas fa-folder-open icon-box'/>
											<p><b>{t("storage")}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/ustawienia'}>
											<div className='icon-box'>
												<img src={require('../images/ustawienia.svg')} alt="settings"/>
											</div>
											<p><b>{t("settings")}</b></p>
										</Link>

									</li>
									<li>
										<button className='sidebar-button' onClick={this.logOut}>
											<i className='fas fa-sign-out-alt icon-box'/>
											<p><b>{t("logOut")}</b></p>
										</button>
									</li>

								</ul>
								{this.downloadApp()}
							</div>

						</div>
					</Swipe>

				</div>

			} else {
				return <div>
					<div className="overlay" onClick={this.closeSidebar}>
					</div>
					<Swipe onSwipeLeft={this.onSwipeLeft}>

						<div className='sidebar-box'>
							<div className='sidebar-header'>
								<Link to={'/lang'}>
									<div className='round-flag'>
										<img className='help-logo'
										     src={require('../images/' + localStorage.getItem('i18nextLng') + '.svg')}
										     alt='help-logo'/>
									</div>

								</Link>
								<div className='user-info'>
									<p>{t("guestMode")}</p>

								</div>
							</div>
							<div className='sidebar-options'>
								<ul>
									<li>
										<Link className="header-icon d-flex" to={'/konto'}>

											<i className='fas fa-user-edit icon-box'/>
											<p><b>{t("myAccount")}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/zdrowie'}>

											<div className='icon-box'>
												<img src={require('../images/leki.svg')} alt="settings"/>
											</div>
											<p><b>{t("myHealth")}</b></p>
										</Link>
									</li>

									<li>
										<Link className="header-icon d-flex" to={'/ustawienia'}>
											<div className='icon-box'>
												<img src={require('../images/ustawienia.svg')} alt="settings"/>
											</div>
											<p><b>{t("settings")}</b></p>
										</Link>

									</li>

								</ul>
								<hr/>
								<ul>
									<li>
										<button className='sidebar-button' onClick={this.guestLogOut}>
											<i className='fas fa-sign-in-alt icon-box'/>
											<p><b>{t("loginSidebar")}</b></p>
										</button>
									</li>
									<li>
										<button className='sidebar-button' onClick={this.moveToRegister}>
											<i className='fas fa-edit icon-box'/>
											<p><b>{t("register2")}</b></p>
										</button>
									</li>
								</ul>

								{this.downloadApp()}
							</div>

						</div>
					</Swipe>
				</div>
			}

		}

	};

	swipeArea = () => {
		const {t} = this.props;

		if (this.props.header === t('langChange') || this.props.header === t('storage') ) {
		return <div className='swipe-area-narrow'>
		</div>
	} else {
		return <div className='swipe-area'>
		</div>
	}
	};

	render() {
		const {t} = this.props;

		if (this.state.forbidden) {
			if (this.props.header !== t('settings')) {
				return <div>
					<div className="overlay">
					</div>
					<div id="savePopup">
						<div className="popup-title"><p>{t("error")}</p></div>
						<div className="popup">
							<p>{this.state.forbiddenError}</p>
							<Link className="btn default-button" to={'/ustawienia'}>{t("passwordChange")}</Link>
						</div>
					</div>
				</div>
			} else {
				return <div>
					<div className="overlay">
					</div>
					<div id="savePopup">
						<div className="popup-title"><p>{t("error")}</p></div>
						<div className="popup">
							<p>{this.state.forbiddenError}</p>
							<button className="btn default-button" onClick={this.closePopup}>{t("ok")}</button>
						</div>
					</div>
				</div>
			}
		}

		return <div>
			<Swipe onSwipeRight={this.onSwipeRight}>
				{this.swipeArea()}
			</Swipe>
			{this.sidebarShow()}
			<div className="header row no-gutters col-12">
				<div>
					<Link className="back d-flex" to={'/menu'}>
						<div className="arrow-header"><i className="fas fa-chevron-left"/></div>
						<div className="title-header "><h5 className='truncate'>{this.props.header}</h5></div>
					</Link>
				</div>
				{this.hideFloatingButton()}
			</div>
			<FallDetector title={this.props.header}/>
		</div>
	}
}

export default translate("translations")(EventHeader);
