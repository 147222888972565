import React, {Component} from 'react';
import EventHeader from './../components/EventHeader'
import {Redirect, Link} from 'react-router-dom';
import {translate} from "react-i18next";
import Sound from "react-sound";
import ReactCountdownClock from 'react-countdown-clock'
import axios from 'axios/index';
import ReactSpeedometer from 'react-d3-speedometer'

class Settings extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			goHome: false,
			fallDetector: localStorage.getItem('fallDetector'),
			ready: false,
			sound: localStorage.getItem('sound'),
			lang: localStorage.getItem('i18nextLng'),
			langName: '',
			stopped: false,
			watchID: localStorage.getItem('watchID'),
			smv: localStorage.getItem('fallDetectorSmv'),
			phone2: localStorage.getItem('phone2'),
			phone: localStorage.getItem('phone'),
			name: localStorage.getItem('name') || 'Użytkownik',
		};
	}

	componentDidMount() {
		const {t} = this.props;

		if (localStorage.getItem('sound') === null) {
			localStorage.setItem('sound', 'many');
		}
		if (localStorage.getItem('phone') === null) {
			localStorage.setItem('phone', '');
		}
		if (localStorage.getItem('phone2') === null) {
			localStorage.setItem('phone2', '');
		}
		if (localStorage.getItem('fallDetector') === null) {
			localStorage.setItem('fallDetector', '0');
		}
		if (localStorage.getItem('fallDetectorSmv') === null) {
			localStorage.setItem('fallDetectorSmv', '21');
			this.setState({
				smv: 21
			})
		}
		if (localStorage.getItem('sound') === null) {
			localStorage.setItem('sound', 'many');

		}
		this.setState({
			ready: true
		});
		if (this.state.watchID === undefined) {
			localStorage.setItem('watchID', null);
		}
		if (this.state.fallDetector === "0") {
			localStorage.setItem('watchID', null);
		}
		if (this.isCordova && this.state.fallDetector === "1") {
			document.addEventListener("deviceready", () => {
				//window.cordova.plugins.backgroundMode.overrideBackButton();
			}, false);
			// window.cordova.plugins.backgroundMode.setDefaults({
			// 	title: t('apkBackground'),
			// 	// text: 'Aby powrócić do aplikacji kliknij tutaj.',
			// 	text:  t('watchOverloads'),
			// });
			// window.cordova.plugins.backgroundMode.enable();
			// window.cordova.plugins.backgroundMode.excludeFromTaskList();
			this.fallWatch();
		}
	}

	getFallDetectorValue = (e) => {
		if (this.state.phone !== '' || this.state.phone2 !== '') {
			if (localStorage.getItem('fallDetector') === 'null') {
				localStorage.setItem('fallDetector', '0');
			} else if (localStorage.getItem('fallDetector') === '0') {
				localStorage.setItem('fallDetector', '1');
			} else if (localStorage.getItem('fallDetector') === '1') {
				localStorage.setItem('fallDetector', '0');
			}
		} else {
			localStorage.setItem('fallDetector', '0');
		}
	};

	fallDetectorOff = () => {
		if (navigator.accelerometer !== undefined) {
			navigator.accelerometer.clearWatch(localStorage.getItem('watchID'));
		}

		if (localStorage.getItem('fallDetector') === '0') {
			localStorage.setItem('fallDetector', '1');

			this.setState({
				fallDetector: '1'
			});

			this.fallWatch()
		} else {
			navigator.accelerometer.clearWatch(localStorage.getItem('watchID'));
			localStorage.setItem('fallDetector', '0');

			this.setState({
				fallDetector: '0'
			})
		}
	};

	soundOnOff = () => {
		if (localStorage.getItem('sound') === 'many') {
			localStorage.setItem('sound', 'single');
			this.setState({
				sound: 'single'
			})
		} else {
			localStorage.setItem('sound', 'many');
			this.setState({
				sound: 'many'
			})
		}
	};

	fallWatch = () => {
		if (this.state.watchID !== null) {
			navigator.accelerometer.clearWatch(this.state.watchID);
		}
		if (this.state.watchID === undefined) {
			localStorage.setItem('watchID', null);
		}

		let watchID = navigator.accelerometer.watchAcceleration((acceleration) => {

			this.setState({
				watchID: watchID
			}, () => {
				this.setState({
					watchID: watchID
				});
				if (this.state.watchID !== null && !this.state.watchIDSaved) {
					localStorage.setItem('watchID', this.state.watchID);
					this.setState({
						watchIDSaved: true
					});
				}
			});

			let sMV = 21.5,
				accX = null,
				accY = null,
				accZ = null,
				signalMagnitudeVector = null;
			accX = Math.pow(Math.abs(acceleration.x), 2);
			accY = Math.pow(Math.abs(acceleration.y), 2);
			accZ = Math.pow(Math.abs(acceleration.z), 2);
			signalMagnitudeVector = Math.sqrt(accX + accY + accZ);
			if (signalMagnitudeVector >= sMV && this.state.fallDetector === "1" && localStorage.getItem('watchID') !== undefined) {
				localStorage.setItem('fallDetected', 'true');
				navigator.accelerometer.clearWatch(this.state.watchID);
				this.setState({
					fallPopup: true,
				}, () => {
					window.cordova.plugins.notification.local.setDefaults({
						vibrate: true
					});
					window.cordova.plugins.notification.local.schedule({
						title: 'Wykryto upadek!',
						text: 'Po upływie 30 sekund zostanie wysłany SMS.',
						foreground: true
					});
				});
			}
		}, () => {
			alert('onError!');
		}, {frequency: 500});

	};

	getValue = (e) => {
		const {t} = this.props;

		localStorage.setItem('name', this.name.current.value);

		if (this.state.phone.length === 9 || this.state.phone === '') {
			localStorage.setItem('phone', this.state.phone);
			this.setState({
				errorPhone: ''
			})
		} else {
			this.setState({
				errorPhone: t('errorPhone')
			})
		}

		if (this.state.phone2.length === 9 || this.state.phone2 === '') {
			localStorage.setItem('phone2', this.state.phone2);
			this.setState({
				errorPhone2: ''
			})
		} else {
			this.setState({
				errorPhone2: t('errorPhone')
			})
		}
	};

	switchButton = () => {

		return <div className="onoffswitch">
			<input type="checkbox" name="fallDetector" className="onoffswitch-checkbox"
			       id="myonoffswitch" onChange={this.switchButton} checked={this.state.fallDetector === '1'}/>
			<label className="onoffswitch-label" htmlFor="myonoffswitch">
							<span className="onoffswitch-switch">
									</span>
				<span className="onoffswitch-inner" onClick={this.fallDetectorOff}>
									</span>

			</label>
		</div>
	};

	closePopup = () => {
		this.setState({
			passwordChangeSuccess: false
		});
	};

	voiceSetting = () => {
		const {t} = this.props;

		if (this.isCordova) {
			if (this.state.fallDetector === '1') {
				return <div className="page-div border-radius-all sound-div">
					<p><strong>{t('fallPopupSound')}</strong></p>
					<hr/>
					<div className="fall-detector">
						<p>{t('fallPopupSoundInfo')}<br/> {t('fallPopupSoundInfo2')}<b>{t('fallPopupSoundInfo3')}</b> {t('fallPopupSoundInfo4')} <b> {t('fallPopupSoundInfo5')}</b> {t('fallPopupSoundInfo6')}</p>
					</div>
					<div className="toggle">
						<input type="radio" name="sound" value="single" id="sizeWeight" onClick={this.soundOnOff}
						       checked={this.state.sound === 'single'}/>
						<label htmlFor="sizeWeight">{t('singleSound')} <br/>{t('singleSound2')}</label>
						<input type="radio" name="sound" value="many" id="sizeDimensions"
						       checked={this.state.sound === 'many'}
						       onClick={this.soundOnOff}/>
						<label htmlFor="sizeDimensions">{t('manySound')} <br/>{t('manySound2')}</label>
					</div>
				</div>
			}
		}
	};
	onComplete = () => {
		const {t} = this.props;

		// this.setState({
		// 	smsSent: true
		// });
		if (this.state.phone && this.state.phone2) {

			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone + '&message=' + this.state.name + t('smsContentFall') + 'https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({});
			}, () => {
			});

			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone2 + '&message=' + this.state.name + t('smsContentFall') + 'https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({
					smsSent: true
				});
			}, () => {
			});

		} else if (this.state.phone && !this.state.phone2) {

			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone + '&message=' + this.state.name + t('smsContentFall') + 'https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({
					smsSent: true
				});
			}, () => {
			});

		} else if (!this.state.phone && this.state.phone2) {
			axios.get(
				'https://api.wurren.optize.pl/send?phone=' + this.state.phone2 + '&message=' + this.state.name + t('smsContentFall') + 'https://www.google.com/maps/place/' + this.state.latitude + ',' + this.state.longitude
			).then(res => res.data).then((result) => {
				this.setState({
					smsSent: true
				});
			}, () => {
			});
		}
	};

	clearCounting = () => {
		localStorage.setItem('fallDetected', 'false');
		this.setState({
			fallPopup: false,
			changeSmv: true
		});
		navigator.vibrate(0)
	};

	reloadWatcher = () => {
		localStorage.setItem('fallDetected', 'false');
		this.setState({
			fallPopup: false,
			smsSent: false,
			changeSmv: false
		}, () => {
			this.fallWatch();
		});
	};

	playSound = () => {
		if (this.state.stopped === false) {
			if (this.state.sound === 'many') {
				return <Sound
					url="../www/static/media/beep-01a.238acd50.mp3"
					loop={true}
					playStatus={Sound.status.PLAYING}
				/>
			} else {
				return <Sound
					url="../www/static/media/beep-01a.238acd50.mp3"
					playStatus={Sound.status.PLAYING}
				/>
			}
		} else if (this.state.stopped === true) {
			return <Sound
				url="../www/static/media/beep-01a.238acd50.mp3"
				playStatus={Sound.status.STOPPED}
			/>
		}
	};

	clearSound = () => {
		this.setState({
			stopped: true
		});
		navigator.vibrate(0)
	};

	buttonMute = () => {
		const {t} = this.props;

		if (this.state.sound === 'many') {
			return <button className="btn default-button full-width-button margin-top-10"
			               onClick={this.clearSound}>{t('mute')}
			</button>
		}
	};

	fallPopupClock = () => {
		const {t} = this.props;

		if (this.state.fallPopup && !this.state.smsSent && !this.state.changeSmv) {
			navigator.vibrate([500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500, 500]);
			return <div>
				<div className="overlay-falldetector">
					<div id="fallPopup">
						<div className="popup-title"><p>{t("fallDetected")}</p></div>
						<div className="popup">
							<h6 className="modal-title"
							    id="errorModal">{t("fallDetectedInfo")}<br/> {t("fallDetectedInfo2")}
							</h6>
							<div className="countdown-clock">
								<ReactCountdownClock seconds={30}
								                     color="#ffd600"
								                     alpha={0.9}
								                     size={150}
								                     showMilliseconds={false}
								                     onComplete={this.onComplete}
								/>
								{this.playSound()}
							</div>
							<button className="btn default-button full-width-button margin-top-10"
							        onClick={this.clearCounting}>{t("cancel")}</button>
							{this.buttonMute()}
						</div>
					</div>
				</div>
			</div>
		}
	};

	smsSentSuccess = () => {
		const {t} = this.props;

		if (this.state.fallPopup && this.state.smsSent && !this.state.changeSmv) {
			return <div className="overlay-falldetector">
				<div id="fallPopup">
					<div className="popup-title"><p>{t("smsSent")}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal">{t("waitForHelp")} </h6>
						<button className="btn default-button full-width-button"
						        onClick={this.reloadWatcher}>{t("ok")}</button>
					</div>
				</div>
			</div>
		}
	};

	changeSmvSensitivity = () => {
		const {t} = this.props;

		if (!this.state.fallPopup && !this.state.smsSent && this.state.changeSmv) {
			return <div className="overlay-falldetector">
				<div id="fallPopup">
					<div className="popup-title"><p>{t('canceled')}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal">{t('sendSmsCanceled')} <br/> {t('changeSensitivityPopup')}</h6>
						{this.reactSpeedometer()}
						<button className="btn default-button full-width-button"
						        onClick={this.reloadWatcher}>{t("ok")}</button>
					</div>
				</div>
			</div>
		}
	};

	set25 = () => {
		this.setState({
			smv: 24.6
		});
		localStorage.setItem('fallDetectorSmv', '24.6')

	};
	set23 = () => {
		this.setState({
			smv: 23.3
		});
		localStorage.setItem('fallDetectorSmv', '23.3')

	};
	set22 = () => {
		this.setState({
			smv: 22
		});
		localStorage.setItem('fallDetectorSmv', '22')

	};
	set21 = () => {
		this.setState({
			smv: 21.0
		});
		localStorage.setItem('fallDetectorSmv', '21')

	};
	set20 = () => {
		this.setState({
			smv: 19.7
		});
		localStorage.setItem('fallDetectorSmv', '19.7')

	};
	set19 = () => {
		this.setState({
			smv: 18.6
		});
		localStorage.setItem('fallDetectorSmv', '18.6')
	};
	set17 = () => {
		this.setState({
			smv: 17.5
		});
		localStorage.setItem('fallDetectorSmv', '17.5')
	};
	getFallDetectorSmv = () => {
		localStorage.setItem('fallDetectorSmv', this.state.smv);
	};

	setDefaultButton = () => {
		const {t} = this.props;

		if (localStorage.getItem('fallDetectorSmv') !== '21') {
			return <button className="btn default-button margin-bottom-10 full-width-button"
			               onClick={this.getDefaultSmv}>{t('restoreDefaults')}
			</button>
		}
	};

	reactSpeedometer = () => {

		return <div className='d-flex justify-content-center '>

			<div className='main-speedometer'>
				<ReactSpeedometer

					minValue={17}
					maxValue={25}
					value={this.state.smv}
					needleColor="steelblue"
					textColor="white"
					width={260}
					height={160}
					startColor='transparent'
					endColor='transparent'
					segments={7}
				/>
			</div>
			<div className='dots-speedometer'
			>
				<img src={require('../images/dots.svg')} alt=''/>
			</div>
			<div className='value-setting'>

				<svg className="speedometer" width="260" height="160">
					<g className="arc-transparent" transform="translate(130,130)">
						<path className="speedo-segment" fill="transparent" onClick={this.set17}
						      d="M-110,-1.3471114790620885e-14A110,110,0,0,1,-99.10657546926609,-47.72721130293141L-45.048443395120955,-21.69418695587791A50,50,0,0,0,-50,-6.123233995736766e-15Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set19}
						      d="M-99.10657546926609,-47.72721130293141A110,110,0,0,1,-68.58387820446069,-86.00146307148329L-31.174490092936676,-39.09157412340149A50,50,0,0,0,-45.048443395120955,-21.69418695587791Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set20}
						      d="M-68.58387820446069,-86.00146307148329A110,110,0,0,1,-24.477302735194577,-107.2420703400006L-11.126046697815717,-48.74639560909118A50,50,0,0,0,-31.174490092936676,-39.09157412340149Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set21}
						      d="M-24.477302735194577,-107.2420703400006A110,110,0,0,1,24.477302735194566,-107.2420703400006L11.126046697815712,-48.74639560909118A50,50,0,0,0,-11.126046697815717,-48.74639560909118Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set22}
						      d="M24.477302735194566,-107.2420703400006A110,110,0,0,1,68.58387820446067,-86.00146307148329L31.17449009293667,-39.09157412340149A50,50,0,0,0,11.126046697815712,-48.74639560909118Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set23}
						      d="M68.58387820446067,-86.00146307148329A110,110,0,0,1,99.10657546926609,-47.72721130293141L45.048443395120955,-21.694186955877914A50,50,0,0,0,31.17449009293667,-39.09157412340149Z">
						</path>
						<path className="speedo-segment" fill="transparent" onClick={this.set25}
						      d="M99.10657546926609,-47.72721130293141A110,110,0,0,1,110,0L50,0A50,50,0,0,0,45.048443395120955,-21.694186955877914Z">
						</path>
					</g>
				</svg>

			</div>
		</div>

	};

	sensitivityChange = () => {
		const {t} = this.props;

		if (this.state.fallDetector === '1') {
			return <div className="page-div border-radius-all ontop">
				<div className='switch-in-header'>
					<p><strong>{t('sensitivityAdjusment')}</strong></p>
				</div>
				<hr/>
				<div className="fall-detector">
					<p>{t('sensitivityAdjusment2')}</p>
				</div>
				{this.reactSpeedometer()}
				{this.setDefaultButton()}
				<button className="btn default-button full-width-button"
				        onClick={this.setSmv}>{t('advanced')}
				</button>
				{this.smvOption()}
				<div className="clearfix margin-bottom-10"/>

			</div>
		}
	};

	fallDetectorSettings = () => {
		const {t} = this.props;
		if (this.isCordova) {
			if (localStorage.getItem('phone') !== '' || localStorage.getItem('phone2') !== '') {
				return <div className='bottom-div'>
					<div className="page-div border-radius-all ontop">
						<div className='switch-in-header'>
							<p><strong>{t('overloadDetection')}</strong></p>
							{this.switchButton()}
						</div>
						<hr/>
						<div className="fall-detector">
							<p>{t('overloadDetectionInfo2')}</p>
						</div>

						<div className="clearfix margin-bottom-10"/>

					</div>
					{this.sensitivityChange()}
				</div>

			} else {
				return <div className="page-div border-radius-all">
					<p><strong>{t('overloadDetection')}</strong></p>
					<hr/>
					<div className="fall-detector">
						<p className='need-phone-number'>{t('overloadDetectionPhone')} <Link
							to={'/konto'}><b>{t('myAccount')}.</b></Link></p>

					</div>
					<Link className="btn default-button full-width-button margin-top-10"
					      to={('/konto')}>{t('myAccount')}</Link>
					<div className="clearfix margin-bottom-10"/>
				</div>
				// return <div className="page-div border-radius-all">
				// 	<p><strong>Wykrywanie upadku</strong></p>
				// 	<hr/>
				// 	<div className="fall-detector">
				// 		<p>Do prawidłowego działania aplikacji niezbędne jest podanie co najmniej jednego numeru
				// 			telefonu.</p>
				//
				// 	</div>
				// 	<div className="clearfix margin-bottom-10"/>
				// </div>
			}
		} else {
			return <div className="page-div border-radius-all">
				<p><strong>{t('overloadDetection')}</strong></p>
				<hr/>
				<div className="fall-detector">
					<p>{t('overloadDetectionApk')}</p>
				</div>
			</div>
		}
	};
	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	setSmv = () => {
		this.setState({
			smvSetting: true
		})
	};

	getDefaultSmv = () => {
		localStorage.setItem('fallDetectorSmv', '21');
		this.setState({
			smv: 21.0
		});
	};

	smvOption = () => {
		const {t} = this.props;
		if (this.state.smvSetting) {
			return <div className="page-div border-radius-all sensitivity">

				<div className="fall-detector">
					<p>{t('chooseSensitivity')}</p>
					<select className="margin-top-10" value={this.state.smv}
					        onChange={this.valueChange}
					        name="smv">
						<option value="17.5" onClick={this.getFallDetectorSmv}>1.8g</option>
						<option value="18.6" onClick={this.getFallDetectorSmv}>1.9g</option>
						<option value="19.7" onClick={this.getFallDetectorSmv}>2.0g</option>
						<option value="21" onClick={this.getFallDetectorSmv}>2.1g</option>
						<option value="22" onClick={this.getFallDetectorSmv}>2.2g</option>
						<option value="23.3" onClick={this.getFallDetectorSmv}>2.3g</option>
						<option value="24.6" onClick={this.getFallDetectorSmv}>2.4g</option>
					</select>
				</div>
				<div className="clearfix margin-bottom-10"/>
			</div>
		}
	};

	render() {
		const {t} = this.props;

		if (this.state.goHome) {
			return <Redirect to="/menu"/>
		} else {
			return <div>
				<EventHeader header={t('settings')} backTo="/menu"/>
				<div className="account-bg">
				</div>
				<div className="page-content page-content-no-margin-bottom col-12 position-absolute">
					<div className="page-div border-radius-all">
						<p><strong>{t('langChange')}</strong></p>
						<hr/>
						<div className='inputs-lang d-flex'>

							<div className='d-flex chosen-lang'>

								<div className='d-flex justify-content-center'>
									<img className='flag-no-margin'
										// src={require('../images/en.svg')}
										 src={require('../images/' + this.state.lang + '.svg')}
										 alt='lang'/>
									<p>{t('langName')}</p>
								</div>
							</div>
						</div>
						<Link className="btn default-button full-width-button margin-top-10" to={'/lang'}>
							{t('langChange2')}</Link>
					</div>

					{this.fallDetectorSettings()}
					{this.voiceSetting()}

				</div>
				{this.fallPopupClock()}
				{this.smsSentSuccess()}
				{this.changeSmvSensitivity()}
			</div>
		}


	}
}

export default translate("translations")(Settings);
