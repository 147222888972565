import React, {Component} from 'react';
import EventHeader from './../components/EventHeader'
import {Redirect} from 'react-router-dom';
import axios from "axios";
import {translate} from "react-i18next";

class Account extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.name = React.createRef();
		this.about = React.createRef();
		this.health = React.createRef();
		this.medicines = React.createRef();
		this.diseases = React.createRef();
		this.fallDetector = React.createRef();

		this.state = {
			goHome: false,
			oldPassword: '',
			newPassword: '',
			confirmNewPassword: '',
			phone: localStorage.getItem('phone'),
			phone2: localStorage.getItem('phone2'),
			name: localStorage.getItem('name'),
			email: localStorage.getItem('user'),
			guest: localStorage.getItem('guest'),
			errorPhone: '',
			errorPhone2: '',
		};
	}

	componentDidMount() {
		if (localStorage.getItem('phone') === null) {
			localStorage.setItem('phone', '');
		}
		if (localStorage.getItem('phone2') === null) {
			localStorage.setItem('phone2', '');
		}
		if (localStorage.getItem('name') === null) {
			localStorage.setItem('name', '');
		}

	}

	getFallDetectorValue = (e) => {
		if (this.state.phone !== '' || this.state.phone2 !== '') {
			if (localStorage.getItem('fallDetector') === undefined) {
				localStorage.setItem('fallDetector', '0');
			} else {
				localStorage.setItem('fallDetector', this.state.fallDetector);

			}
		} else {
			localStorage.setItem('fallDetector', '0');
		}
		this.setState({
			goHome: true
		});
	};

	getValue = (e) => {
		const {t} = this.props;

		if (this.state.name !== null) {
			localStorage.setItem('name', this.state.name);
		}
		if (this.state.phone.length === 9 || this.state.phone === '') {
			localStorage.setItem('phone', this.state.phone);
			this.setState({
				errorPhone: ''
			})
		} else {
			this.setState({
				errorPhone: t('errorPhone')
			})
		}

		if (this.state.phone2.length === 9 || this.state.phone2 === '') {
			localStorage.setItem('phone2', this.state.phone2);
			this.setState({
				errorPhone2: ''
			})
		} else {
			this.setState({
				errorPhone2: t('errorPhone')
			})
		}

		if (this.state.phone === '' && this.state.phone2 === '') {
			localStorage.setItem('fallDetector', '0');
		}

		if ((this.state.phone.length === 9 || this.state.phone === '') && (this.state.phone2.length === 9 || this.state.phone2 === ''))  {
			this.setState({
				dataChangeSuccess: true
			}, () => {
				setTimeout(() =>
					this.setState({
						dataChangeSuccess: false,
					}), 1500);
			})
		}
	};

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	successPopup = () => {
		const {t} = this.props;
		if (this.state.dataChangeSuccess) {
			return <div>
				<div className="overlay-success">
				</div>
				<div id="successPopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title first-letter-big" id="errorModal">{t('dataSaveSuccess')}</h6>
					</div>
				</div>
			</div>
		}
	};

	changePassword = () => {
		if (this.state.oldPassword !== '') {
			axios.post('https://api.helphelptrip.com/api/1.0/password_change', {
				old_password: this.state.oldPassword,
				new_password: this.state.newPassword,
				password_confirmed: this.state.confirmNewPassword,
			}, {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						passwordChangeSuccess: true,
					});
				})
				.catch((error) => {
					this.setState({
						oldPasswordError: error.response.data.errors.old_password,
						newPasswordError: error.response.data.errors.new_password,
						confirmNewPasswordError: error.response.data.errors.password_confirmed
					});
				});
		}
	};

	passwordChangePopup = () => {
		const {t} = this.props;

		if (this.state.passwordChangeSuccess) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>{t('success')}</p></div>
					<div className="popup">
						<h6 className="modal-title" id="errorModal">{t('successPasswordChange')}</h6>
						<button className="btn default-button full-width-button margin-top-10" onClick={this.closePopup}>{t('ok')}</button>
					</div>
				</div>
			</div>
		}
	};

	displayButton() {
		const {t} = this.props;
		if (this.state.oldPassword !== '') {
			return <button className="btn full-width-button default-button margin-bottom-10"
			               onClick={this.changePassword}>{t('save')}
			</button>
		} else {
			return <button className="btn full-width-button default-button button-disabled margin-bottom-10"
			               onClick={this.changePassword}>{t('save')}
			</button>
		}
	}

	closePopup = () => {
		this.setState({
			passwordChangeSuccess: false
		})
	};

	userInfo = () => {
		const {t} = this.props;

		if (this.state.guest === 'false') {
			return <div className="page-div border-radius-all">
				<div className="bg-content"
				     style={{backgroundImage: 'url(' + require('../images/info-solid.svg') + ')'}}/>
				<p><strong>{t('yourEmail')}</strong></p>
				<hr/>
				<div className="margin-top-20 margin-bottom-10">
					<p> {this.state.email}</p>
				</div>

			</div>
		} else {
			return <div className="page-div border-radius-all">
				<div className="bg-content"
				     style={{backgroundImage: 'url(' + require('../images/info-solid.svg') + ')'}}/>
				<p><strong>{t('info')}</strong></p>
				<hr/>
				<div className="margin-top-20 margin-bottom-10">
					<p>{t('guestMode2')}</p>
				</div>
			</div>
		}
	};

	passwordBox = () => {
		const {t} = this.props;
		if (this.state.guest === 'false') {
			return <div className="page-div border-radius-all password-change" id="passwordChange">
				<div className="bg-content"
				     style={{backgroundImage: 'url(' + require('../images/unlock-alt-solid.svg') + ')'}}/>
				<p><strong>{t('passwordChange')}</strong></p>
				<hr/>
				<div className="margin-top-20 margin-bottom-10">
					<p>{t('passwordChangeInfo')} <b>{t('save')}</b>.</p>
				</div>
				<div className=" margin-top-20 d-flex flex-column">
					<input className='full-width-input' onChange={this.valueChange} value={this.state.oldPassword}
					       type="password"
					       placeholder={t('actualPassword')} name="oldPassword"/>
					<p className="error-line-phone">{this.state.oldPasswordError}</p>
				</div>
				<div className=" margin-top-10">
					<input className='full-width-input' onChange={this.valueChange} value={this.state.newPassword}
					       type="password"
					       placeholder={t('newPassword')} name="newPassword"/>
					<p className="error-line-phone">{this.state.newPasswordError}</p>
				</div>
				<div className=" d-flex justify-content-between">
					<input className='full-width-input' onChange={this.valueChange}
					       value={this.state.confirmNewPassword} type="password"
					       placeholder={t('confirmNewPassword')} name="confirmNewPassword"/>
					<p className="error-line-phone">{this.state.confirmNewPasswordError}</p>

				</div>
				{this.displayButton()}
			</div>
		}
	};

	render() {
		const {t} = this.props;

		if (this.state.goHome) {
			return <Redirect to="/menu"/>
		}

		return <div>

			<EventHeader header={t('myAccount')} backTo="/menu"/>
			<div className="account-bg">
			</div>
			<div className="page-content col-12 position-absolute last-child">
				{this.userInfo()}
				<div className="page-div border-radius-all extra-margin-bottom">
					<div className="bg-content"
					     style={{backgroundImage: 'url(' + require('../images/info-solid.svg') + ')'}}/>
					<p><strong>{t('alarmPhones')}</strong></p>
					<hr/>
					<div className="margin-top-20 margin-bottom-10">
						<p>{t('alarmPhonesInfo')}</p>
					</div>
					<div className=" margin-bottom-10 margin-top-15">
						<input className='full-width-input' onChange={this.valueChange} value={this.state.phone}
						       type="number"
						       placeholder="000 000 000" name="phone"/>
						<p className="error-line-phone">{this.state.errorPhone}</p>
						<input className='full-width-input' onChange={this.valueChange} value={this.state.phone2}
						       type="number"
						       placeholder="000 000 000" name="phone2"/>
						<p className="error-line-phone">{this.state.errorPhone2}</p>
					</div>
					<div className="margin-bottom-10 d-flex justify-content-between">
						<input className='part-width-button' onChange={this.valueChange} value={this.state.name}
						       type="text" placeholder="Jan"
						       name="name"/>
						<button className="btn default-button short margin-bottom-10 save-button"
						        onClick={this.getValue}>{t('save')}</button>
					</div>
				</div>
				{this.passwordBox()}


			</div>
			{this.passwordChangePopup()}
			{this.successPopup()}

		</div>
	}
}

export default translate("translations")(Account);
