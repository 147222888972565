import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {translate} from "react-i18next";

class Facility extends Component {
	renderOtherButton(objectsLength, index) {
		const {t} = this.props;
		if (objectsLength > 1) {
			return <Link className="btn default-button margin-top-10" to={{
				pathname: '/event/' + this.props.eventId + '/category/' + index,
				state: {otherFacilities: this.props.categories, eventId: this.props.eventId}
			}}>{t("seeOther")}</Link>
		}
	}

	renderCoordinates(coordinates) {
		if (coordinates.latitude && coordinates.longitude) {
			return <p>{coordinates.latitude}, {coordinates.longitude}</p>
		} else {
			return false;
		}
	}

	renderImg(img) {
		if (img) {
			return <div className="info-container">
				<div className="img-con"><img src={img}/></div>
				<div className="par-con">
					<p className="margin-bottom-20">
						<strong>{this.props.item.objects[0].name}</strong>
					</p>
					<p>{this.props.item.objects[0].address}</p>
					{this.renderCoordinates(this.props.item.objects[0].coordinates)}
					<p>
						{this.props.item.objects[0].phones.map((phone, index) => {
							return <a className="d-block" key={index} href={'tel:' + phone}>{phone}</a>
						})}
					</p>
				</div>
			</div>
		} else {
			return <div className="info-container">
				<p className="margin-bottom-20">
					<strong>{this.props.item.objects[0].name}</strong>
				</p>
				<p>{this.props.item.objects[0].address}</p>
				{this.renderCoordinates(this.props.item.objects[0].coordinates)}
				<p>
					{this.props.item.objects[0].phones.map((phone, index) => {
						return <a className="d-block" key={index} href={'tel:' + phone}>{phone}</a>
					})}
				</p>
			</div>
		}
	}

	renderingButtons(phone, coordinates) {
		const {t} = this.props;
		if (phone && coordinates) {
			return <div>
				<a className="btn default-button orange-btn left-btn"
				   href={'https://www.google.com/maps/place/' + this.props.item.objects[0].coordinates.latitude + ',' + this.props.item.objects[0].coordinates.longitude}
				   target='_blank' rel="noopener noreferrer"><i className="fas fa-map-marker-alt"/>{t("drive")}</a>
				<a className="btn default-button green-btn right-btn"
				   href={'tel:' + this.props.item.objects[0].phones[0]}>
					<i className="fas fa-phone"/>{t("call")}</a>
			</div>
		} else if (!phone && coordinates) {
			return <div>
				<a className="btn default-button orange-btn "
				   href={'https://www.google.com/maps/place/' + this.props.item.objects[0].coordinates.latitude + ',' + this.props.item.objects[0].coordinates.longitude}
				   target='_blank' rel="noopener noreferrer"><i className="fas fa-map-marker-alt"/>{t("drive")}</a>
				<a className="btn default-button green-btn hide-element"
				   href={'tel:' + this.props.item.objects[0].phones[0]}>
					<i className="fas fa-phone"/>{t("call")}</a>
			</div>
		} else if (phone && !coordinates) {
			return <div>
				<a className="btn default-button orange-btn hide-element"
				   href={'https://www.google.com/maps/place/' + this.props.item.objects[0].coordinates.latitude + ',' + this.props.item.objects[0].coordinates.longitude}
				   target='_blank' rel="noopener noreferrer"><i className="fas fa-map-marker-alt"/>{t("drive")}</a>
				<a className="btn default-button green-btn margin-top-10 "
				   href={'tel:' + this.props.item.objects[0].phones[0]}>
					<i className="fas fa-phone"/>{t("call")}</a>
			</div>
		}
	}

	render() {
		if (this.props.categories.length !== 0) {
			let phonesExist = this.props.item.objects[0].phones.length;
			let coordinatesExist = this.props.item.objects[0].coordinates.longitude ? true : false && this.props.item.objects[0].coordinates.latitude ? true : false;

			if (this.props.noradius) {
				return <div className="page-div border-radius-bottom">
					<div className="bg-content"
					     style={{backgroundImage: 'url(' + this.props.item.objects[0].background + ')'}}/>
					{this.renderImg(this.props.item.objects[0].image)}
					{this.renderingButtons(phonesExist, coordinatesExist)}
					{this.renderOtherButton(this.props.item.objects.length, this.props.index)}
				</div>
			} else {
				return <div className="page-div border-radius-all">
					<div className="bg-content"
					     style={{backgroundImage: 'url(' + this.props.item.objects[0].background + ')'}}/>
					{this.renderImg(this.props.item.objects[0].image)}
					{this.renderingButtons(phonesExist, coordinatesExist)}
					{this.renderOtherButton(this.props.item.objects.length, this.props.index)}
				</div>
			}
		} else {
			return <div className="page-div border-radius-all">
				<div className="bg-content"/>
				<p>Nie znaleziono żadnych obiektów.</p>
			</div>
		}


	}
}

export default translate("translations")(Facility);
