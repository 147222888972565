import React, {Component} from 'react';

import Facility from '../components/Facility';
import {translate} from "react-i18next";

class ClosestFacility extends Component {
	render() {

		const {t} = this.props;

		if (this.props.categories.length !== 0) {
			return <div className="no-pointer-events">
				<div className="content-title border-radius-top"><p>{this.props.categories[0].title2} {t("closest")}</p>
				</div>
				<Facility item={this.props.categories[0]} categories={this.props.categories}
				          eventId={this.props.eventId}
				          index='0' noradius={true}/>
			</div>
		} else {
			return <div className="no-pointer-events">
				<div className="page-div border-radius-all">
					<p><b>Nie znaleziono obiektów w pobliżu.</b></p>
				</div>
			</div>
		}
	}
}

export default translate("translations")(ClosestFacility);
