import AddFileOrFolder from '../components/AddFileOrFolder';
import FileOrFolder from '../components/FileOrFolder'
import EventHeader from './../components/EventHeader'
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
import axios from "axios";
import {translate} from "react-i18next";
const addr = 'https://api.helphelptrip.com/api/1.0';

class Directory extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			files: '',
			name: '',
			slug: '',
			loaded: false,
			storage: props.location.state.storage,
			id: props.location.state.id,
		};
		let handleToUpdate = this.handleToUpdate.bind(this);
	}

	componentDidMount() {
		let makeSlugFrom = this.props.location.state.nameFrom.replace(' ', '-').toLowerCase();
		let makeSlug = this.props.location.state.name.replace(' ', '-').toLowerCase();
		this.setState({
			files: this.props.location.state.files,
			name: this.props.location.state.name,
			pathMaker: makeSlugFrom + '/' + makeSlug,
			loaded: true
		});
		if (this.state.storage.id !== undefined) {
			axios.get(addr +'/dirs/' + this.state.storage.id + '/items', {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})

				.then((response) => {
					this.setState({
						storage: response.data,
						storageList: true
					});

				})
				.catch((error) => {

				});
		} else {
			axios.get(addr +'/dirs/' + this.state.id + '/items', {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})

				.then((response) => {
					this.setState({
						storage: response.data,
						storageList: true
					});

				})
				.catch((error) => {

				});
		}


	}

	handleToUpdate = (newStorage) => {
		this.setState({
			storage: {files: [], directories: []},
			newStorage: true
		});
		this.setState({
			storage: newStorage,
			newStorage: true
		});
	};

	render() {
		const {t} = this.props;

		if (this.state.storageList) {
			return <div>
				<EventHeader header={t('storage')} backTo="/menu"/>
				<div className="page-content storage col-12">
					<div className="page-div storage-container border-radius-all">
						<p className="path-style border-radius-top">{t('storage')}/{this.props.match.params.directory}</p>
						<Link className="storage-go-back grey-color " to={'/storage'}>
							<i className="fas fa-chevron-left pr-2 grey-color"/>
							{t("back")}</Link>
						<div className="storage-wrapper">

							{this.state.storage.directories.map((file, index) => {
								//folder*/}
								return <FileOrFolder id={this.state.storage.directories[index].id}
								                     key={index}
								                     name={file.name}
								                     isFile={false}
								                     files={file.files}
								                     nameFrom={this.state.name}
								                     storage={this.props.location.state.storage}
								                     storage2={this.state.storage}
								                     handleToUpdate={this.handleToUpdate}/>
							})}

							{this.state.storage.files.map((file, index) => {
								let fileFullName = '',
									fileNamePart = '',
									fileType = '';

								fileFullName = file.name;
								fileNamePart = fileFullName.split('.');
								fileType = fileNamePart[1];
								return <FileOrFolder key={index}
								                     name={file.name}
								                     isFile={true}
								                     fileType={fileType}
								                     files={this.state.storage.files[index]}
								                     link={file.link}
								                     handleToUpdate={this.handleToUpdate}/>
							})}
							<AddFileOrFolder data={this.props.location.state.storage} props={this.props}
							                 handleToUpdate={this.handleToUpdate}/>
						</div>
					</div>
				</div>
			</div>
		} else {
			return <div className="bg">
				<div className="centering">
					<p className="center text-center">
						<img src={require('../images/logo.png')} alt="" className="logo-in-spinner"/>
						<i className="fas fa-spinner"/> {t("loading")}
					</p>
				</div>
			</div>
		}

	}
}
export default translate("translations")(Directory);
