import React, {Component} from 'react';
import {translate} from "react-i18next";

class Translator extends Component {

	render() {
		const {t} = this.props;

		return <div className="page-div border-radius-all">
			<div className="bg-content" style={{backgroundImage: 'url(' + this.props.translator.background + ')'}}/>
			<p><strong>{this.props.translator.title}</strong></p>
			<p>{this.props.translator.name}</p>
			<a className="btn default-button green-btn margin-bottom-15 margin-top-10" href={'tel:+' + this.props.translator.phone}>
				<i className="fas fa-phone"/> {t('call')}
			</a>
			<p>{t('onlineTranslator')}</p>
			<a className="btn default-button margin-top-10" href='https://translate.google.pl/?hl=pl' target='_blank'
			   rel="noopener noreferrer">{t('onlineTranslatorOn')}</a>
		</div>
	}
}
export default translate("translations")(Translator);

