import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {translate} from "react-i18next";

class Intro extends Component {

	render() {
		if(this.props.custom.title !== '') {
			return <div className="no-pointer-events currency-calculator">
				<div className="content-title border-radius-top"><p>{this.props.custom.title}</p></div>
				<div className="page-div border-radius-bottom to-left">

					<div dangerouslySetInnerHTML={{__html: this.props.custom.teaser}}>
					</div>
					<Link className="btn default-button margin-top-10" to={{
						pathname: '/event/' + this.props.eventId + '/content',
						state: {
							content: this.props.custom.content,
							title: this.props.custom.title,
							eventId: this.props.eventId
						}
					}}>{this.props.custom.button}</Link>
				</div>
			</div>
		} else {
			return <div>
			</div>
		}

	}
}

export default translate("translations")(Intro);
