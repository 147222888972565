import React, {Component} from 'react';
import {translate} from "react-i18next";

class Emergency extends Component {
	render() {
		const {t} = this.props;

		return <div className="page-div border-radius-all">
			<a className="btn default-button" href="tel:112" style={{marginTop: 0}}>{t("call112")}</a>
		</div>
	}
}
export default translate("translations")(Emergency);
