import React, {Component} from 'react';
import axios from "axios";
import {translate} from "react-i18next";

const addr = 'https://api.helphelptrip.com/api/1.0';

class AddFileOrFolder extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			addFolder: false,
			addFolderSuccess: false,
			isFile: props.isFile,
			name: props.name,
			slug: props.slug,
			files: props.files,
			link: props.link,
			folderName: '',
			rootDir: localStorage.getItem('rootDir'),
			data: props.data,
			props: props.props,
			newFileOrFolder: false
		};
		this.fileInput = React.createRef();
	}

	valueChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	addFile = () => {
		this.setState({
			addFile: true,
			optionsOpen: false,
			isFile: true
		});
	};

	addFolder = () => {
		this.setState({
			addFolder: true,
			optionsOpen: false,
			folderName: '',
			isFile: false
		});
	};

	displayButton() {
		const {t} = this.props;

		if (this.state.folderName !== '' && this.state.folderName.length >= 3) {
			return <button className="btn default-button full-width-button" onClick={this.confirmAdd}>{t("add")}</button>
		} else {
			return <button className="btn default-button button-disabled full-width-button">{t("add")}</button>
		}
	}

	confirmAdd = () => {
		if (this.state.data !== undefined) {
			axios.post(addr +'/dirs', {
				parentDir: this.state.data.id,
				name: this.state.folderName,
				rootDir: false
			}, {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						addFolderSuccess: true,
						isRootDir: false

					}, () => {

						setTimeout(() =>
							this.setState({
								addFolder: false,
								optionsOpen: false,
								addFolderSuccess: false,
								storageList: false
							}), 2500)
					});
				})
				.catch((error) => {
					this.setState({
						dirError: error.response.data.errors.dirs
					})
				});
		} else {
			axios.post(addr +'/dirs', {
				parentDir: this.state.rootDir,
				name: this.state.folderName,
				rootDir: false
			}, {
				headers: {
					'Content-type': 'application/json',
					token: localStorage.getItem('token')
				}
			})
				.then((response) => {
					this.setState({
						addFolderSuccess: true,
						isRootDir: true

					}, () => {
						setTimeout(() =>
							this.setState({
								addFolderSuccess: false,
								addFolder: false,
								storageList: false
							}), 2500)
					});
				})
				.catch((error) => {
					this.setState({
						dirError: error.response.data.errors.dirs
					})
				});
		}
	};

	closePopup = () => {
		this.setState({
			addFolderSuccess: false,
			addFolder: false
		})
	};

	getNewDataRoot = () => {

		axios.get(addr +'/dirs/' + this.state.rootDir + '/items', {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			}
		})
			.then((response) => {
				this.setState({
					storage: response.data,
					storageList: true
				});
				let handleToUpdate = this.props.handleToUpdate;
				handleToUpdate(this.state.storage)
			})
			.catch((error) => {

			});
	};

	getNewDataId = () => {
		axios.get(addr +'/dirs/' + this.state.data.id + '/items', {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			}
		})
			.then((response) => {

				this.setState({
					storage: response.data,
					storageList: true
				});
				let handleToUpdate = this.props.handleToUpdate;
				handleToUpdate(this.state.storage)
			})
			.catch((error) => {

			});
	};

	closeOptionPopup = () => {
		if (!this.state.isFile) {
			return <div className="popup-flex-close">
				<div className="popup-close" onClick={this.closePopup}>
					<span className="fas fa-times grey-color"/>
				</div>
			</div>
		} else {
			return <div className="popup-flex-close">
				<div className="popup-close" onClick={this.closeFilePopup}>
					<span className="fas fa-times grey-color"/>
				</div>
			</div>
		}
	};

	addingDirPopup = () => {
		const {t} = this.props;

		if (this.state.addFolder && !this.state.addFolderSuccess) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t("addDir")}</p></div>
					<div className="popup">
						<input type="text" placeholder={t("dirName")} onChange={this.valueChange} name="folderName"
						       value={this.state.folderName}/>
						<p className="error-line">{this.state.dirError}</p>
						{this.displayButton()}
						<button className="btn default-button full-width-button margin-top-10" onClick={this.closePopup}>{t("cancel")}</button>
					</div>

				</div>
			</div>
		}

		if (this.state.addFolder && this.state.addFolderSuccess && this.state.isRootDir && !this.state.storageList) {

			this.getNewDataRoot()

		} else if (this.state.addFolder && this.state.addFolderSuccess && !this.state.isRootDir && !this.state.storageList) {

			this.getNewDataId()

		}

		if (this.state.addFolder && this.state.addFolderSuccess && this.state.storageList && (this.state.isRootDir || !this.state.isRootDir)) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="deleteConfirmPopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title first-letter-big" id="errorModal">{t("dirSuccess")}</h6>
					</div>
				</div>
			</div>
		}
	};

	handleSubmit = (event) => {
		event.preventDefault();
		let formData = new FormData();
		formData.append("document", this.fileInput.current.files[0]);
		this.setState({
			addChosenFile: formData
		}, () => {
			if (this.state.data !== undefined) {
				formData.append("dir", this.state.data.id);
				axios.post(addr + '/files',
					formData
					, {
						headers: {
							'Content-type': 'multipart/form-data',
							token: localStorage.getItem('token')
						}
					})
					.then((response) => {

						this.setState({
							addFileSuccess: true,
							isRootDir: false,

						}, () => {

							setTimeout(() =>
								this.setState({
									addFolder: false,
									addFile: false,
									optionsOpen: false,
									addFileSuccess: false,
									storageList: false,

								}), 2500)
						});
					})
					.catch((error) => {
						this.setState({
							fileError: error.response.data.errors.file
						});
					});
			} else {
				formData.append("dir", this.state.rootDir);

				axios.post(addr + '/files',
					formData
					, {
						headers: {
							'Content-type': 'multipart/form-data',
							token: localStorage.getItem('token')
						}
					}
				)
					.then((response) => {

						this.setState({
							addFileSuccess: true,
							isRootDir: true

						}, () => {

							setTimeout(() =>
								this.setState({
									addFolder: false,
									addFile: false,
									optionsOpen: false,
									addFileSuccess: false,
									storageList: false
								}), 2500)
						});
					})
					.catch((error) => {
						this.setState({
							fileError: error.response.data.errors.file
						});
					});
			}
		});
	};

	closeFilePopup = () => {

		this.setState({
			addFileSuccess: false,
			addFile: false
		})
	};

	submitButton = () => {
		const {t} = this.props;

		if (this.fileInput.current !== null && this.state.inputChanged) {
			return <label className='change-label' htmlFor="file">{t("change")}</label>
		} else {
			return <label htmlFor="file">{t("chooseFile")}</label>
		}

	};

	addedFile = () => {
		const {t} = this.props;

		if (this.fileInput.current !== null && this.state.inputChanged) {
			return <div>
				<p className='chosen-file-name'>{t("fileChosen")}</p>
				<span className='underline-file-name'> {this.fileInput.current.files[0].name}</span>
				<p className="error-line">{this.state.fileError}</p>
				<button className="btn default-button full-width-button" type="submit">{t("add")}</button>
			</div>
		}
	};

	getFile = () => {
		if (this.fileInput.current.files.length !== 0) {
			this.setState({
				inputChanged: true
			});
		}
	};

	addingFilePopup = () => {
		const {t} = this.props;

		if (this.state.addFile && !this.state.addFileSuccess) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="savePopup">
					{this.closeOptionPopup()}
					<div className="popup-title"><p>{t("addFile")}</p></div>
					<div className="popup">
						<form onSubmit={this.handleSubmit} onChange={this.getFile}>
							<input type="file" name="file" className="inputfile" id="file" ref={this.fileInput}/>

							{this.submitButton()}
							{this.addedFile()}
						</form>
						<button className="btn default-button full-width-button margin-top-10" onClick={this.closeFilePopup}>{t("cancel")}</button>
					</div>
				</div>
			</div>
		}

		if (this.state.addFile && this.state.addFileSuccess && this.state.isRootDir && !this.state.storageList) {
			this.getNewDataRoot()

		} else if (this.state.addFile && this.state.addFileSuccess && !this.state.isRootDir && !this.state.storageList) {
			this.getNewDataId()
		}

		if (this.state.addFile && this.state.addFileSuccess && this.state.storageList && (this.state.isRootDir || !this.state.isRootDir)) {
			return <div>
				<div className="overlay-storage">
				</div>
				<div id="deleteConfirmPopup">
					<div className="popup-title"><p>{t("success")}</p></div>
					<div className="popup">
						<h6 className="modal-title first-letter-big" id="errorModal">{t("addFileSuccess")}</h6>
					</div>
				</div>
			</div>
		}


	};

	render() {
		const {t} = this.props;

		return (
			<div className="d-flex justify-content-center flex-wrap mb-4">
				<div className="data-box folder-to-add">
					<div className="folder-div single-file-or-folder" onClick={this.addFolder}>
						<i className="fas fa-folder-plus light-grey-color"/>
						<div className="file-name">
							<h6>+ {t("addFolder")}</h6>
						</div>
					</div>
				</div>
				<div className="data-box folder-to-add">
					<div className="folder-div single-file-or-folder" onClick={this.addFile}>
			<span className="fa-stack">
			<i className="fas fa-plus fa-stack-1x light-grey-color small-icon"/>
			<i className="far fa-file fa-stack-2x light-grey-color "/>
			</span>
						<div className="file-name">
							<h6>+ {t("addFile2")}</h6>

						</div>
					</div>
				</div>
				{this.addingDirPopup()}
				{this.addingFilePopup()}
			</div>
		)
	}
}
export default translate("translations")(AddFileOrFolder);
