import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {translate} from "react-i18next";

class Forms extends Component {
	constructor(props) {
		super(props);
			this.state = {
				eventId: props.match.params.eventId,

			};
	}
	//routingi do pobrania formularzy i historia formularzy
	// do zrobienia
	render() {
		const {t} = this.props;

		return <div className="bg">
				<div className="header row no-gutters col-12">
					<Link className="back d-flex" to={'/event/' + this.state.eventId}>
						<div className="arrow-header">
							<i className="fas fa-chevron-left"/>
						</div>
						<div className="title-header">
							<h5>{t('forms')}</h5>
						</div>
					</Link>
				</div>
				<div className="page-content col-12">
					<div className="page-div border-radius-all">
						<p><strong>{t('downloadedForms')}</strong></p>
						<hr/>
					</div>
					<div className="page-div border-radius-all">
						<p><strong>{t('archivedForms')}</strong></p>
						<hr/>
					</div>
				</div>
			</div>
	}
}
export default translate("translations")(Forms);
