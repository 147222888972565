import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import axios from "axios";
import {translate} from "react-i18next";
import Swipe from 'react-easy-swipe';
import FallDetector from '../components/FallDetector';

const addr = 'https://api.helphelptrip.com/api/1.0';

class Header extends Component {

	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);
		this.state = {
			isLoaded: false,
			events: [],
			error: false,
			loggedIn: true,
			offlineData: '',
			offlineDataObject: '',
			writeFile: false,
			writeObjectFile: false,
			internetConnection: true,
			notMobile: '',
			goHome: false,
			data: {},
			name: localStorage.getItem('name') || 'Użytkownik',
			phone: localStorage.getItem('phone'),
			phone2: localStorage.getItem('phone2'),
			geolocationSuccess: false,
			latitude: '',
			longitude: '',
			accidentSave: false,
			objectSave: false,
			fallDetector: localStorage.getItem('fallDetector'),
			wantToLogOut: false,
			regions: [],
			chooseRegion: false,
			regionChosen: '',
			guestMode: localStorage.getItem('guest'),
			sidebarOpen: false,
			user: localStorage.getItem('user'),
			lang: localStorage.getItem('i18nextLng')
		};
		localStorage.setItem('messageSend', 'false');
	}

	componentDidMount() {
		if (this.state.lang.length !== null && this.state.lang.length > 2) {
			let lang = '';
			lang = this.state.lang.split('-');
			this.setState({
				lang: lang[0]
			});
			localStorage.setItem('i18nextLng', lang[0])
		}
		if (this.isCordova) {
			document.addEventListener("deviceready", () => {
				//window.cordova.plugins.backgroundMode.overrideBackButton();
			})
		}
	}

	logOut = () => {
		axios.post(addr + '/logout', null, {
			headers: {
				'Content-type': 'application/json',
				token: localStorage.getItem('token')
			},

		})
			.then((response) => {
				this.setState({
					loggedIn: false
				}, () => {
					localStorage.removeItem('token');
					localStorage.removeItem('user');
					this.setState({
						loggedOut: true
					})
				});
			})
			.catch((error) => {
				if (error.response.status === 403 && error.response.data.password_change) {
					this.setState({
						forbidden: true,
						forbiddenError: error.response.data.password_change
					});
				}
			});
	};

	hideFloatingButton() {
		const {t} = this.props;

		if (typeof this.props.hideBtn === 'undefined') {
			return <Link className="header-icon d-flex floating-button btn default-button" to={'/zdrowie'}>
				<img src={require('../images/leki.svg')} alt="medicines"/>
				{t("myHealth")}
			</Link>
		}
	}

	regionValueChange = (e) => {
		this.setState({
			regionChosen: e.target.value
		});
	};

	chooseRegionModal = () => {
		if (this.state.chooseRegion) {
			return <div>
				<div className="overlay">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>Wybierz region</p></div>
					<div className="popup">
						<select name="regions" id="regions" value={this.state.regionChosen}
						        onChange={this.regionValueChange}>
							{this.state.regions.map((region, index) => {
								return <option key={index} value={region}>{region}</option>
							})}
						</select>
						<button className="btn default-button" onClick={this.saveOfflineData}>Wybierz</button>
						<button className="btn default-button" onClick={this.returnHome}>Zamknij</button>
					</div>
				</div>
			</div>
		}
	};

	chooseRegion = () => {
		axios.get('https://panel.wurren.optize.pl/wurren/taxonomy?q=region')
			.then(res => res.data)
			.then((result) => {
					let regions = [];
					for (let i in result.data.options) {
						if (result.data.options.hasOwnProperty(i)) {
							regions.push(result.data.options[i])
						}
					}
					this.setState({
						regions: regions,
						chooseRegion: true,
						regionChosen: regions[0]
					});
					localStorage.setItem('regions', regions)
				},
				(error) => {
				}
			);
	};

	saveOfflineData = () => {
		this.setState({
			chooseRegion: false
		});
		if (this.isCordova) {
			this.setState({
				notMobile: false,
			});
			if (this.state.internetConnection) {
				axios.get('https://panel.wurren.optize.pl/wurren/accident?contains[region]=' + this.state.regionChosen)
					.then(res => res.data)
					.then((result) => {
						let res = null;
						res = JSON.stringify(result);
						this.setState({
							offlineData: res
						});
						this.writeFile();
					}, () => {
					});
				axios.get('https://panel.wurren.optize.pl/wurren/objects?page[size]=9999&contains[region]=' + this.state.regionChosen)
					.then(res => res.data)
					.then((result) => {
						let res = null;
						res = JSON.stringify(result);
						this.setState({
							offlineDataObject: res,
						});
						this.writeFileObject();
					}, () => {

					});
			} else {
				alert("Błąd. Nie udało się zapisać danych gdyż aplikacja pracuje w trybie offline. Sprawdź połączenie z siecią i spróbuj ponownie.")
			}
		} else {
			this.setState({
				notMobile: true,
				popup: true
			})
		}
	};

	writeFile = () => {

		let offlineDataText = '',
			blob = {};
		offlineDataText = this.state.offlineData;
		let type = window.PERSISTENT;
		let size = 5 * 1024 * 1024;
		window.requestFileSystem(type, size, (fs) => {
			fs.root.getFile('accident.txt', {create: true}, (fileEntry) => {
				//fs.root.getFile('accident-'+ this.state.regionChosen +'.txt', {create: true}, (fileEntry) => {
				fileEntry.createWriter((fileWriter) => {
					fileWriter.onwriteend = () => {

						this.setState({
							accidentSave: true
						}, () => {
							setTimeout(() =>
								this.setState({
									accidentSave: false
								}), 3000)
						});
						// this.setState({
						//     accidentSave: true
						// })
					};

					fileWriter.onerror = () => {
						alert('Błąd zapisu danych');
					};
					blob = new Blob([offlineDataText], {type: 'text/plain'});
					fileWriter.write(blob);

				}, () => {
				});
			}, () => {
			});
		}, () => {
			alert('Błąd zapisu danych')
		});
	};

	writeFileObject = () => {
		let offlineDataObjectText = '',
			blob = {};
		offlineDataObjectText = this.state.offlineDataObject;
		let type = window.PERSISTENT;
		let size = 5 * 1024 * 1024;
		window.requestFileSystem(type, size, (fs) => {
			//fs.root.getFile('object-' + this.state.regionChosen + '.txt', {create: true}, (fileEntry) => {
			fs.root.getFile('object.txt', {create: true}, (fileEntry) => {
				fileEntry.createWriter((fileWriter) => {
					fileWriter.onwriteend = () => {
						this.setState({
							objectSave: true
						}, () => {
							setTimeout(() =>
								this.setState({
									objectSave: false
								}), 3000)
						});
					};

					fileWriter.onerror = () => {
						alert('Błąd zapisu danych');
					};
					blob = new Blob([offlineDataObjectText], {type: 'text/plain'});
					fileWriter.write(blob);

				}, () => {
				});
			}, () => {
			});
		}, () => {
			alert('Błąd zapisu danych');
		});

	};

	returnHome = () => {
		this.setState({
			notMobile: false,
			chooseRegion: false
		});
	};

	closeSavePopup = () => {
		this.setState({
			objectSave: false,
			accidentSave: false,
		});
	};

	saveNotAvailable = () => {
		if (this.state.notMobile) {
			return <div id="savePopup">
				<div className="popup-title"><p>Błąd zapisu danych</p></div>
				<div className="popup">
					<h6 className="modal-title" id="errorModal">Opcja dostępna tylko w aplikacji mobilnej.</h6>
					<button className="btn default-button" onClick={this.returnHome}>Zamknij</button>
				</div>
			</div>
		}
	};

	dataSaved = () => {
		if (this.state.objectSave && this.state.accidentSave) {
			return <div>
				<div className="overlay">
					<div id="savePopup">
						<div className="popup-title"><p>Dane zapisano poprawnie</p></div>
						<div className="popup">
							<h6 className="modal-title" id="errorModal">Dzięki temu możliwe będzie korzystanie z
								aplikacji w
								trybie offline</h6>
						</div>
					</div>
				</div>
			</div>
		}
	};

	login = () => {

		localStorage.removeItem('token');
		axios.post(addr + '/login', {
			email: this.state.loginEmail,
			password: this.state.loginPassword
		}, {
			headers: {
				'Content-type': 'application/json'
			}
		})
			.then((response) => {

				let token = response.data.token,
					user = response.data.user.email,
					rootDir = response.data.rootDir;
				localStorage.setItem('token', token);
				localStorage.setItem('rootDir', rootDir);
				localStorage.setItem('user', user);
				localStorage.setItem('guest', 'false');

				this.setState({
					loggedIn: true
				});
			})
			.catch((error) => {
				const {t} = this.props;

				if (error.response.status === 404) {
					this.setState({
						errorLoginMessage: t("errorLoginMessage404")
					})
				} else if (error.response.status === 401) {
					this.setState({
						errorLoginMessage: t("errorLoginMessage401")
					})
				}
			});
	};

	onSwipeRight = () => {
		this.setState({
				sidebarOpen: true
			}
		);
	};

	onSwipeLeft = () => {
		this.setState({
			sidebarOpen: false
		})
	};

	moveToRegister = () => {
		this.setState({
			register: true
		});
		localStorage.setItem('guest', 'false')
	};

	guestLogOut = () => {
		this.setState({
			moveToLogin: true
		});
		localStorage.setItem('guest', 'false')
	};

	sidebarShow() {
		const {t} = this.props;


		if (this.state.sidebarOpen === true) {
			if (this.state.register) {
				return <Redirect to={{
					pathname: '/',
					state: {
						register: this.state.register,
					}
				}}/>
			}
			if (this.state.moveToLogin) {
				return <Redirect to={{
					pathname: '/',

				}}/>
			}
			if (this.state.loggedOut) {
				return <Redirect to={{
					pathname: '/'
				}}/>
			}
			if (this.state.guestMode === 'false') {
				return <div>
					<div className="overlay" onClick={this.closeSidebar}>
					</div>
					<Swipe onSwipeLeft={this.onSwipeLeft}
						>
						<div className='sidebar-box'>
							<div className='sidebar-header'>
								<Link to={'/lang'}>
									<div className='round-flag'>
										<img className='help-logo'
										     src={require('../images/' + localStorage.getItem('i18nextLng') + '.svg')}
										     alt='help-logo'/>
									</div>
								</Link>
								<div className='user-info'>
									<p><b>{t('yourEmail')}</b></p>
									<p>{this.state.user}</p>
								</div>
							</div>
							<div className='sidebar-options'>
								<ul>
									<li>
										<Link className="header-icon d-flex" to={'/konto'}>

											<i className='fas fa-user-edit icon-box'/>
											<p><b>{t('myAccount')}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/zdrowie'}>

											<div className='icon-box'>
												<img src={require('../images/leki.svg')} alt="settings"/>
											</div>
											<p><b>{t('myHealth')}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/storage'}>

											<i className='fas fa-folder-open icon-box'/>
											<p><b>{t('storage')}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/ustawienia'}>
											<div className='icon-box'>
												<img src={require('../images/ustawienia.svg')} alt="settings"/>
											</div>
											<p><b>{t('settings')}</b></p>
										</Link>

									</li>
									<li>
										<button className='sidebar-button' onClick={this.logOut}>
											<i className='fas fa-sign-out-alt icon-box'/>
											<p><b>{t('logOut')}</b></p>
										</button>
									</li>

								</ul>
								{this.downloadApp()}
							</div>

						</div>
					</Swipe>

				</div>

			} else {
				return <div>
					<div className="overlay" onClick={this.closeSidebar}>
					</div>
					<Swipe onSwipeLeft={this.onSwipeLeft}>

						<div className='sidebar-box'>
							<div className='sidebar-header'>
								<Link to={'/lang'}>
									<div className='round-flag'>
										<img className='help-logo'
										     src={require('../images/' + localStorage.getItem('i18nextLng') + '.svg')}
										     alt='help-logo'/>
									</div>

								</Link>
								<div className='user-info'>
									<p>{t('guestMode')}</p>

								</div>
							</div>
							<div className='sidebar-options'>
								<ul>
									<li>
										<Link className="header-icon d-flex" to={'/konto'}>

											<i className='fas fa-user-edit icon-box'/>
											<p><b> {t('myAccount')}</b></p>
										</Link>
									</li>
									<li>
										<Link className="header-icon d-flex" to={'/zdrowie'}>

											<div className='icon-box'>
												<img src={require('../images/leki.svg')} alt="settings"/>
											</div>
											<p><b>{t('myHealth')}</b></p>
										</Link>
									</li>

									<li>
										<Link className="header-icon d-flex" to={'/ustawienia'}>
											<div className='icon-box'>
												<img src={require('../images/ustawienia.svg')} alt="settings"/>
											</div>
											<p><b> {t('settings')}</b></p>
										</Link>

									</li>

								</ul>
								<hr/>
								<ul>
									<li>
										<button className='sidebar-button' onClick={this.guestLogOut}>
											<i className='fas fa-sign-in-alt icon-box'/>
											<p><b> {t('loginSidebar')}</b></p>
										</button>
									</li>
									<li>
										<button className='sidebar-button' onClick={this.moveToRegister}>
											<i className='fas fa-edit icon-box'/>
											<p><b> {t('register2')}</b></p>
										</button>
									</li>
								</ul>

								{this.downloadApp()}
							</div>

						</div>
					</Swipe>
				</div>
			}

		}

	};

	downloadApp = () => {
		const {t} = this.props;

		if (!this.isCordova) {
			return <div>
				<hr/>
				<ul>
					<li>
						<a className="back d-flex" href="https://helphelptrip.com/android.apk">
							<i className='fas fa-download icon-box'/>
							<p><b> {t("downloadApk")}</b></p>
						</a>
					</li>
				</ul>
			</div>
		}
	};

	render() {
		const {t} = this.props;

		if (this.state.forbidden) {
			return <div>
				<div className="overlay">
				</div>
				<div id="savePopup">
					<div className="popup-title"><p>{t('error')}</p></div>
					<div className="popup">
						<p>{this.state.forbiddenError}</p>
						<Link className="btn default-button"
						      to={'/ustawienia/#passwordChange'}>{t('passwordChange')}</Link>
					</div>
				</div>
			</div>
		}
		return <div>
			<Swipe onSwipeRight={this.onSwipeRight}
			>
				<div className='swipe-area'>

				</div>
			</Swipe>
			{this.sidebarShow()}
			<div className="main-header row no-gutters col-12">

				<div className="header-icons">
					<button onClick={this.onSwipeRight} className='menu-icon'>
						<i className='bigger-icon fas fa-bars'/>
					</button>
				</div>
				<Link className="header-icon d-flex floating-button btn default-button" to={'/leki'}>
					<img src={require('../images/leki.svg')} alt="medicines"/>
					{t('myHealth')}
				</Link>
				<FallDetector title={this.props.header}/>
				{this.hideFloatingButton()}
				{this.saveNotAvailable()}
				{this.dataSaved()}
				{this.chooseRegionModal()}
			</div>
		</div>
	}
}

export default translate("translations")(Header);
