import React, {Component} from 'react';
import {translate} from "react-i18next";

class TutorialMain extends Component {
	constructor(props) {
		super(props);
		this.isCordova = navigator.userAgent.match(/cordova/i);

		this.state = {
			step: 1,
			seenTutorialMain: false
		};
	}

	languageButtons = () => {
		const {i18n} = this.props;
		const changeLanguage = lng => {
			i18n.changeLanguage(lng);
		};
		return <div className='language-buttons-tutorial'>
			<img alt="" src={require('../images/pl.svg')} onClick={() => changeLanguage("pl")}/>
			<img alt="" src={require('../images/en.svg')} onClick={() => changeLanguage("en")}/>
			<img alt="" src={require('../images/fr.svg')} onClick={() => changeLanguage("fr")}/>
			<img alt="" src={require('../images/ru.svg')} onClick={() => changeLanguage("ru")}/>
		</div>
	};

	nextStep = () => {

		let currentStep = this.state.step,
			nextStep = currentStep + 1;

		this.setState({
			step: nextStep
		});
	};

	closeTutorial = () => {
		this.props.callback();
	};

	nextStepButton = () => {
		const {t} = this.props;

		return <button className="btn default-button full-width-button" onClick={this.nextStep}>
			{t('next')}
		</button>
	};
	setStep1 = () => {
		this.setState({
			step: 1
		})
	};
	setStep2 = () => {
		this.setState({
			step: 2
		})
	};
	setStep3 = () => {
		this.setState({
			step: 3
		})
	};
	setStep4 = () => {
		this.setState({
			step: 4
		})
	};
	setStep5 = () => {
		this.setState({
			step: 5
		})
	};

	inputChange = () =>{

	};

	dots = () => {
		return <div className='dots'>
			<input type='radio' className='nav-dot' onClick={this.setStep1} readOnly checked={this.state.step === 1}/>
			<label htmlFor='step1' onClick={this.setStep1}></label>
			<input type='radio' className='nav-dot' onClick={this.setStep2} readOnly checked={this.state.step === 2}/>
			<label htmlFor='step2' onClick={this.setStep2}></label>
			<input type='radio' className='nav-dot' onClick={this.setStep3} readOnly checked={this.state.step === 3}/>
			<label htmlFor='step3' onClick={this.setStep3}></label>
			<input type='radio' className='nav-dot' onClick={this.setStep4} readOnly checked={this.state.step === 4}/>
			<label htmlFor='step4' onClick={this.setStep4}></label>
			<input type='radio' className='nav-dot' onClick={this.setStep5} readOnly checked={this.state.step === 5}/>
			<label htmlFor='step5' onClick={this.setStep5}></label>
		</div>
	};

	navigation = () => {
		return <div className='tutorial-button'>
			{this.nextStepButton()}
			{this.dots()}
		</div>
	};

	render() {
		const {t} = this.props;

		if (this.state.step === 2) {
			return <div className='tutorial-box'>
				<div className='tutorial-icon'>
					{this.languageButtons()}
					<i className='fa fa-envelope'/>
				</div>
				<div className='tutorial-info'>
					<p>{t('tutorialPhones')}</p>
				</div>
				{this.navigation()}

			</div>
		}

		if (this.state.step === 3) {
			return <div className='tutorial-box'>
				<div className='tutorial-icon'>
					{this.languageButtons()}
					<i className='fa fa-language'/>
				</div>
				<div className='tutorial-info'>
					<p>{t('tutorialTranslator')}</p>
				</div>
				{this.navigation()}
			</div>
		}

		if (this.state.step === 4) {
			return <div className='tutorial-box'>
				<div className='tutorial-icon'>
					{this.languageButtons()}
					<i className='fa fa-wallet'/>
				</div>
				<div className='tutorial-info'>
					<p>{t('tutorialCurrency')}</p>

				</div>
				{this.navigation()}
			</div>
		}

		if (this.state.step === 5) {
			return <div className='tutorial-box'>
				<div className='tutorial-icon'>
					{this.languageButtons()}
					<i className='fa fa-file-medical'/>
				</div>
				<div className='tutorial-info'>
					<p> {t('tutorialHealth')} <br/> {t('tutorialHealth2')}</p>
				</div>
				<div className='tutorial-button'>
					<button className="btn default-button full-width-button" onClick={this.closeTutorial}>
						{t('exit')}
					</button>
					{this.dots()}
				</div>
			</div>
		}

		return <div className='tutorial-box'>
			<div className='tutorial-icon'>
				{this.languageButtons()}
				<i className='fa fa-car-crash'/>
			</div>
			<div className='tutorial-info'>
				<p> {t('tutorialEvent')}</p>
			</div>
			{this.navigation()}
		</div>
	}
}

export default translate("translations")(TutorialMain);
